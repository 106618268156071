import { UserWithClinicsDto } from "../common/dtos/UserWithClinicsDto";
import { api } from "./api";

export const changePassword = async (pass: any): Promise<void> => {
  await api.patch("/users/pass", pass, {
    withCredentials: true,
  });
};

export const updateMyProfile = async (user: any): Promise<void> => {
  await api.patch("/users/me", user, {
    withCredentials: true,
  });
};

export const updateMySettings = async (settings: any): Promise<void> => {
  await api.patch("/users/settings", settings, {
    withCredentials: true,
  });
};

export const getCurrentUser = async (): Promise<UserWithClinicsDto> => {
  const response = (await api.get("/users/me", {
    withCredentials: true,
  })) as UserWithClinicsDto;
  return response;
};
