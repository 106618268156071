import type { FC } from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Card,
  Typography,
  Col,
  Row,
  Space,
  Switch,
  Popconfirm,
  Spin,
} from "antd";
import { MailOutlined, LeftOutlined } from "@ant-design/icons";
import { Link, useParams, Navigate, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useAuth } from "../../common/helpers/auth";
import * as adminAPI from "../../api/adminApi";
import { SelectClinics } from "./components/SelectClinics";
import { ClinicValueDto } from "../clinics/dtos/ClinicValueDto";
import { ROUTES } from "../../common/constants/ROUTES";
import { notify } from "../../common/helpers/notify";
import { UserDto } from "../../common/dtos/UserDto";
import { SelectUser } from "./components/SelectUser";
import { updateSelectedClinics } from "./common/helpers";
import { SelectClient } from "./components/SelectClient";
import { SelectRole } from "./components/SelectRole";
import { SelectOptionDto } from "../../common/dtos/SelectOptionDto";
import { ROLES } from "../../common/constants/ROLES";
import { UpdateUserDto } from "../../api/dtos/usersApiRequests";
import { UserWithClinicsDto } from "../../common/dtos/UserWithClinicsDto";
const { Title } = Typography;

const MAX_EMAIL_LENGTH = 64;
const MIN_EMAIL_LENGTH = 6;
const MAX_NAME_LENGTH = 32;
const MIN_NAME_LENGTH = 2;

type UpdateUserFormDto = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isSuperAdmin: boolean;
  clientId: string;
  roleId: string;
  clinicsIds: SelectOptionDto[];
  isActive: boolean;
  copyClinicAccessFromUserId?: string;
};

export const EditUserPage: FC = () => {
  const [form] = Form.useForm();
  const clientId: string | null = Form.useWatch("clientId", form);
  const roleId: string | null = Form.useWatch("roleId", form);
  let { id: userId } = useParams();
  const { user: currentUser, refresh: refreshCurrentUser }: any = useAuth();
  const [user, setUser] = useState<UserDto | null>(null);
  const location = useLocation();
  const [clinicsIds, setClinicsIds] = useState<ClinicValueDto[]>([]);
  const [previousClientId, setPreviousClientId] = useState<string | null>(null);
  const { isLoading: isGetUserLoading }: any = useQuery(
    [userId],
    adminAPI.getUser,
    {
      onSuccess: (user: UserDto) => {
        const updatedClinicIds = user.userRole
          ? user?.userRole.clinics.map((clinic: any) => {
              return {
                label: clinic.name + " (" + clinic.code + ")",
                value: clinic.id,
                key: clinic.id,
              };
            })
          : [];
        setClinicsIds(updatedClinicIds);
        setUser(user);
      },
      onError: (error: Error) =>
        notify.error(error.message ?? "Unable to get user."),
    }
  );
  const { mutate: updateUser, isSuccess: isSuccessUpdateUser }: any =
    useMutation(adminAPI.updateUser, {
      onSuccess: (response: any) => {
        notify.success("User was updated.");
      },
      onError: () => notify.error("Unable to update a user."),
    });

  const { mutate: resendInviteUser, isLoading: isResendUserInviteLoading } =
    useMutation(adminAPI.inviteUser, {
      onError: () => notify.error("Unable to resend the invitation email"),
      onSuccess: () =>
        notify.success("The invitation email has been successfully resent"),
    });

  useEffect(() => {
    if (!clientId) return;
    if (!previousClientId) {
      setPreviousClientId(clientId);
      return;
    }
    if (previousClientId !== clientId) {
      form.setFieldsValue({ clinicsIds: [] });
      form.setFieldsValue({ copyClinicAccessFromUserId: [] });
    }
  }, [clientId, form, previousClientId]);

  useEffect(() => {
    if (roleId === ROLES.ADMIN) {
      form.setFieldsValue({ clinicsIds: [] });
      form.setFieldsValue({ copyClinicAccessFromUserId: [] });
    }
  }, [roleId, form]);

  if (isSuccessUpdateUser && user) {
    if (currentUser.id === user.id) refreshCurrentUser();
    return <Navigate to={ROUTES.ADMIN_USERS} replace state={{ location }} />;
  }

  const onFinish = async (values: UpdateUserFormDto) => {
    delete values.copyClinicAccessFromUserId;
    const updatedClinicsIds =
      values.clinicsIds?.length > 0
        ? values.clinicsIds.map(({ value }: SelectOptionDto) => ({ id: value }))
        : [];
    const updateUserDto: UpdateUserDto = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      isSuperAdmin: values.isSuperAdmin,
      userRole: {
        clientId: values.clientId,
        roleId: values.roleId,
        isActive: values.isActive,
        clinicsIds: updatedClinicsIds,
      },
    };
    updateUser(updateUserDto);
  };

  return (
    <>
      <Title level={3}>Edit user</Title>
      <Spin spinning={isGetUserLoading || isResendUserInviteLoading}>
        <Row gutter={24}>
          <Col xs={12}>
            {user && (
              <Card
                size="small"
                title={
                  <Space>
                    <Link to="/admin/users">
                      <Button type="default" htmlType="submit">
                        <LeftOutlined /> Back
                      </Button>
                    </Link>
                    {!user.isEnabled && (
                      <Popconfirm
                        title="Please confirm sending an email?"
                        onConfirm={() => {
                          if (userId) {
                            resendInviteUser({ userId, email: user.email });
                          }
                        }}
                      >
                        <Button type="default" htmlType="submit">
                          <MailOutlined /> Resend invite
                        </Button>
                      </Popconfirm>
                    )}
                  </Space>
                }
                bordered={false}
              >
                <Form
                  form={form}
                  labelCol={{ span: 5 }}
                  autoComplete="off"
                  size="middle"
                  onFinish={onFinish}
                  name="admin_users_edit"
                  initialValues={{
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    isSuperAdmin: user.isSuperAdmin,
                    clientId: user.userRole?.client.id,
                    roleId: user.userRole?.role.id,
                    clinicsIds: clinicsIds,
                    isActive: user.userRole?.isActive ?? false,
                  }}
                >
                  <Form.Item label={false} name="id" hidden={true}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item
                    label="Name"
                    name="firstName"
                    rules={[
                      { required: true, message: "Please input your Name!" },
                      {
                        min: MIN_NAME_LENGTH,
                        message: `Name min length is ${MIN_NAME_LENGTH} characters`,
                      },
                      {
                        max: MAX_NAME_LENGTH,
                        message: `Name max length is ${MAX_NAME_LENGTH} characters`,
                      },
                    ]}
                  >
                    <Input name="firstName" />
                  </Form.Item>
                  <Form.Item
                    label="Surname"
                    name="lastName"
                    rules={[
                      { required: true, message: "Please input your Surname!" },
                      {
                        min: MIN_NAME_LENGTH,
                        message: `Surname min length is ${MIN_NAME_LENGTH} characters`,
                      },
                      {
                        max: MAX_NAME_LENGTH,
                        message: `Surname max length is ${MAX_NAME_LENGTH} characters`,
                      },
                    ]}
                  >
                    <Input name="lastName" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please input your Email!" },
                      {
                        min: MIN_EMAIL_LENGTH,
                        message: `Email min length is ${MIN_EMAIL_LENGTH} characters`,
                      },
                      {
                        max: MAX_EMAIL_LENGTH,
                        message: `Email max length is ${MAX_EMAIL_LENGTH} characters`,
                      },
                    ]}
                  >
                    <Input name="email" />
                  </Form.Item>
                  <Form.Item
                    label="Client"
                    name="clientId"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <SelectClient
                      placeholder="Select client"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  {clientId && (
                    <>
                      <Form.Item
                        label="Role"
                        name="roleId"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <SelectRole
                          placeholder="Select role"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      {roleId && roleId === ROLES.ADMIN && (
                        <p
                          style={{
                            color: "#919191",
                            marginTop: 0,
                            marginLeft: 118,
                          }}
                        >
                          Admin has access to all client's clinics.
                        </p>
                      )}
                    </>
                  )}
                  {clientId && roleId !== ROLES.ADMIN && (
                    <Form.Item label="Access" name="clinicsIds">
                      <SelectClinics
                        clientId={clientId}
                        mode="multiple"
                        placeholder="Select clinics"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}
                  {clientId && roleId !== ROLES.ADMIN && (
                    <Form.Item
                      label="Copy access"
                      name="copyClinicAccessFromUserId"
                    >
                      <SelectUser
                        clientId={clientId}
                        placeholder="Type user name or email"
                        style={{ width: "100%" }}
                        onChange={(event) => {
                          // if the selected user is cleared, event will be null
                          const selectedUser: UserWithClinicsDto = event
                            ? JSON.parse(event.value)
                            : null;
                          updateSelectedClinics(selectedUser, form);
                        }}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="isActive"
                    label="Is active"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    label="DM Admin"
                    name="isSuperAdmin"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      style={{ minWidth: 100 }}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            )}
          </Col>
        </Row>
      </Spin>
    </>
  );
};
