import "./common/styles/app.css";
import { Layout } from "antd";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./common/components/AppRoutes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ProvideAuth } from "./common/helpers/auth";
import { ConfigProvider } from "antd";
import enGb from "antd/locale/en_GB";
import "dayjs/locale/en-gb";

const queryClient = new QueryClient({});

export const App = (): React.ReactElement => (
  <ConfigProvider locale={enGb}>
    <QueryClientProvider client={queryClient}>
      <Layout className="layout">
        <BrowserRouter>
          <ProvideAuth>
            <AppRoutes />
          </ProvideAuth>
        </BrowserRouter>
      </Layout>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ConfigProvider>
);
