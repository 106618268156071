import { RcFile } from "antd/lib/upload";

export function readFileContent(file: RcFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target?.result as string;
      const parsedFileContent = fileContent.replace(/\n/g, "");
      resolve(parsedFileContent);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsText(file);
  });
}

export function converStringToFile(content: string, fileName: string): File {
  const blob = new Blob([content], {
    type: "text/plain",
  });
  const file = new File([blob], fileName, {
    type: "text/plain",
  });
  return file;
}
