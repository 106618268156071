/** tag => hashtag */
export const CALLHASHTAGS: Record<string, string> = {
  "unaware of appt": "#WasUnawareOfAppt",
  "failed ID+V": "#RefusedOrFailedIDV",
  "requests letter": "#RequestsLetter",
  "needs transport": "#NeedsTransport",
  "appt not needed": "#DoesntNeedAppt",
  "asks for earlier date": "#AsksForEarlierDate",
  "asks for later date": "#AsksForLaterDate",
};

export const hasTag = (hashtag: string, text: string): boolean => {
  const regex = new RegExp(`${hashtag}(?!\\w)`, "gi");
  const matches = text?.match(regex);
  if (!matches || matches.length === 0) return false;
  return true;
};

export const removeTag = (tag: string, text: string): string => {
  const newText = text?.replace(
    new RegExp("\\s*" + CALLHASHTAGS[tag] + "\\b", "gi"),
    ""
  );
  return newText;
};
