import { CreateCommCredentialsDto } from "../admin/comms/credentials/dtos/CommCredentialsDto";
import {
  CreateUpdateCommDto,
  GetCommsResponse,
  CommDto,
  ApptsFilterDto,
  ApptsFilterFormDto,
} from "../admin/comms/dtos/CommDto";
import { CommMessageDto } from "../admin/comms/dtos/CommMessageDto";
import { api } from "./api";

function mapFilters(filters: any): ApptsFilterFormDto {
  const hasDnaFilter =
    typeof filters.minDna === "number" && typeof filters.maxDna === "number";
  const hasAgeFilter =
    typeof filters.minAge === "number" && typeof filters.maxAge === "number";
  const hasReasonFilter = !!filters.reason?.name && !!filters.reason?.minWeight;
  return {
    id: filters.id,
    type: filters.type,
    ...(hasAgeFilter && {
      ageFilter: {
        minAge: filters.minAge,
        maxAge: filters.maxAge,
      },
    }),
    ...(hasReasonFilter && {
      reasonFilter: {
        name: filters.reason?.name,
        minWeight: filters.reason?.minWeight,
      },
    }),
    ...(hasDnaFilter && {
      dnaFilter: {
        minDna: filters.minDna,
        maxDna: filters.maxDna,
      },
    }),
    apptIds: filters.apptIds,
    offsetDaysFromCurrent: filters.offsetDaysFromCurrent,
    clinics: filters.clinics,
  };
}

export const creatComm = async (
  createCommDto: CreateUpdateCommDto
): Promise<void> => {
  const response = (await api.post("/admin/comms", createCommDto, {
    withCredentials: true,
  })) as void;
  return response;
};

export const createCommCredentials = async (
  createCommCredentialsDto: CreateCommCredentialsDto
): Promise<void> => {
  const response = (await api.post(
    "/admin/comms/credentials",
    createCommCredentialsDto,
    {
      withCredentials: true,
    }
  )) as void;
  return response;
};

export const getComms = async (
  limit: number,
  page: number
): Promise<GetCommsResponse> => {
  const response = (await api.get("/admin/comms", {
    withCredentials: true,
    params: {
      limit,
      page,
    },
  })) as any;
  return {
    total: response.total,
    results: response.results.map((comm: any) => ({
      ...comm,
      filters: mapFilters(comm.filters[0]),
    })),
  };
};

export const getCommCredentials = async (limit: number, page: number) => {
  const response = (await api.get("/admin/comms/credentials", {
    withCredentials: true,
    params: {
      limit,
      page,
    },
  })) as any;
  return response;
};

export const getCommById = async (commId: string): Promise<CommDto> => {
  const response = (await api.get(`/admin/comms/${commId}`, {
    withCredentials: true,
  })) as any;
  const filters = response.filters[0];
  return {
    ...response,
    filters: mapFilters(filters),
  };
};

export const updateComm = async (
  commId: string,
  updateCommDto: CreateUpdateCommDto
): Promise<void> => {
  await api.patch(`/admin/comms/${commId}`, updateCommDto, {
    withCredentials: true,
  });
};

export const deleteComm = async (commId: string): Promise<void> => {
  await api.delete(`/admin/comms/${commId}`, {
    withCredentials: true,
  });
};

export const deleteCommCredentials = async (id: string): Promise<void> => {
  await api.delete(`/admin/comms/credentials/${id}`, {
    withCredentials: true,
  });
};

export const getCommMessages = async (
  commId: string,
  limit: number,
  page: number
): Promise<CommMessageDto> => {
  const response = (await api.get(`/admin/comms/${commId}/messages`, {
    withCredentials: true,
    params: {
      limit,
      page,
    },
  })) as CommMessageDto;
  return response;
};

export const estimateCommsToSend = async (
  apptsFilterDto: ApptsFilterDto,
  commId?: string,
  clientId?: string
): Promise<number> => {
  const response = (await api.post(
    "/admin/comms/estimate",
    { filters: apptsFilterDto, commId, clientId },
    {
      withCredentials: true,
    }
  )) as { commCount: number };
  return response.commCount;
};

export const runAllComms = async (): Promise<void> => {
  const response = (await api.post(
    "/admin/comms/run-all",
    {},
    {
      withCredentials: true,
    }
  )) as void;
  return response;
};
