import { Alert, Spin } from "antd";
import { useQuery } from "react-query";
import { getQuicksightUrl } from "../../api/insightsApi";
import { FC } from "react";

export const Insights: FC = () => {
  const {
    isLoading,
    isError,
    data: embedUrl,
  } = useQuery(["insights"], getQuicksightUrl, {
    cacheTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });
  if (!isLoading && (isError || !embedUrl)) {
    return (
      <Alert
        type="error"
        showIcon={true}
        message={
          "Unfortunately Data Insights are not available at the moment. Please try refreshing the page or contact DM support if issue persists."
        }
      />
    );
  }

  return (
    <Spin spinning={isLoading}>
      <iframe
        src={embedUrl}
        title="Insights dashboard"
        style={{ width: "100%", height: "80vh", border: "none" }}
      ></iframe>
    </Spin>
  );
};
