export const ROUTES = {
  APPTS: "/",
  APPTS_DATE: "/schedules/:date",
  APPTS_CALENDAR: "/schedules/calendar",
  CANCELLATIONS: "/cancellations",
  SETTINGS: "/settings",
  LOGIN: "/login",
  CONFIRM_USER: "/confirm-user/:id",
  RECOVER_PASS: "/reset-password",
  CONFIRM_RECOVER_PASS: "/reset-password/:id",
  INSIGHTS: "/insights",
  ADMIN_HOME: "/admin",
  ADMIN_EMAIL_LOGS: "/admin/email-logs",
  ADMIN_CLINICS: "/admin/clinics",
  ADMIN_CLINICS_THRESHOLDS: "/admin/clinics/thresholds",
  ADMIN_CLINICS_VIEW: "/admin/clinics/view/:id",
  ADMIN_CLINICS_SEARCH: "/admin/clinics/search",
  ADMIN_CLINICS_APPTS_CANCELLATION: "/admin/clinics/appts/cancellation",
  ADMIN_USERS: "/admin/users",
  ADMIN_SYNC: "/admin/syncs",
  ADMIN_USERS_INVITE: "/admin/users/invite",
  ADMIN_USERS_EDIT: "/admin/users/update/:id",
  ACTIONS: "/actions",
  ADMIN_SMS_JOBS: "/admin/sms-jobs",
  ADMIN_SMS_JOBS_CREATE: "/admin/sms-jobs/create",
  ADMIN_SMS_JOBS_EDIT: "/admin/sms-jobs/edit/:smsJobId",
  ADMIN_SMS_JOBS_LOGS_VIEW: "/admin/sms-jobs/:smsJobId/logs",
  ADMIN_COMMS: "/admin/comms",
  ADMIN_COMMS_CREATE: "/admin/comms/create",
  ADMIN_COMMS_EDIT: "/admin/comms/edit/:commId",
  ADMIN_COMM_MESSAGES_VIEW: "/admin/comms/:commId/messages",
  ADMIN_COMM_CREDENTIALS: "/admin/comms/credentials",
  ADMIN_COMM_CREDENTIALS_CREATE: "/admin/comms/credentials/create",
  ADMIN_BB_ROUTINES: "/admin/bb-routines",
  ADMIN_BB_ROUTINES_VIEW: "/admin/bb-routines/:id",
};
