import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import * as authApi from "../../api/authApi";
import * as usersApi from "../../api/usersApi";
import { UserWithClinicsDto } from "../dtos/UserWithClinicsDto";
import { notify } from "./notify";
import { LoginDto } from "../dtos/LoginDto";

export interface AuthContextType {
  user: UserWithClinicsDto | null;
  isLoadingCurrentUser: boolean;
  authError: string | null;
  login: (credentials: LoginDto) => Promise<any>;
  logout: (credentials: any) => Promise<any>;
  refresh: () => Promise<any>;
  getHospitals: () => any[];
}

const AuthProvider = () => {
  const [user, setUser] = useState<UserWithClinicsDto | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { mutate: authenticateUser }: any = useMutation(authApi.loginUser, {
    onSuccess: (authenticatedUser: UserWithClinicsDto) => {
      notify.success(
        "Welcome to Deep Medical, " + authenticatedUser.firstName + "."
      );
      setAuthError(null);
      setUser(authenticatedUser);
      navigate("/");
    },
    onError: (error: Error) => {
      setAuthError(error.message);
    },
  });
  const { refetch: logoutUser } = useQuery(["logout"], authApi.logoutUser, {
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setUser(null);
      navigate("/login");
    },
  });
  const { isLoading: isLoadingCurrentUser, refetch: getCurrentUser } = useQuery(
    ["getCurrentUser"],
    usersApi.getCurrentUser,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response: UserWithClinicsDto) => {
        setUser(response);
      },
    }
  );

  const refresh = async (): Promise<any> => {
    await getCurrentUser();
  };

  const login = async (credentials: LoginDto): Promise<any> => {
    return authenticateUser({ credentials });
  };

  const logout = async () => {
    await logoutUser();
  };

  const getHospitals = () => {
    if (!user || user.clinics?.length === 0) return [];
    const hospitals: any = {};
    user.clinics.forEach((clinic) => {
      if (!hospitals[clinic.hospital.id]) {
        hospitals[clinic.hospital.id] = {
          name: clinic.hospital.name,
          id: clinic.hospital.id,
        };
      }
    });
    return Object.values(hospitals).map((hospital: any) => {
      return { ...hospital };
    });
  };

  return {
    user,
    isLoadingCurrentUser,
    authError,
    login,
    logout,
    refresh,
    getHospitals,
  };
};

const authContext = createContext<AuthContextType | any>({});

export const useAuth = (): AuthContextType => {
  return useContext(authContext);
};

export function ProvideAuth({ children }: any) {
  const auth = AuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
