import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Button, Table, TablePaginationConfig } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PaginationDto } from "../../../common/dtos/PaginationDto";
import { ClinicDto } from "../../../common/dtos/ClinicDto";
import { dayjs } from "../../../common/helpers/dayjs";
import { SortOrder } from "antd/lib/table/interface";
import { DeleteCommButton } from "./DeleteCommButton";
import { CommDto } from "../dtos/CommDto";
import { MdCancel, MdCheckBox } from "react-icons/md";
const defaultSortOrder: SortOrder = "descend";

function getColumnsCfg() {
  const columns = [
    {
      width: 28,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Days span",
      dataIndex: "daysSpan",
      key: "daysSpan",
      render: (_: any, record: CommsTableRow) => {
        return record.filters.offsetDaysFromCurrent;
      },
    },
    {
      title: "Clinics",
      dataIndex: "clinics",
      key: "clinics",
      render: (clinics: ClinicDto[]) => {
        return clinics.length;
      },
    },
    {
      defaultSortOrder,
      title: "Created",
      dataIndex: "created",
      // sortDirections: SORT_DIRECTIONS,
      key: "created",
      render: (createdDate: string) =>
        dayjs.utc(createdDate).tz().format("DD.MM.YYYY"),
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (_: any, record: CommsTableRow) => {
        return record.client.code;
      },
    },
    {
      title: "Is enabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled: boolean) =>
        isEnabled ? (
          <MdCheckBox style={{ color: "green", fontSize: 18 }} />
        ) : (
          <MdCancel style={{ color: "red", fontSize: 18 }} />
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: CommsTableRow) => {
        return (
          <>
            <Link to={`/admin/comms/edit/${record.id}`}>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size={"middle"}
                style={{ marginRight: 10 }}
              />
            </Link>
            <Link to={`/admin/comms/${record.id}/messages`}>
              <Button
                shape="circle"
                icon={<FileSearchOutlined />}
                size={"middle"}
                style={{ marginRight: 10 }}
              />
            </Link>
            <DeleteCommButton commId={record.id} />
          </>
        );
      },
    },
    {
      width: 28,
    },
  ];

  return columns;
}

interface CommsTableProps {
  data: CommsTableRow[];
  pagination: PaginationDto;
  isLoading: boolean;
  onPaginationChange: (pagination: TablePaginationConfig) => void;
}

export type CommsTableRow = Omit<CommDto, "id"> & {
  id: string;
};

export const CommsTable: FC<CommsTableProps> = ({
  data,
  pagination,
  onPaginationChange,
  isLoading,
}) => {
  return (
    <Table
      rowKey={"id"}
      size="middle"
      loading={isLoading}
      columns={getColumnsCfg()}
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        onPaginationChange(pagination);
      }}
    />
  );
};
