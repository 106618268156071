import type { FC } from "react";
import { Typography, Table, Card, Col, Row, Button, Avatar } from "antd";
import { dayjs } from "../../common/helpers/dayjs";
import { Link } from "react-router-dom";
import { UserAddOutlined, StarOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import * as adminAPI from "../../api/adminApi";
import { ROLES } from "../../common/constants/ROLES";
import { MdCancel, MdCheckBox } from "react-icons/md";
const { Title } = Typography;
const defaultSortOrderDesc: any = "descend";
const defaultSortOrderAsc: any = "ascend";
const columns = [
  {
    title: "Name",
    dataIndex: "firstName",
    defaultSortOrder: defaultSortOrderAsc,
    render: function (text: any, record: any, index: any) {
      return (
        <Link to={`/admin/users/update/${record.id}`}>
          <Avatar
            size={22}
            style={{
              color: "#fff",
              backgroundColor: "rgb(111 69 206)",
              fontWeight: 600,
              marginRight: 7,
              fontSize: 11,
            }}
          >
            {record.firstName[0] + record.lastName[0]}
          </Avatar>
          {record.firstName + " " + record.lastName}{" "}
          {record.isSuperAdmin && <StarOutlined style={{ color: "orange" }} />}
        </Link>
      );
    },
    sorter: (a: any, b: any) => {
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
        return -1;
      }
      if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    sorter: (a: any, b: any) => {
      if (a.email.toLowerCase() < b.email.toLowerCase()) {
        return -1;
      }
      if (a.email.toLowerCase() > b.email.toLowerCase()) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: "Client",
    render: function (text: any, record: any, index: any) {
      if (!record.userRole) return;
      return record.userRole.client.code;
    },
  },
  {
    title: "Last login",
    dataIndex: "lastlogin",
    render: function (text: any, record: any, index: any) {
      return record.lastLogin ? dayjs.utc(record.lastLogin).fromNow() : <></>;
    },
    sorter: (a: any, b: any) =>
      Number.parseInt(dayjs.utc(a.lastLogin ? a.lastLogin : 0).format("x")) -
      Number.parseInt(dayjs.utc(b.lastLogin ? b.lastLogin : 0).format("x")),
  },
  {
    defaultSortOrder: defaultSortOrderDesc,
    title: "Invited",
    dataIndex: "created",
    render: function (text: any, record: any, index: any) {
      return dayjs.utc(text).tz().format("DD.MM.YYYY");
    },
    sorter: (a: any, b: any) =>
      Number.parseInt(dayjs.utc(a.created).format("x")) -
      Number.parseInt(dayjs.utc(b.created).format("x")),
  },
  {
    title: "Confirmed",
    dataIndex: "isEnabled",
    render: function (text: any, record: any, index: any) {
      return record.isEnabled ? (
        <MdCheckBox style={{ color: "green", fontSize: 18 }} />
      ) : (
        <MdCancel style={{ color: "red", fontSize: 18 }} />
      );
    },
    sorter: (a: any, b: any) => Number(a.isEnabled) - Number(b.isEnabled),
  },
  {
    width: 28,
  },
];

export const ListUsersPage: FC = () => {
  const { isLoading, isError, data } = useQuery(
    ["getUsers"],
    adminAPI.getUsers
  );
  if (isError)
    return <Title>An error has occurred. Try reloading this page.</Title>;
  const users: any = data?.results ?? [];
  return (
    <>
      <Row gutter={24}>
        <Col xl={20}>
          <Title level={3}>Manage users</Title>
        </Col>
        <Col xl={4} lg={4} md={4}></Col>
      </Row>
      <Card
        size="small"
        bodyStyle={{ padding: 0 }}
        headStyle={{ paddingTop: 7, paddingBottom: 7 }}
        title={
          <Link to="/admin/users/invite">
            <Button type="primary" htmlType="submit">
              <UserAddOutlined /> Invite
            </Button>
          </Link>
        }
        bordered={false}
      >
        <Table
          size="middle"
          loading={isLoading}
          expandable={{
            expandedRowRender: (user) => {
              if (user.userRole.role.id === ROLES.ADMIN)
                return (
                  <div style={{ marginLeft: 45 }}>
                    <span>Has access to:</span>
                    <ul style={{ margin: 0 }}>
                      <li key={1}>
                        All clinics of {user.userRole.client.code}
                      </li>
                    </ul>
                  </div>
                );
              return (
                <div style={{ marginLeft: 45 }}>
                  <span>Has access to:</span>
                  <ul style={{ margin: 0 }}>
                    {user.userRole?.clinics?.length > 0 && (
                      <>
                        {user.userRole?.clinics.map((clinic) => (
                          <li key={clinic.id}>{clinic.name}</li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              );
            },
            rowExpandable: (user) => {
              return (
                user.userRole != null &&
                (user.userRole.role.id === ROLES.ADMIN ||
                  user.userRole.clinics.length > 0)
              );
            },
          }}
          rowKey={"id"}
          columns={columns}
          dataSource={data?.results ?? []}
          pagination={{
            defaultPageSize: 50,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
            showSizeChanger: false,
            total: users.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </Card>
    </>
  );
};
