import { FC } from "react";
import { Link } from "react-router-dom";
import {
  SettingOutlined,
  BankOutlined,
  StarOutlined,
  LogoutOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Typography, Menu, Button, Dropdown, Space, Avatar } from "antd";
import { useAuth } from "../helpers/auth";
const { Text } = Typography;

export const UserDropdown: FC = () => {
  const { user, logout }: any = useAuth();
  let menuItems: any = [
    {
      key: "2",
      icon: <SettingOutlined />,
      label: (
        <Link to="/settings">
          <Button type="text" size="small">
            Settings
          </Button>
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "4",
      danger: true,
      icon: <LogoutOutlined />,
      label: (
        <Button type="text" onClick={logout} size="small">
          Logout
        </Button>
      ),
    },
  ];
  if (user.isSuperAdmin) {
    menuItems = [
      {
        key: "0",
        icon: <StarOutlined />,
        label: (
          <Link to="/admin">
            <Button type="text" size="small">
              Admin
            </Button>
          </Link>
        ),
      },
      {
        key: "1",
        icon: <BankOutlined />,
        label: (
          <Link to="/">
            <Button type="text" size="small">
              Clinic
            </Button>
          </Link>
        ),
      },
      ...menuItems,
    ];
  }
  return (
    <Dropdown
      dropdownRender={() => <Menu className="menu-user" items={menuItems} />}
    >
      <Space>
        <Avatar
          className="dm-user-avatar"
          style={{
            color: "#fff",
            fontWeight: 600,
          }}
        >
          {user.firstName[0]}
          {user.lastName[0]}
        </Avatar>
        <Text style={{ color: "rgba(255, 255, 255, 0.65)" }}>
          {user.firstName} {user.lastName}
        </Text>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};
