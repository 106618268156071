import { FC, useState } from "react";
import { Typography, Card, Alert, Col, Row, Spin } from "antd";
import { BackButton } from "../../common/components/BackButton";
import { Navigate, useLocation, useParams } from "react-router-dom";
import * as commsApi from "../../api/commApi";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ROUTES } from "../../common/constants/ROUTES";
import { notify } from "../../common/helpers/notify";
import {
  CommDto,
  ApptsFilterFormDto,
  CreateUpdateCommDto,
} from "./dtos/CommDto";
import {
  CommForm,
  CommFormValue,
  FILTER_TYPES,
  getInitialFilterTypeValue,
} from "./components/CommForm";
import { readFileContent } from "./utils/file";
import { shouldGetEstimate } from "./utils/shouldGetEstimate";
import { mapFormValuesToApptsFilterDto } from "./utils/mapFormValuesToApptsFilterDto";

const { Title } = Typography;

type EditCommPageParams = {
  commId: string;
};

export const EditCommPage: FC = () => {
  const { commId } = useParams<
    keyof EditCommPageParams
  >() as EditCommPageParams;
  const location = useLocation();
  const queryClient = useQueryClient();
  const [filterValues, setFilterValues] = useState<ApptsFilterFormDto>(
    {} as ApptsFilterFormDto
  );
  const [clientId, setClientId] = useState<string>("");
  // const [selectedClinics, setSelectedClinics] = useState<ClinicValueDto[]>([]);
  const {
    isLoading: isLoadingGetComm,
    isError: isGetCommError,
    data: currentComm,
    error: getCommError,
  }: {
    isLoading: boolean;
    isError: boolean;
    data?: CommDto | null;
    error?: Error | null;
  } = useQuery(["get-comm-by-id", commId], () => commsApi.getCommById(commId), {
    onSuccess: (comm: CommDto) => {
      setFilterValues({
        ...comm.filters,
        clinics: comm.clinics.map((clinic) => ({
          value: clinic.id,
          label: clinic.name,
        })),
      });
      setClientId(comm.client.id);
    },
    refetchOnWindowFocus: false,
  });
  const {
    mutate: updateRecord,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(
    ({
      commId,
      updateCommDto,
    }: {
      commId: string;
      updateCommDto: CreateUpdateCommDto;
    }) => commsApi.updateComm(commId, updateCommDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-comm-by-id", commId]);
        notify.success("Communication was updated.");
      },
      onError: () => notify.error("Unable to update a communication."),
    }
  );
  const { data: commsCount, isLoading: isLoadingEstimate } = useQuery(
    ["estimate-comms", filterValues, clientId, currentComm?.id],
    async () => {
      if (filterValues.type === FILTER_TYPES.EXTERNAL) {
        return commsApi.estimateCommsToSend(
          mapFormValuesToApptsFilterDto(filterValues),
          commId,
          clientId
        );
      }
      return commsApi.estimateCommsToSend(
        mapFormValuesToApptsFilterDto(filterValues)
      );
    },
    {
      enabled: shouldGetEstimate(filterValues, clientId, currentComm?.id),
    }
  );
  if (isUpdateSuccess) {
    return <Navigate to={ROUTES.ADMIN_COMMS} replace state={{ location }} />;
  }
  const isErrorMessageVisible = () => isGetCommError || isUpdateError;
  const getErrorMessage = () => {
    if (isGetCommError) return (getCommError as Error).message;
    if (isUpdateError) return (updateError as Error).message;
  };
  return (
    <>
      <Title level={3}>{`Edit communication`}</Title>
      <Row gutter={24}>
        <Col xs={14}>
          <Spin spinning={isLoadingGetComm || isLoadingUpdate} size="large">
            <Card
              title={<BackButton />}
              size="small"
              bordered={false}
              headStyle={{ paddingTop: 7, paddingBottom: 7 }}
            >
              {isErrorMessageVisible() && (
                <Alert
                  message="Error"
                  description={getErrorMessage()}
                  type="error"
                  showIcon
                  style={{ marginBottom: 15 }}
                />
              )}
              <Spin spinning={isLoadingEstimate}>
                <Alert
                  message={`Will contact approx. ${
                    commsCount ?? 0
                  } patients during the next 30 days`}
                  style={{ marginBottom: 15 }}
                />
              </Spin>
              {currentComm && (
                <CommForm
                  initialValues={{
                    ...currentComm,
                    filters: {
                      ...currentComm.filters,
                      clinics: currentComm.clinics.map((clinic) => ({
                        label: clinic.name,
                        value: clinic.id,
                      })),
                    },
                    hasDnaPredictionFilter: !!currentComm?.filters.dnaFilter,
                    hasReasonFilter: !!currentComm?.filters?.reasonFilter,
                    hasAgeFilter: !!currentComm?.filters?.ageFilter,
                    clientId: currentComm.client.id,
                    filterType: getInitialFilterTypeValue(currentComm),
                  }}
                  onSubmit={async (formValue: CommFormValue) => {
                    let fileContent;
                    if (formValue.file) {
                      fileContent = await readFileContent(formValue.file);
                    }
                    updateRecord({
                      commId,
                      updateCommDto: {
                        ...formValue,
                        clientId: formValue.clientId,
                        filters: {
                          ...formValue.filters,
                          id: currentComm.filters.id,
                          clinics:
                            formValue.filters.clinics?.map(
                              (clinic) => clinic.value
                            ) || null,
                          apptIds: fileContent?.split(","),
                          type: formValue.filterType,
                        },
                      },
                    });
                  }}
                  onFiltersChange={(value: ApptsFilterFormDto) => {
                    queryClient.cancelQueries("estimate-comms", {
                      exact: false,
                    });
                    setFilterValues(value);
                  }}
                  onClientChange={(clientId: string) => {
                    setClientId(clientId);
                  }}
                  submitButtonLabel="Update"
                />
              )}
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};
