import { Table, TablePaginationConfig } from "antd";
import { FC } from "react";
import { PaginationDto } from "../../../../common/dtos/PaginationDto";
import { dayjs } from "../../../../common/helpers/dayjs";
import { SortOrder } from "antd/lib/table/interface";
import { DeleteCommCredentialsButton } from "./DeleteCommCredentialsButton";
import { GetCommCredentialsDto } from "../dtos/CommCredentialsDto";
const defaultSortOrder: SortOrder = "descend";
// const SORT_DIRECTIONS: SortOrder[] = ["ascend", "descend", "ascend"];

function getColumnsCfg() {
  const columns = [
    {
      width: 28,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (client: { id: string; code: string }) => {
        return <div key={client.id}>{client.code}</div>;
      },
    },
    {
      defaultSortOrder,
      title: "Created",
      dataIndex: "created",
      // sortDirections: SORT_DIRECTIONS,
      key: "created",
      render: (createdDate: string) =>
        dayjs.utc(createdDate).tz().format("DD.MM.YYYY"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: CommCredentialsTableRow) => {
        return (
          <>
            <DeleteCommCredentialsButton commCredentialsId={record.id} />
          </>
        );
      },
    },
    {
      width: 28,
    },
  ];

  return columns;
}

interface CommCredentialsTableProps {
  data: CommCredentialsTableRow[];
  pagination: PaginationDto;
  isLoading: boolean;
  onPaginationChange: (pagination: TablePaginationConfig) => void;
}

export type CommCredentialsTableRow = Omit<GetCommCredentialsDto, "id"> & {
  id: string;
};

export const CommCredentialsTable: FC<CommCredentialsTableProps> = ({
  data,
  pagination,
  onPaginationChange,
  isLoading,
}) => {
  return (
    <Table
      rowKey={"id"}
      size="middle"
      loading={isLoading}
      columns={getColumnsCfg()}
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        onPaginationChange(pagination);
      }}
    />
  );
};
