import { useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Card,
  Typography,
  Col,
  Row,
  Switch,
  Alert,
  Spin,
} from "antd";
import { LeftOutlined, CheckOutlined } from "@ant-design/icons";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import * as adminAPI from "../../api/adminApi";
import { SelectClinics } from "./components/SelectClinics";
import { notify } from "../../common/helpers/notify";
import { ROUTES } from "../../common/constants/ROUTES";
import { SelectUser } from "./components/SelectUser";
import { updateSelectedClinics } from "./common/helpers";
import { SelectClient } from "./components/SelectClient";
import { SelectRole } from "./components/SelectRole";
import type { SelectOptionDto } from "../../common/dtos/SelectOptionDto";
import { ROLES } from "../../common/constants/ROLES";
import { CreateUserDto } from "../../api/dtos/usersApiRequests";
import { UserWithClinicsDto } from "../../common/dtos/UserWithClinicsDto";

const { Title } = Typography;

const MAX_EMAIL_LENGTH = 64;
const MIN_EMAIL_LENGTH = 6;
const MAX_NAME_LENGTH = 32;
const MIN_NAME_LENGTH = 2;

type CreateUserFormDto = {
  firstName: string;
  lastName: string;
  email: string;
  isSuperAdmin: boolean;
  clientId: string;
  roleId: string;
  clinicsIds: SelectOptionDto[];
  isActive: boolean;
  copyClinicAccessFromUserId?: string;
};

export const CreateUserPage: any = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const clientId: string | null = Form.useWatch("clientId", form);
  const roleId: string | null = Form.useWatch("roleId", form);
  const {
    mutate: createUser,
    isLoading,
    isError,
    isSuccess,
    error,
  }: any = useMutation(adminAPI.createUser, {
    onSuccess: () => notify.success("User was invited."),
    onError: () => notify.error("Unable to invite a user."),
  });

  useEffect(() => {
    form.setFieldsValue({ clinicsIds: [] });
    form.setFieldsValue({ copyClinicAccessFromUserId: [] });
  }, [clientId, form]);

  useEffect(() => {
    if (roleId === ROLES.ADMIN) {
      form.setFieldsValue({ clinicsIds: [] });
      form.setFieldsValue({ copyClinicAccessFromUserId: [] });
    }
  }, [roleId, form]);

  const onFinish = async (values: CreateUserFormDto) => {
    const createUserDto: CreateUserDto = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      isSuperAdmin: values.isSuperAdmin,
      userRole: {
        clientId: values.clientId,
        roleId: values.roleId,
        isActive: values.isActive,
        clinicsIds:
          values.clinicsIds?.length > 0
            ? values.clinicsIds.map(({ value }: SelectOptionDto) => ({
                id: value,
              }))
            : [],
      },
    };
    createUser(createUserDto);
  };
  if (isSuccess)
    if (isSuccess)
      return <Navigate to={ROUTES.ADMIN_USERS} replace state={{ location }} />;

  return (
    <>
      <Row gutter={24}>
        <Col xl={20}>
          <Title level={3}>Invite new user</Title>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xl={12} lg={12} md={16} xs={24}>
          <Spin spinning={isLoading} size="large">
            <Card
              size="small"
              title={
                <Link to="/admin/users">
                  <Button type="default" htmlType="submit" size="middle">
                    <LeftOutlined /> Back
                  </Button>
                </Link>
              }
              headStyle={{ paddingTop: 7, paddingBottom: 7 }}
              bordered={false}
            >
              {isError && (
                <Alert
                  message="Error"
                  description={error.message}
                  type="error"
                  showIcon
                  style={{ marginBottom: 15 }}
                />
              )}
              {isSuccess ? (
                <Title>
                  User invited! <CheckOutlined style={{ color: "green" }} />
                </Title>
              ) : null}
              <Form
                labelCol={{ span: 5 }}
                autoComplete="off"
                size="middle"
                onFinish={onFinish}
                name="admin_users_invite"
                initialValues={{ isActive: true, isSuperAdmin: false }}
                form={form}
              >
                <Form.Item
                  label="Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Name!",
                    },
                    {
                      min: MIN_NAME_LENGTH,
                      message: `Name min length is ${MIN_NAME_LENGTH} characters`,
                    },
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Name max length is ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Surname"
                  name="lastName"
                  rules={[
                    { required: true, message: "Please input your Surname!" },
                    {
                      min: MIN_NAME_LENGTH,
                      message: `Surname min length is ${MIN_NAME_LENGTH} characters`,
                    },
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Surname max length is ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      min: MIN_EMAIL_LENGTH,
                      message: `Email min length is ${MIN_EMAIL_LENGTH} characters`,
                    },
                    {
                      max: MAX_EMAIL_LENGTH,
                      message: `Email max length is ${MAX_EMAIL_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Client"
                  name="clientId"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <SelectClient
                    placeholder="Select client"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                {clientId && (
                  <>
                    <Form.Item
                      label="Role"
                      name="roleId"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <SelectRole
                        placeholder="Select role"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    {roleId && roleId === ROLES.ADMIN && (
                      <p
                        style={{
                          color: "#919191",
                          marginTop: 0,
                          marginLeft: 118,
                        }}
                      >
                        Admin has access to all client's clinics.
                      </p>
                    )}
                  </>
                )}

                {clientId && roleId !== ROLES.ADMIN && (
                  <Form.Item label="Access" name="clinicsIds">
                    <SelectClinics
                      clientId={clientId}
                      mode="multiple"
                      placeholder="Select clinics"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                )}
                {clientId && roleId !== ROLES.ADMIN && (
                  <Form.Item
                    label="Copy access"
                    name="copyClinicAccessFromUserId"
                  >
                    <SelectUser
                      clientId={clientId}
                      placeholder="Type user name or email"
                      style={{ width: "100%" }}
                      onChange={(event) => {
                        // if the selected user is cleared, event will be null
                        const selectedUser: UserWithClinicsDto = event
                          ? JSON.parse(event.value)
                          : null;
                        updateSelectedClinics(selectedUser, form);
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="isActive"
                  label="Active"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name="isSuperAdmin"
                  label="DM Admin"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                  <Button type="primary" htmlType="submit" size="middle">
                    Send invite
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};
