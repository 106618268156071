import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../../../helpers/auth";
import { UserWithClinicsDto } from "../../../dtos/UserWithClinicsDto";
import { ROLES } from "../../../../common/constants/ROLES";
import { ROUTES } from "../../../constants/ROUTES";

export const ClinicMenu: React.FC = () => {
  const { user }: { user: UserWithClinicsDto | null } = useAuth();
  const location = useLocation();
  let selectedKey = location.pathname;
  if (
    selectedKey === ROUTES.APPTS_CALENDAR ||
    selectedKey.startsWith("/schedules/")
  ) {
    selectedKey = ROUTES.APPTS;
  }
  if (!user) return <></>;
  const menuItems = [
    {
      key: "/",
      label: <Link to="/">Appointments</Link>,
    },
    {
      key: "/cancellations",
      label: <Link to="/cancellations">Cancellations</Link>,
    },
    {
      key: "/actions",
      label: <Link to="/actions">Actions</Link>,
    },
  ];
  if (
    user.userRole.role.id === ROLES.ADMIN &&
    user.userRole.client.quicksightDashboardId
  )
    menuItems.push({
      key: "/insights",
      label: <Link to="/insights">Insights</Link>,
    });
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      style={{
        minWidth: 300,
        flex: 1,
        justifyContent: "flex-start",
        height: "65px",
      }}
      defaultSelectedKeys={["1"]}
      selectedKeys={[selectedKey]}
      items={menuItems}
    />
  );
};
