import { Button } from "antd";
import {
  MdFreeCancellation,
  MdGroup,
  MdOutlineEditCalendar,
  MdOutlineLaptopMac,
  MdPhoneInTalk,
  MdPhoneMissed,
  MdAddCall,
  MdSettingsAccessibility,
  MdFactCheck,
  MdDeviceUnknown,
} from "react-icons/md";
import { FC } from "react";
import {
  ACTION_TYPES,
  CALL_OUTCOMES,
  COMM_OUTCOMES,
  DOUBLEBOOK_OUTCOMES,
  SENTIMENT_TYPES,
} from "../../../common/helpers/actions";
import { MdPersonOff } from "react-icons/md";

function getButtonCollorClass(outcomeSentiment: number) {
  if (outcomeSentiment === SENTIMENT_TYPES.POSITIVE) {
    return "btn-action-outcome-positive";
  } else if (outcomeSentiment === SENTIMENT_TYPES.NEGATIVE) {
    return "btn-action-outcome-negative";
  } else if (outcomeSentiment === SENTIMENT_TYPES.NEUTRAL) {
    return "btn-action-outcome-neutral";
  }
}

const getOutcomeIcon = (type: number, outcome: number) => {
  if (type === ACTION_TYPES.CALL) {
    switch (outcome) {
      case CALL_OUTCOMES.CONFIRMED:
        return <MdPhoneInTalk />;
      case CALL_OUTCOMES.RESCHEDULED:
        return <MdOutlineEditCalendar />;
      case CALL_OUTCOMES.RESCHEDULED_VIRTUAL:
        return <MdOutlineLaptopMac />;
      case CALL_OUTCOMES.PATIENT_CANCELLED:
        return <MdFreeCancellation />;
      case CALL_OUTCOMES.NO_ANSWER:
        return <MdPhoneMissed />;
      case CALL_OUTCOMES.ESCALATION_CALL:
        return <MdAddCall />;
      default:
        return <></>;
    }
  }
  if (
    type === ACTION_TYPES.DOUBLEBOOK &&
    outcome === DOUBLEBOOK_OUTCOMES.DOUBLEBOOKED
  ) {
    return <MdGroup />;
  }
  if (type === ACTION_TYPES.COMM) {
    switch (outcome) {
      case COMM_OUTCOMES.CONFIRMED:
        return <MdPhoneInTalk />;
      case COMM_OUTCOMES.RESCHEDULED:
        return <MdOutlineEditCalendar />;
      case COMM_OUTCOMES.CANCELLED:
        return <MdFreeCancellation />;
      case COMM_OUTCOMES.NO_ANSWER:
        return <MdPhoneMissed />;
      case COMM_OUTCOMES.DELIVERED_NO_RESPONSE_EXPECTED:
        return <MdFactCheck />;
      case COMM_OUTCOMES.FAILED_ID_VERIFICATION:
        return <MdPersonOff />;
      case COMM_OUTCOMES.UNRECOGNIZED_RESPONSE:
        return <MdDeviceUnknown />;
      default:
        return <MdSettingsAccessibility />;
    }
  }
  return <></>;
};

interface OutcomeButtonPropTypes {
  onClick?: () => void | undefined;
  disabled: boolean;
  outcomeSentiment: number;
  actionType: number;
  actionOutcome: number;
}

export const OutcomeButton: FC<OutcomeButtonPropTypes> = ({
  onClick,
  disabled,
  outcomeSentiment,
  actionType,
  actionOutcome,
}: OutcomeButtonPropTypes) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      htmlType="submit"
      type="primary"
      className={getButtonCollorClass(outcomeSentiment)}
      shape="circle"
      icon={getOutcomeIcon(actionType, actionOutcome)}
    />
  );
};
