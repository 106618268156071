import { FC } from "react";
import { Table, TablePaginationConfig } from "antd";
import { CommMessageDto } from "../dtos/CommMessageDto";
import { PaginationDto } from "../../../common/dtos/PaginationDto";
import { dayjs } from "../../../common/helpers/dayjs";

const columns = [
  {
    width: 28,
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
    render: (createdDate: string) =>
      dayjs.utc(createdDate).tz().format("YYYY.MM.DD HH:mm"),
  },
  {
    title: "Appointment Date",
    dataIndex: ["appt", "datetime"],
    key: "appointment_datetime",
    render: (apptDateTime: string) =>
      dayjs.utc(apptDateTime).tz().format("YYYY.MM.DD"),
  },
  {
    title: "Appointment Id",
    dataIndex: ["appt", "id"],
    key: "appointment_id",
  },
  {
    title: "Clinic",
    dataIndex: ["appt", "clinic", "name"],
    key: "clinic_name",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    render: (_: any, comm: CommMessageDto) => {
      return comm.appt.patient.phone;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    width: 28,
  },
];

interface CommMessagesTableProps {
  data: CommMessageDto[];
  pagination: PaginationDto;
  isLoading: boolean;
  onPaginationChange: (pagination: TablePaginationConfig) => void;
}

export const CommMessagesTable: FC<CommMessagesTableProps> = ({
  data,
  pagination,
  isLoading,
  onPaginationChange,
}) => {
  return (
    <Table
      rowKey={"id"}
      size="middle"
      loading={isLoading}
      columns={columns}
      dataSource={data}
      onChange={(pagination: TablePaginationConfig) => {
        onPaginationChange(pagination);
      }}
      pagination={{
        hideOnSinglePage: true,
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      }}
    />
  );
};
