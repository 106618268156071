import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Button, Table, TablePaginationConfig, Tag } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import { PaginationDto } from "../../../common/dtos/PaginationDto";
import { ClinicDto } from "../../../common/dtos/ClinicDto";
import { SmsJobDto } from "../dtos/SmsJobDto";
import { dayjs } from "../../../common/helpers/dayjs";
import { DeleteSmsJobButton } from "./DeleteSmsJobButton";
import { SortOrder } from "antd/lib/table/interface";
const defaultSortOrder: SortOrder = "descend";
// const SORT_DIRECTIONS: SortOrder[] = ["ascend", "descend", "ascend"];

function getColumnsCfg() {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Days before",
      dataIndex: "sendBefore",
      key: "sendBefore",
    },
    {
      title: "Clinics",
      dataIndex: "clinics",
      key: "clinics",
      render: (clinics: ClinicDto[]) => {
        const isLimitApplied = clinics.length > 5;
        const limitedClinics = clinics.slice(0, 5);
        const countHiddenClinics = isLimitApplied ? clinics.length - 5 : 0;
        return (
          <>
            {limitedClinics.map((clinic: ClinicDto) => {
              return (
                <div key={clinic.id} style={{ marginBottom: 5 }}>
                  <Tag>{clinic.name}</Tag>
                  <br />
                </div>
              );
            })}
            {isLimitApplied && (
              <Tag style={{ borderStyle: "dashed" }}>
                +{countHiddenClinics} more
              </Tag>
            )}
          </>
        );
      },
    },
    {
      defaultSortOrder,
      title: "Created",
      dataIndex: "created",
      // sortDirections: SORT_DIRECTIONS,
      key: "created",
      render: (createdDate: string) =>
        dayjs.utc(createdDate).tz().format("DD.MM.YYYY"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: SmsJobsTableRow) => {
        return (
          <>
            <Link to={`/admin/sms-jobs/edit/${record.id}`}>
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size={"middle"}
                style={{ marginRight: 10 }}
              />
            </Link>
            <Link to={`/admin/sms-jobs/${record.id}/logs`}>
              <Button
                shape="circle"
                icon={<FileSearchOutlined />}
                size={"middle"}
                style={{ marginRight: 10 }}
              />
            </Link>
            <DeleteSmsJobButton smsJobId={record.id} />
          </>
        );
      },
    },
  ];

  return columns;
}

interface SmsJobsTableProps {
  data: SmsJobsTableRow[];
  pagination: PaginationDto;
  isLoading: boolean;
  onPaginationChange: (pagination: TablePaginationConfig) => void;
}

export type SmsJobsTableRow = Omit<SmsJobDto, "id"> & {
  id: string;
};

export const SmsJobsTable: FC<SmsJobsTableProps> = ({
  data,
  pagination,
  onPaginationChange,
  isLoading,
}) => {
  return (
    <Table
      rowKey={"id"}
      size="middle"
      loading={isLoading}
      columns={getColumnsCfg()}
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      }}
      onChange={(pagination: TablePaginationConfig) => {
        onPaginationChange(pagination);
      }}
    />
  );
};
