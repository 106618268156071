const ACTION_TYPES = {
  CALL: 1,
  DOUBLEBOOK: 2,
  COMM: 3,
};

const CALL_OUTCOMES = {
  CONFIRMED: 1,
  RESCHEDULED: 2,
  RESCHEDULED_VIRTUAL: 3,
  PATIENT_CANCELLED: 4,
  NO_ANSWER: 5,
  ESCALATION_CALL: 7,
};

const COMM_OUTCOMES = {
  CONFIRMED: CALL_OUTCOMES.CONFIRMED,
  RESCHEDULED: CALL_OUTCOMES.RESCHEDULED,
  CANCELLED: CALL_OUTCOMES.PATIENT_CANCELLED,
  NO_ANSWER: CALL_OUTCOMES.NO_ANSWER,
  DELIVERED_NO_RESPONSE_EXPECTED: 8,
  FAILED_ID_VERIFICATION: 9,
  UNDELIVERED: 100,
  ERROR: 101,
  UNRECOGNIZED_RESPONSE: 102,
};

const DOUBLEBOOK_OUTCOMES = {
  DOUBLEBOOKED: 6,
};

const CONCLUSIVE_POSITIVE_OUTCOMES: any = {};
CONCLUSIVE_POSITIVE_OUTCOMES[ACTION_TYPES.CALL] = [
  CALL_OUTCOMES.CONFIRMED,
  CALL_OUTCOMES.RESCHEDULED,
  CALL_OUTCOMES.RESCHEDULED_VIRTUAL,
  CALL_OUTCOMES.PATIENT_CANCELLED,
  CALL_OUTCOMES.ESCALATION_CALL,
];
CONCLUSIVE_POSITIVE_OUTCOMES[ACTION_TYPES.DOUBLEBOOK] = [
  DOUBLEBOOK_OUTCOMES.DOUBLEBOOKED,
];

CONCLUSIVE_POSITIVE_OUTCOMES[ACTION_TYPES.COMM] = [
  COMM_OUTCOMES.CONFIRMED,
  COMM_OUTCOMES.RESCHEDULED,
];

const CONCLUSIVE_NEGATIVE_OUTCOMES: any = {};
CONCLUSIVE_NEGATIVE_OUTCOMES[ACTION_TYPES.CALL] = [
  CALL_OUTCOMES.PATIENT_CANCELLED,
];

CONCLUSIVE_NEGATIVE_OUTCOMES[ACTION_TYPES.COMM] = [COMM_OUTCOMES.CANCELLED];

const INCONCLUSIVE_OUTCOMES: any = {};
INCONCLUSIVE_OUTCOMES[ACTION_TYPES.CALL] = [CALL_OUTCOMES.NO_ANSWER];

const isConclusiveAction = ({
  type,
  outcome,
}: {
  type: number;
  outcome: number;
}): boolean => {
  return (
    CONCLUSIVE_POSITIVE_OUTCOMES[type].includes(outcome) ||
    CONCLUSIVE_NEGATIVE_OUTCOMES[type].includes(outcome)
  );
};

const SENTIMENT_TYPES = {
  POSITIVE: 1,
  NEUTRAL: 0,
  NEGATIVE: -1,
};

const getOutcomeSentiment = ({
  type,
  outcome,
}: {
  type: number;
  outcome: number;
}): number => {
  let sentiment: number;
  if (CONCLUSIVE_POSITIVE_OUTCOMES[type].includes(outcome)) {
    sentiment = SENTIMENT_TYPES.POSITIVE;
  } else if (CONCLUSIVE_NEGATIVE_OUTCOMES[type].includes(outcome)) {
    sentiment = SENTIMENT_TYPES.NEGATIVE;
  } else if (INCONCLUSIVE_OUTCOMES[type].includes(outcome)) {
    sentiment = SENTIMENT_TYPES.NEUTRAL;
  } else {
    throw new Error(
      'Invalid data "type" or "outcome" in "getOutcomeSentiment".'
    );
  }
  return sentiment;
};

const OUTCOME_TO_LABEL = {
  [CALL_OUTCOMES.CONFIRMED]: "Confirmed",
  [CALL_OUTCOMES.RESCHEDULED]: "Rescheduled",
  [CALL_OUTCOMES.RESCHEDULED_VIRTUAL]: "Rescheduled as VA",
  [CALL_OUTCOMES.PATIENT_CANCELLED]: "Patient cancelled",
  [CALL_OUTCOMES.ESCALATION_CALL]: "Call requested",
  [CALL_OUTCOMES.NO_ANSWER]: "Called, no answer",
  [DOUBLEBOOK_OUTCOMES.DOUBLEBOOKED]: "Backup booking",
  [COMM_OUTCOMES.DELIVERED_NO_RESPONSE_EXPECTED]: "Reminder delivered",
  [COMM_OUTCOMES.FAILED_ID_VERIFICATION]: "Failed ID verification",
  [COMM_OUTCOMES.UNRECOGNIZED_RESPONSE]: "Unrecognized response",
};

const getOutcomeText = (outcome: number): string => {
  if (OUTCOME_TO_LABEL[outcome] === undefined) {
    console.error("Undefined text for outcome");
    return "";
  }
  return OUTCOME_TO_LABEL[outcome];
};

export {
  SENTIMENT_TYPES,
  getOutcomeText,
  isConclusiveAction,
  getOutcomeSentiment,
  ACTION_TYPES,
  CALL_OUTCOMES,
  DOUBLEBOOK_OUTCOMES,
  COMM_OUTCOMES,
};
