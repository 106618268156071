import type { FC } from "react";
import { useState } from "react";
import { Typography, Table, Card } from "antd";
import { useQuery } from "react-query";
import * as adminAPI from "../../api/adminApi";
import { dayjs } from "../../common/helpers/dayjs";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { notify } from "../../common/helpers/notify";
import { GetEmailLogsResponseDto } from "../../api/dtos/adminApiResponses";
const TYPE_MAIL_INVITE = 1;
const TYPE_MAIL_NOTIFICATION = 2;
const TYPE_MAIL_RESETPASS = 3;

const TYPE_EMAIL_LOG_RESULT_OK = 1;

const defaultSortOrder: any = "descend";
const { Title } = Typography;

const columns = [
  {
    title: "Recipient",
    dataIndex: "recipient",
    render: (text: string, record: any, index: number) => {
      return <>{text}</>;
    },
  },
  {
    title: "Result",
    dataIndex: "result",
    render: function (text: any, record: any, index: any) {
      return text === 1 ? (
        <CheckOutlined style={{ color: "green" }} />
      ) : (
        <CloseOutlined style={{ color: "red" }} />
      );
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    render: function (text: any, record: any, index: any) {
      switch (text) {
        case TYPE_MAIL_INVITE:
          return "invite";
        case TYPE_MAIL_NOTIFICATION:
          return "notification";
        case TYPE_MAIL_RESETPASS:
          return "reset-pass";
      }
      return "";
    },
  },
  {
    defaultSortOrder,
    title: "Sent",
    dataIndex: "created",
    render: function (text: any, record: any, index: any) {
      return dayjs.utc(text).tz().format("DD.MM.YYYY HH:mm");
    },
  },
];

export const ListEmailLogsPage: FC = () => {
  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: 20,
    sortBy: "created",
    order: "desc",
    total: 0,
  });
  const { isLoading, data: getEmailLogsData } = useQuery(
    [
      pagination.current,
      pagination.pageSize,
      pagination.sortBy,
      pagination.order,
      "getEmailLogs",
    ],
    adminAPI.getEmailLogs,
    {
      onSuccess: (response: GetEmailLogsResponseDto) =>
        setPagination({
          current: pagination.current,
          pageSize: pagination.pageSize,
          sortBy: pagination.sortBy,
          order: pagination.order,
          total: response.total,
        }),
      onError: (error: Error) =>
        notify.error(
          error.message ?? "Unable to get data. Please contact admin."
        ),
    }
  );
  const onTableChange = async (pagination: any): Promise<any> => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      sortBy: pagination.sortBy,
      order: pagination.order,
      total: pagination.total,
    });
  };
  return (
    <>
      <Title level={3}>Browse email logs</Title>
      <Card size="small" bodyStyle={{ padding: 0 }}>
        <Table
          size="middle"
          loading={isLoading}
          expandable={{
            expandedRowRender: (record) => (
              <pre>
                {record.info ? JSON.stringify(record.info, null, 2) : ""}
              </pre>
            ),
            rowExpandable: (record) =>
              record.result !== TYPE_EMAIL_LOG_RESULT_OK &&
              record.info !== null &&
              record.info !== "",
          }}
          rowKey={(row) => row.id}
          columns={columns}
          dataSource={getEmailLogsData?.results || []}
          pagination={{
            defaultPageSize: 15,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={(pagination) => onTableChange(pagination)}
        />
      </Card>
    </>
  );
};
