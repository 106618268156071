import { PlusOutlined } from "@ant-design/icons";
import { TablePaginationConfig, Alert, Typography, Card, Button } from "antd";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import * as commsApi from "../../../api/commApi";
import { ROUTES } from "../../../common/constants/ROUTES";
import { PaginationDto } from "../../../common/dtos/PaginationDto";
import { GetCommCredentialsDto } from "./dtos/CommCredentialsDto";
import { notify } from "../../../common/helpers/notify";
import { CommCredentialsTable } from "./components/CommCredentialsTable";
import { BackButton } from "../../../common/components/BackButton";

const { Title } = Typography;
const PAGE_SIZE = 20;

export const ListCommCredentialsPage: FC = () => {
  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: PAGE_SIZE,
    sortBy: "created",
    order: "ASC",
    total: 0,
  });
  const { data, isError, isLoading } = useQuery(
    ["get-comm-credentials", ...Object.values(pagination)],
    async () => {
      return commsApi.getCommCredentials(
        pagination.pageSize,
        pagination.current
      );
    },
    {
      onSuccess: (data) => {
        let current = pagination.current;
        if (data?.results.length === 0 && pagination.current > 1) {
          --current;
        }
        setPagination({
          ...pagination,
          current: current,
          total: data.total,
        });
      },
      onError: () => notify.error("Unable to get message communications."),
    }
  );

  const commCredentials: GetCommCredentialsDto[] =
    (data?.results as GetCommCredentialsDto[]) || [];
  const handlePaginationChange = (newPagination: TablePaginationConfig) => {
    setPagination({
      ...pagination,
      current: newPagination.current ?? 1,
      pageSize: newPagination.pageSize ?? PAGE_SIZE,
    });
  };
  if (isError) {
    return (
      <Alert
        message="Error"
        description="Unable to get comm credentials."
        type="error"
        showIcon
        style={{ marginBottom: 15 }}
      />
    );
  }
  return (
    <>
      <Title level={3}>Communications credentials</Title>
      <Card
        size="small"
        title={
          <>
            <BackButton />
            <Link to={ROUTES.ADMIN_COMM_CREDENTIALS_CREATE}>
              <Button type="primary" style={{ marginLeft: 7 }}>
                <PlusOutlined />
                Create
              </Button>
            </Link>
          </>
        }
        headStyle={{ paddingTop: 7, paddingBottom: 7 }}
        bodyStyle={{ padding: 0 }}
      >
        <CommCredentialsTable
          data={commCredentials}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePaginationChange}
        />
      </Card>
    </>
  );
};
