import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Button, Space } from "antd";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as commsApi from "../../../api/commApi";
import { notify } from "../../../common/helpers/notify";

interface DeleteCommProps {
  commId: string;
}

export const DeleteCommButton: FC<DeleteCommProps> = ({ commId }) => {
  const queryClient = useQueryClient();
  const [isPopConfirmOpen, setIsPopConfirmOpen] = useState<boolean>(false);
  const deleteCommMutation = useMutation(commsApi.deleteComm, {
    onSuccess: () => {
      notify.success("Communication was deleted.");
      queryClient.invalidateQueries(["get-comms"]);
    },
    onSettled: () => {
      setIsPopConfirmOpen(false);
    },
    onError: () => {
      notify.error("Unable to delete a communication.");
    },
  });
  return (
    <Popconfirm
      title="Delete this communication?"
      open={isPopConfirmOpen}
      onCancel={() => setIsPopConfirmOpen(false)}
      onConfirm={() => deleteCommMutation.mutate(commId)}
      okButtonProps={{ loading: deleteCommMutation.isLoading }}
      cancelButtonProps={{ disabled: deleteCommMutation.isLoading }}
    >
      <Space>
        <Button
          onClick={() => setIsPopConfirmOpen(true)}
          shape="circle"
          icon={<DeleteOutlined style={{ color: "red" }} />}
          size={"middle"}
        />
      </Space>
    </Popconfirm>
  );
};
