import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Navigate user to the previous page.
 */
export const BackButton: FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Button type="default" onClick={handleClick}>
      <LeftOutlined /> Back
    </Button>
  );
};
