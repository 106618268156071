import { Button, Form, Input, Spin } from "antd";
import { useMutation } from "react-query";
import { useAuth } from "../../../common/helpers/auth";
import * as usersAPI from "../../../api/usersApi";
import { notify } from "../../../common/helpers/notify";

export const UserSettingsTab = () => {
  const { user, refresh }: any = useAuth();
  const { mutate: updateProfile, isLoading: isLoadingUpdateMyProfile }: any =
    useMutation(usersAPI.updateMyProfile, {
      onSuccess: () => {
        notify.success("Profile updated.");
        refresh();
      },
      onError: () => notify.error("Unable to update profile."),
    });
  return (
    <Spin spinning={isLoadingUpdateMyProfile}>
      {user && (
        <Form
          labelCol={{ span: 6 }}
          autoComplete="off"
          onFinish={updateProfile}
          name="settings_user"
          style={{ maxWidth: "500px" }}
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          }}
        >
          <Form.Item
            label="Name"
            name="firstName"
            rules={[{ required: true, message: "Please input your Name!" }]}
          >
            <Input name="firstName" />
          </Form.Item>
          <Form.Item
            label="Surname"
            name="lastName"
            rules={[{ required: true, message: "Please input your Surname!" }]}
          >
            <Input name="lastName" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input name="email" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      )}
    </Spin>
  );
};
