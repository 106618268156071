import { UserDto } from "../common/dtos/UserDto";
import { api } from "./api";
import { LoginDto } from "../common/dtos/LoginDto";
import { EnableUserDto } from "./dtos/authApiRequests";
import { UserWithClinicsDto } from "../common/dtos/UserWithClinicsDto";

export const loginUser = async ({
  credentials,
}: {
  credentials: LoginDto;
}): Promise<UserWithClinicsDto> => {
  const response = (await api.post(
    "/auth/login",
    {
      password: credentials.password,
      email: credentials.email.toLowerCase(),
    },
    {
      withCredentials: true,
    }
  )) as UserWithClinicsDto;
  return response;
};

export const logoutUser = async (): Promise<any> => {
  const response = await api.get("/auth/logout", {
    withCredentials: true,
  });
  return response;
};

export const getUserForActivation = async ({
  queryKey,
}: any): Promise<UserDto> => {
  const [userId] = queryKey;
  const response = (await api.get("/auth/enable/" + userId)) as UserDto;
  return response;
};

export const enableUser = async (
  enableUserDto: EnableUserDto
): Promise<void> => {
  await api.patch("/auth/enable/" + enableUserDto.id, enableUserDto);
};

export const requestResetPassword = async ({ email }: any): Promise<void> => {
  await api.post(
    "/auth/reset-password",
    { email },
    {
      withCredentials: true,
    }
  );
};

export const confirmResetPassword = async ({
  resetPasswordId,
  newPassword,
}: any): Promise<void> => {
  await api.patch(
    "/auth/reset-password",
    { resetPasswordId, newPassword },
    {
      withCredentials: true,
    }
  );
};
