import { useState, type FC } from "react";
import {
  Typography,
  Card,
  Alert,
  Spin,
  Descriptions,
  Col,
  Row,
  Button,
  Popconfirm,
  Form,
  InputNumber,
  Input,
} from "antd";
import { useMutation, useQueries } from "react-query";
import * as adminBBRoutinesApi from "../../api/adminBBRoutinesApi";
import { useParams } from "react-router-dom";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { notify } from "../../common/helpers/notify";
import {
  BBRoutineDto,
  BBRoutineLogDto,
  UpdateBBRoutineDto,
} from "../../api/dtos/adminApiBBRoutinesDtos";
import { BBRoutineLogsTable } from "./components/BBRoutineLogsTable";
import { dayjs } from "../../common/helpers/dayjs";
import { ROUTES } from "../../common/constants/ROUTES";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const PAGINATION_SIZE = 20;

const defaultPagination = {
  current: 1,
  pageSize: PAGINATION_SIZE,
  sortBy: "created",
  order: "desc",
  total: 0,
};

export const ViewBBRoutinePage: FC = () => {
  const { id: bbRoutineId } = useParams();
  const navigate = useNavigate();
  const [filtersForm] = Form.useForm();
  const [configForm] = Form.useForm();
  const [pagination, setPagination] =
    useState<PaginationDto>(defaultPagination);
  const [filters, setFilters] = useState<{
    clinicId?: string | null;
    apptId?: string | null;
  }>({
    clinicId: null,
    apptId: null,
  });
  const [getBBRoutineQuery, findBBRoutineLogsQuery] = useQueries([
    {
      queryKey: [bbRoutineId],
      queryFn: adminBBRoutinesApi.get,
      onError: () => notify.error("Unable to find routine."),
    },
    {
      queryKey: [
        "findLogs",
        bbRoutineId,
        pagination.current,
        pagination.pageSize,
        filters.clinicId,
        filters.apptId,
      ],
      queryFn: () =>
        adminBBRoutinesApi.findLogs(bbRoutineId as string, pagination, filters),
      onSuccess: (response: any) => {
        setPagination({
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: response.total,
        });
      },
      onError: () => notify.error("Unable to find logs."),
    },
  ]);
  const { mutate: updateBBRoutine, isLoading: isLoadingUpdateBBRoutine } =
    useMutation(
      (updateBBRoutineDto: UpdateBBRoutineDto) =>
        adminBBRoutinesApi.update(updateBBRoutineDto),
      {
        onSuccess: () => {
          getBBRoutineQuery.refetch();
          notify.success(`The routine was updated.`);
        },
        onError: () => notify.error(`Unable to update the routine.`),
      }
    );
  const { mutate: triggerBBRoutines, isLoading: isLoadingTriggerBBRoutines } =
    useMutation(() => adminBBRoutinesApi.trigger(), {
      onSuccess: () => notify.success(`Enabled routines were triggered.`),
      onError: () => notify.error(`Unable to trigger enabled routines.`),
    });
  const handleFiltersSubmit = async (values: {
    filterByClinicId?: string;
    filterByApptId?: string;
  }): Promise<void> => {
    setPagination(defaultPagination);
    setFilters({
      clinicId: values?.filterByClinicId?.trim() ?? null,
      apptId: values?.filterByApptId?.trim() ?? null,
    });
    findBBRoutineLogsQuery.refetch();
  };

  const handleConfigSubmit = async (values: {
    daysAhead: number;
    noAnswersRequired: number;
  }): Promise<void> => {
    const { daysAhead, noAnswersRequired } = values;
    updateBBRoutine({
      id: bbRoutineId as string,
      daysAhead,
      noAnswersRequired,
    });
  };

  const handleFilterInputChange = (
    inputName: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const trimmedValue = e.target.value.trim();
    filtersForm.setFieldsValue({ [inputName]: trimmedValue });
  };

  const bbRoutine = (getBBRoutineQuery?.data as BBRoutineDto) ?? null;
  const logs =
    (findBBRoutineLogsQuery?.data?.results as BBRoutineLogDto[]) ?? [];
  if (getBBRoutineQuery.isError)
    return (
      <Alert
        type="error"
        showIcon={true}
        message={(getBBRoutineQuery.error as Error).message}
      />
    );
  if (!bbRoutine)
    return (
      <Spin
        spinning={getBBRoutineQuery.isLoading}
        style={{ width: "100%", height: 400, paddingTop: 200 }}
        size="large"
      />
    );
  const currentBBDateMsg = `Current backup-booking date ${dayjs()
    .tz("Europe/London")
    .add(bbRoutine.daysAhead, "days")
    .startOf("day")
    .format("DD.MM.YYYY")} `;
  return (
    <>
      <Spin spinning={getBBRoutineQuery.isLoading || isLoadingUpdateBBRoutine}>
        <Title level={3}>
          Backup-booking routine - {bbRoutine.client.code}
        </Title>
        <Row gutter={24} style={{ marginBottom: 20 }}>
          <Col xs={10}>
            <Card
              size="small"
              title="About"
              bordered={false}
              bodyStyle={{ padding: 0 }}
            >
              <Descriptions
                bordered
                column={1}
                size={"small"}
                labelStyle={{ minWidth: 140 }}
              >
                <Descriptions.Item label="Client code">
                  {bbRoutine.client.code}
                </Descriptions.Item>
                <Descriptions.Item label="Is enabled (CRON)">
                  {bbRoutine.isEnabled ? (
                    <Text style={{ color: "green", fontWeight: 500 }}>
                      yes (running regularly)
                    </Text>
                  ) : (
                    <Text style={{ color: "red", fontWeight: 500 }}>
                      no (not running)
                    </Text>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Is creating actions">
                  {bbRoutine.isLive ? (
                    <Text style={{ color: "green", fontWeight: 500 }}>
                      yes (saving actions)
                    </Text>
                  ) : (
                    <Text style={{ color: "red", fontWeight: 500 }}>
                      no (not saving actions)
                    </Text>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Last successful run at">
                  {bbRoutine?.lastRunFinished
                    ? dayjs
                        .utc(bbRoutine.lastRunFinished)
                        .tz()
                        .format("HH:mm DD.MM")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Last successful run in seconds">
                  {bbRoutine?.lastRunTime ? bbRoutine?.lastRunTime + "s" : "-"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col xs={9}>
            <Card size="small" title="Config" bordered={false}>
              <Form
                form={configForm}
                labelCol={{ span: 14 }}
                initialValues={{
                  daysAhead: bbRoutine.daysAhead,
                  noAnswersRequired: bbRoutine.noAnswersRequired,
                }}
                onFinish={handleConfigSubmit}
              >
                <Form.Item
                  label="Backup-book days ahead"
                  name="daysAhead"
                  rules={[
                    { required: true, message: "Please input when to BB!" },
                  ]}
                >
                  <InputNumber name="daysAhead" min={1} />
                </Form.Item>
                <Form.Item
                  label="Required 'NO_ANSWER' count"
                  name="noAnswersRequired"
                  rules={[
                    { required: true, message: "Please input when to BB!" },
                  ]}
                >
                  <InputNumber name="daysAhead" min={1} />
                </Form.Item>{" "}
                <Form.Item wrapperCol={{ offset: 14 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    style={{ minWidth: 100 }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
              <Text style={{ color: "#696969", paddingLeft: 75 }}>
                {currentBBDateMsg}
              </Text>
            </Card>
          </Col>
          <Col xs={5} style={{ display: "flex", flexDirection: "column" }}>
            <Button
              type="default"
              style={{ marginLeft: 7, width: 160, marginBottom: 15 }}
              onClick={() => {
                navigate(`${ROUTES.ADMIN_BB_ROUTINES}`);
              }}
            >
              Go back
            </Button>
            <Popconfirm
              overlayStyle={{ width: 300 }}
              title={
                bbRoutine.isEnabled
                  ? "This will stop the routine. Are you sure?"
                  : "This will start the routine. Are you sure?"
              }
              onConfirm={() =>
                updateBBRoutine({
                  id: bbRoutine.id,
                  isEnabled: !bbRoutine.isEnabled,
                })
              }
              disabled={isLoadingUpdateBBRoutine}
            >
              <Button
                type="primary"
                danger={bbRoutine.isEnabled}
                icon=""
                style={{ marginLeft: 7, width: 160, marginBottom: 15 }}
              >
                {bbRoutine.isEnabled ? "Stop routine" : "Start routine"}
              </Button>
            </Popconfirm>
            <Popconfirm
              overlayStyle={{ width: 300 }}
              title={
                bbRoutine.isLive
                  ? "This will stop saving backup-booking actions. Are you sure?"
                  : "This will start saving backup-booking actions. Are you sure?"
              }
              onConfirm={() =>
                updateBBRoutine({
                  id: bbRoutine.id,
                  isLive: !bbRoutine.isLive,
                })
              }
              disabled={isLoadingUpdateBBRoutine}
            >
              <Button
                type="primary"
                danger={bbRoutine.isLive}
                style={{ marginLeft: 7, width: 160, marginBottom: 15 }}
              >
                {bbRoutine.isLive ? "Disable LIVE" : "Enable LIVE"}
              </Button>
            </Popconfirm>
            <Popconfirm
              overlayStyle={{ width: 300 }}
              title={
                "This will trigger all enabled routines now. Are you sure?"
              }
              onConfirm={() => triggerBBRoutines()}
              disabled={isLoadingUpdateBBRoutine}
            >
              <Button
                disabled={isLoadingTriggerBBRoutines}
                type="primary"
                style={{ marginLeft: 7, width: 160, marginBottom: 15 }}
              >
                Trigger routine
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        <Form form={filtersForm} onFinish={handleFiltersSubmit}>
          <Row gutter={24} style={{ marginBottom: 20, marginTop: 35 }}>
            <Col xs={9}>
              <Form.Item
                style={{ marginBottom: 0 }}
                label="Filter by Clinic ID"
                name="filterByClinicId"
                rules={[
                  {
                    min: 36,
                    message: `Clinic ID (uuidv4) length is 36 characters`,
                  },
                  {
                    max: 36,
                    message: `Clinic ID (uuidv4) length is 36 characters`,
                  },
                ]}
              >
                <Input
                  name="filterByClinicId"
                  onChange={(e) =>
                    handleFilterInputChange("filterByClinicId", e)
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={9}>
              <Form.Item
                style={{ marginBottom: 0 }}
                label="Filter by Appt ID"
                name="filterByApptId"
                rules={[
                  {
                    min: 36,
                    message: `Appt ID (uuidv4) length is 36 characters`,
                  },
                  {
                    max: 36,
                    message: `Appt ID (uuidv4) length is 36 characters`,
                  },
                ]}
              >
                <Input
                  name="filterByApptId"
                  onChange={(e) => handleFilterInputChange("filterByApptId", e)}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                style={{ minWidth: 115 }}
              >
                Filter
              </Button>
              <Button
                type="default"
                size="middle"
                onClick={() => {
                  //   filtersForm.setFieldValue("filterByClinicId", null);
                  //   filtersForm.setFieldValue("filterByApptId", null);
                  filtersForm.resetFields([
                    "filterByClinicId",
                    "filterByApptId",
                  ]);
                  setFilters({
                    clinicId: null,
                    apptId: null,
                  });
                  setPagination(defaultPagination);
                  findBBRoutineLogsQuery.refetch();
                }}
                style={{ minWidth: 115, marginLeft: 20 }}
              >
                Reset filter
              </Button>
            </Col>
          </Row>
        </Form>
        <Row gutter={24} style={{ marginBottom: 20 }}>
          <Col xs={24}>
            <BBRoutineLogsTable
              pagination={pagination}
              onPaginationChange={setPagination}
              isLoading={findBBRoutineLogsQuery.isLoading}
              data={logs}
            />
          </Col>
        </Row>
      </Spin>
    </>
  );
};
