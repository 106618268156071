import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// dayjs.tz.setDefault("Europe/London");
dayjs.tz.setDefault(userTimeZone);

export { dayjs };
