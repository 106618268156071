import { Layout, Typography } from "antd";
import { FC } from "react";

const { Footer: BaseFooter } = Layout;
const { Text } = Typography;

interface FooterProps {
  isOnDarkBg?: boolean | undefined;
}

export const Footer: FC<FooterProps> = ({ isOnDarkBg }) => (
  <BaseFooter
    style={{
      textAlign: "center",
      backgroundColor: isOnDarkBg ? "transparent" : "inherit",
    }}
  >
    <Text
      type="secondary"
      style={{ color: isOnDarkBg ? "rgb(187 187 187)" : "" }}
      className="login-subline"
    >
      DeepMedical ltd © 2024
    </Text>
  </BaseFooter>
);
