import { FC, useState, useMemo } from "react";
import { Typography, Card, Row, Col, Form, Switch, Select, Spin } from "antd";
import {
  ActionsTable,
  ActionsTableFilters,
  ActionsTableRow,
} from "./components/ActionsTable";
import { useQuery, useQueryClient } from "react-query";
import { ApptDto } from "../../common/dtos/ApptDto";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { CompleteActionModal } from "./components/CompleteActionModal";
import { AuthContextType, useAuth } from "../../common/helpers/auth";
import { HospitalDto } from "../../common/dtos/HospitalDto";
import { ActionDto } from "../../common/dtos/ActionDto";
import * as ApptsAPI from "../../api/apptsApi";
import * as ActionsAPI from "../../api/actionsApi";
import { notify } from "../../common/helpers/notify";
import { APPT_PRIORITIES, APPT_TYPES } from "../../common/constants/APPTS";
import { useClinicsMultiselectContext } from "../../common/components/HospitalLayout/HospitalLayout";

const { Title } = Typography;

const mapApptsToTableRows = (appts: ApptDto[] = []) => {
  return (
    appts.reduce((previousAppts: ActionsTableRow[], currentAppt: ApptDto) => {
      const currentApptOutcomes: ActionsTableRow[] = currentAppt.actions.map(
        (action) => {
          return {
            datetime: currentAppt.datetime,
            appt: currentAppt,
            patient: currentAppt.patient,
            clinic: {
              id: currentAppt.clinic.id,
              name: currentAppt.clinic.name,
              code: currentAppt.clinic.code,
            },
            priority: currentAppt.priority,
            type: currentAppt.type,
            action,
            specialty: currentAppt.specialty,
            key: action.id,
          };
        }
      );
      return previousAppts.concat(currentApptOutcomes);
    }, []) || []
  );
};

const PAGINATION_SIZE = 20;

type CompleteActionModalDto = {
  clinicId: string;
  appt: ApptDto;
  action: any;
};

export const ListActionsPage: FC = () => {
  const { setIsUpdatingUserSettings }: any = useClinicsMultiselectContext();
  const queryClient = useQueryClient();
  const auth: AuthContextType = useAuth();
  const hospitals = auth.getHospitals();
  const [activeHospitalId, setActiveHospitalId] = useState<string>(
    hospitals?.length ? hospitals.find(() => true).id : ""
  );
  const [activeSpecialtyId, setActiveSpecialtyId] = useState<string>();
  const [activePatientId, setActivePatientId] = useState<string>();
  const [isLoadingExtended, setIsLoadingExtended] = useState<boolean>(
    !!activeHospitalId
  );
  const [completeActionModal, setCompleteActionModal] =
    useState<null | CompleteActionModalDto>(null);
  const [isShowCompleted, setShowCompleted] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: PAGINATION_SIZE,
    total: 0,
  });
  const [filters, setFilters] = useState<ActionsTableFilters>({
    priority: Object.values(APPT_PRIORITIES),
    type: Object.values(APPT_TYPES),
  });

  const { isLoading: isLoadingFindApptsWithActions, data } = useQuery(
    [
      "findApptsWithActions",
      activeHospitalId,
      activeSpecialtyId,
      activePatientId,
      isShowCompleted,
      pagination.current,
      pagination.pageSize,
      filters,
    ],
    () =>
      ApptsAPI.findApptsWithActions(
        activeHospitalId,
        isShowCompleted,
        pagination,
        filters,
        activeSpecialtyId,
        activePatientId
      ),
    {
      enabled: !!activeHospitalId,
      onSuccess: (response: any) => {
        /** If results came empty for non-first page, go 1 page back */
        let current = pagination.current;
        if (response?.results.length === 0 && pagination.current > 1) {
          --current;
        }
        setPagination({
          current: current,
          pageSize: pagination.pageSize,
          total: response.total,
        });
        setIsLoadingExtended(false);
      },
      onError: () => setIsLoadingExtended(false),
      onSettled: () => setIsUpdatingUserSettings(false),
    }
  );
  const { isLoading: isLoadingFindSpecialties, data: specialtiesData } =
    useQuery(
      ["findSpecialtiesForFilters", activeHospitalId, isShowCompleted],
      () =>
        ActionsAPI.findSpecialtiesForFilters({
          hospitalId: activeHospitalId,
          isCompleted: isShowCompleted,
        }),
      {
        enabled: !!activeHospitalId,
        onSuccess: (response: any) => {
          setIsLoadingExtended(false);
        },
        onError: () => setIsLoadingExtended(false),
        onSettled: () => setIsUpdatingUserSettings(false),
      }
    );
  const { isLoading: isLoadingFindPatients, data: patientsData } = useQuery(
    [
      "findPatientsForFilters",
      activeHospitalId,
      isShowCompleted,
      activeSpecialtyId,
    ],
    () =>
      ActionsAPI.findPatientsForFilters({
        hospitalId: activeHospitalId,
        isCompleted: isShowCompleted,
        specialtyId: activeSpecialtyId,
      }),
    {
      enabled: !!activeHospitalId,
      onSuccess: (response: any) => {
        setIsLoadingExtended(false);
      },
      onError: () => setIsLoadingExtended(false),
      onSettled: () => setIsUpdatingUserSettings(false),
    }
  );
  const actionsTableRows = useMemo(() => {
    return mapApptsToTableRows(data?.results ?? []);
  }, [data?.results]);
  const hospitalDropdownOpts = useMemo(() => {
    return hospitals.map((hospital: HospitalDto) => {
      return {
        value: hospital.id,
        label: hospital.name,
      };
    });
  }, [hospitals]);
  const specialtiesDropdownOpts = useMemo(() => {
    return specialtiesData?.map((specialty: { id: string; name: string }) => {
      return {
        value: specialty.id,
        label: specialty.name,
      };
    });
  }, [specialtiesData]);
  const patientsDropdownOpts = useMemo(() => {
    return patientsData?.map((patient: { id: string; name: string }) => {
      return {
        value: patient.id,
        label: patient.name,
      };
    });
  }, [patientsData]);

  const filterSelect = (input: any, option: any): boolean => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <>
      <Title level={3}>
        <span style={{ fontWeight: 400 }}>Performed actions</span>
      </Title>
      {completeActionModal && (
        <CompleteActionModal
          appt={completeActionModal.appt}
          action={completeActionModal.action}
          activeClinicId={completeActionModal.clinicId}
          onClose={() => setCompleteActionModal(null)}
          onSuccess={() => {
            setIsLoadingExtended(true);
            notify.success("Action updated.");
            setCompleteActionModal(null);
            queryClient.invalidateQueries("findApptsWithActions", {
              exact: false,
            });
            queryClient.invalidateQueries("findSpecialtiesForFilters", {
              exact: false,
            });
            queryClient.invalidateQueries("findPatientsForFilters", {
              exact: false,
            });
          }}
        />
      )}
      <Spin
        tip="Loading..."
        spinning={
          isLoadingFindApptsWithActions ||
          isLoadingFindSpecialties ||
          isLoadingFindPatients ||
          isLoadingExtended
        }
        size={"large"}
      >
        <Row align="middle" style={{ padding: "0px 12px 6px" }}>
          <Col span={12} style={{ display: "flex", flexDirection: "row" }}>
            <Form>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ width: 279 }}
                  onChange={(value: any) => {
                    setIsLoadingExtended(true);
                    queryClient.cancelQueries("findApptsWithActions", {
                      exact: false,
                    });
                    queryClient.cancelQueries("findSpecialtiesForFilters", {
                      exact: false,
                    });
                    queryClient.cancelQueries("findPatientsForFilters", {
                      exact: false,
                    });
                    setPagination({
                      current: 1,
                      pageSize: pagination.pageSize,
                      total: pagination.total,
                    });
                    setActiveHospitalId(value);
                    setActiveSpecialtyId(undefined);
                    setActivePatientId(undefined);
                  }}
                  options={hospitalDropdownOpts}
                  placeholder={"Select a hospital"}
                  defaultActiveFirstOption={true}
                  value={activeHospitalId}
                />
              </Form.Item>
            </Form>
            <Form>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ width: 279, marginLeft: 10 }}
                  onChange={(value: any) => {
                    setIsLoadingExtended(true);
                    queryClient.cancelQueries("findSpecialtiesForFilters", {
                      exact: false,
                    });
                    queryClient.cancelQueries("findPatientsForFilters", {
                      exact: false,
                    });
                    setActiveSpecialtyId(value);
                    setActivePatientId(undefined);
                  }}
                  options={specialtiesDropdownOpts}
                  placeholder={"Select a specialty"}
                  value={activeSpecialtyId}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterSelect}
                  allowClear
                />
              </Form.Item>
            </Form>
            <Form>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ width: 279, marginLeft: 10 }}
                  onChange={(value: any) => {
                    setIsLoadingExtended(true);
                    queryClient.cancelQueries("findPatientsForFilters", {
                      exact: false,
                    });
                    setActivePatientId(value);
                  }}
                  options={patientsDropdownOpts}
                  placeholder={"Select a patient"}
                  value={activePatientId}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterSelect}
                  allowClear
                />
              </Form.Item>
            </Form>
            <Form>
              <Form.Item
                label="Show completed"
                style={{ marginBottom: 0, marginRight: 0, marginLeft: 20 }}
              >
                <Switch
                  disabled={!activeHospitalId}
                  onChange={(checked: boolean) => {
                    setIsLoadingExtended(true);
                    setPagination({
                      current: 1,
                      pageSize: pagination.pageSize,
                      total: pagination.total,
                    });
                    setActiveSpecialtyId(undefined);
                    setActivePatientId(undefined);
                    setShowCompleted(checked);
                  }}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Card size="small" bodyStyle={{ padding: 0 }}>
          <Row>
            <Col span={24}>
              <ActionsTable
                isShowCompleted={isShowCompleted}
                data={actionsTableRows}
                pagination={pagination}
                onPaginationChange={setPagination}
                onCompleteClick={(
                  clinicId: string,
                  appt: ApptDto,
                  action: ActionDto
                ) => setCompleteActionModal({ clinicId, appt, action })}
                onFiltersChange={(filters: ActionsTableFilters) => {
                  setPagination({
                    current: 1,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                  });
                  // sorting the filter arrays prevent unnecessary data fetching if the new filters array contains the same values but in a different order
                  setFilters({
                    priority: filters?.priority
                      ? filters.priority.sort()
                      : Object.values(APPT_PRIORITIES),
                    type: filters?.type
                      ? filters.type.sort()
                      : Object.values(APPT_TYPES),
                  });
                }}
              />
            </Col>
          </Row>
        </Card>
      </Spin>
    </>
  );
};
