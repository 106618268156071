import {
  CreateSmsJobDto,
  SmsJobDto,
  GetSmsJobsResponse,
  UpdateSmsJobDto,
} from "../admin/sms-jobs/dtos/SmsJobDto";
import { api } from "./api";
import { GetSmsLogsResponseDto } from "./dtos/smsApiResponses";

export const getMessageTemplate = async (): Promise<string> => {
  const response = await api.get("/admin/sms/template", {
    withCredentials: true,
  });
  return response.data as string;
};

export const createSmsJob = async (
  createSmsJobDto: CreateSmsJobDto
): Promise<void> => {
  const response = (await api.post("/admin/sms", createSmsJobDto, {
    withCredentials: true,
  })) as void;
  return response;
};

export const getSmsJobs = async (
  limit: number,
  page: number
): Promise<GetSmsJobsResponse> => {
  const response = (await api.get("/admin/sms", {
    withCredentials: true,
    params: {
      limit,
      page,
    },
  })) as GetSmsJobsResponse;
  return response;
};

export const getSmsJobById = async (smsJobId: string): Promise<SmsJobDto> => {
  const response = (await api.get(`/admin/sms/${smsJobId}`, {
    withCredentials: true,
  })) as SmsJobDto;
  return response;
};

export const deleteSmsJob = async (smsJobId: string): Promise<void> => {
  await api.delete(`/admin/sms/${smsJobId}`, {
    withCredentials: true,
  });
};

export const updateSmsJob = async (
  smsJobId: string,
  updateSmsJobDto: UpdateSmsJobDto
): Promise<void> => {
  await api.patch(`/admin/sms/${smsJobId}`, updateSmsJobDto, {
    withCredentials: true,
  });
};

export const estimateSms = async (clinicIds: string[]): Promise<number> => {
  const response = (await api.post(
    "/admin/sms/estimate",
    {
      clinicIds,
    },
    {
      withCredentials: true,
    }
  )) as number;
  return response;
};

export const getSmsLogs = async (
  smsJobId: string,
  limit: number,
  page: number
): Promise<GetSmsLogsResponseDto> => {
  const response = (await api.get(`/admin/sms/${smsJobId}/logs`, {
    withCredentials: true,
    params: {
      limit,
      page,
    },
  })) as GetSmsLogsResponseDto;
  return response;
};
