import { ApptDto } from "../common/dtos/ApptDto";
import { PaginationDto } from "../common/dtos/PaginationDto";
import { ActionsTableFilters } from "../hospital/actions/components/ActionsTable";
import { api } from "./api";
import { GetApptsToCompleteResponseDto } from "./dtos/apptsApiResponses";

export const findBookedDnaAppts = async (date: any): Promise<ApptDto[]> => {
  const response = (await api.get("/appts/" + date.format("YYYY-MM-DD"), {
    withCredentials: true,
  })) as ApptDto[];
  return response;
};

export const getOverview = async ({ queryKey }: any): Promise<any> => {
  const [month, year] = queryKey;
  const response = await api.get("/appts/overview/" + month + "/" + year, {
    withCredentials: true,
  });
  return response;
};

export const findCancelledAppts = async (
  isOnlyFilled: boolean = false
): Promise<any> => {
  const response = await api.get("/appts/cancellations", {
    withCredentials: true,
    params: {
      isOnlyFilled,
    },
  });
  return response;
};

export const addAction = async ({
  clinicId,
  apptId,
  type,
  outcome,
  callNote,
  isDnaPrevented,
}: {
  clinicId: string;
  apptId: string;
  type: number;
  outcome: number;
  callNote: string | null;
  isDnaPrevented?: boolean;
}): Promise<void> => {
  await api.post(
    "/appts/" + clinicId + "/actions/add",
    { clinicId, apptId, type, outcome, callNote, isDnaPrevented },
    {
      withCredentials: true,
    }
  );
};

export const cancelAction = async ({
  clinicId,
  apptId,
  actionId,
}: {
  clinicId: string;
  apptId: string;
  actionId: string;
}): Promise<void> => {
  await api.delete("/appts/" + clinicId + "/actions/cancel/" + actionId, {
    withCredentials: true,
  });
};

export const findApptsWithActions = async (
  hospitalId: string,
  showCompleted: boolean = false,
  pagination: Partial<PaginationDto>,
  filters: ActionsTableFilters,
  specialtyId?: string,
  patientId?: string
): Promise<GetApptsToCompleteResponseDto> => {
  const response = (await api.get("/appts/actions", {
    withCredentials: true,
    params: {
      hospitalId,
      isCompleted: showCompleted,
      page: pagination.current,
      limit: pagination.pageSize,
      priorities: filters.priority,
      types: filters.type,
      specialtyId,
      patientId,
    },
  })) as GetApptsToCompleteResponseDto;
  return response;
};

export const updateAction = async (actionDto: any): Promise<void> => {
  await api.patch(
    `/appts/${actionDto.clinicId}/actions/update/${actionDto.id}`,
    {
      id: actionDto.id,
      outcome: actionDto.outcome,
      callNote: actionDto.callNote,
      actionNote: actionDto.actionNote,
      isCompleted: actionDto.isCompleted,
      isDnaPrevented: actionDto.isDnaPrevented,
    },
    {
      withCredentials: true,
    }
  );
};

export const updateCancelledAppt = async (updateCancelledApptDto: {
  apptId: string;
  clinicId: string;
  isFilled: boolean;
}): Promise<void> => {
  await api.patch(
    `/appts/${updateCancelledApptDto.clinicId}/cancellations/${updateCancelledApptDto.apptId}`,
    {
      apptId: updateCancelledApptDto.apptId,
      isFilled: updateCancelledApptDto.isFilled,
    },
    {
      withCredentials: true,
    }
  );
};
