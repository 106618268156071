import { ROUTES } from "../common/constants/ROUTES";
import { api } from "./api";
import {
  BBRoutineDto,
  FindBBRoutinesResponseDto,
  UpdateBBRoutineDto,
  FindBBRoutineLogsResponseDto,
} from "./dtos/adminApiBBRoutinesDtos";

export const findAll = async (): Promise<FindBBRoutinesResponseDto> => {
  const response = (await api.get(ROUTES.ADMIN_BB_ROUTINES, {
    withCredentials: true,
  })) as FindBBRoutinesResponseDto;
  return response;
};

export const findLogs = async (
  bbRoutineId: string,
  pagination: { current: number; pageSize: number },
  filters?: { clinicId?: string | null; apptId?: string | null }
): Promise<FindBBRoutineLogsResponseDto> => {
  const response = (await api.get(
    `${ROUTES.ADMIN_BB_ROUTINES}/${bbRoutineId}/logs`,
    {
      withCredentials: true,
      params: {
        page: pagination.current,
        limit: pagination.pageSize,
        filterByClinicId: filters?.clinicId,
        filterByApptId: filters?.apptId,
      },
    }
  )) as FindBBRoutineLogsResponseDto;
  return response;
};

export const get = async ({ queryKey }: any): Promise<BBRoutineDto> => {
  const [bbRoutineId] = queryKey;
  const response = (await api.get(
    `${ROUTES.ADMIN_BB_ROUTINES}/${bbRoutineId}`,
    {
      withCredentials: true,
    }
  )) as BBRoutineDto;
  return response;
};

export const update = async (
  updateBBRoutineDto: UpdateBBRoutineDto
): Promise<void> => {
  await api.patch(
    `${ROUTES.ADMIN_BB_ROUTINES}/${updateBBRoutineDto.id}`,
    updateBBRoutineDto,
    {
      withCredentials: true,
    }
  );
};

export const trigger = async (): Promise<void> => {
  await api.post(
    `${ROUTES.ADMIN_BB_ROUTINES}/all/trigger`,
    {},
    {
      withCredentials: true,
    }
  );
};
