import { api } from "./api";

export const findSpecialtiesForFilters = async ({
  hospitalId,
  isCompleted,
}: {
  hospitalId: string;
  isCompleted: boolean;
}): Promise<{ id: string; name: string }[]> => {
  const response = (await api.get("/actions/filters/specialties", {
    withCredentials: true,
    params: {
      hospitalId,
      isCompleted,
    },
  })) as { id: string; name: string }[];
  return response;
};

export const findPatientsForFilters = async ({
  hospitalId,
  isCompleted,
  specialtyId,
}: {
  hospitalId: string;
  isCompleted: boolean;
  specialtyId?: string;
}): Promise<{ id: string; name: string }[]> => {
  const response = (await api.get("/actions/filters/patients", {
    withCredentials: true,
    params: {
      hospitalId,
      isCompleted,
      specialtyId,
    },
  })) as { id: string; name: string }[];
  return response;
};
