export const SETTING_NAMES = {
  DISABLE_NOTIFICATIONS: 1,
  SELECTED_CLINICS: 2,
};

export const SETTING_TYPES = {
  BOOL: 1,
  INT: 2,
  STR: 3,
  JSON: 4,
};
