import { FILTER_TYPES } from "../components/CommForm";
import { ApptsFilterFormDto } from "../dtos/CommDto";

/**
 * Validates the filters for the form, to ensure that the estimates are fetched only when the filters are valid.
 * @param clinics
 * @param filters
 * @returns
 */
export function shouldGetEstimate(
  filters: ApptsFilterFormDto,
  clientId?: string,
  commId?: string
): boolean {
  if (filters.type === FILTER_TYPES.EXTERNAL && clientId && commId) {
    return true;
  }
  if (filters.apptIds?.length) return true;
  if (!filters?.clinics?.length) return false;
  if (filters?.ageFilter) {
    const { minAge, maxAge } = filters.ageFilter;
    if ((!minAge && minAge !== 0) || !maxAge || minAge >= maxAge) {
      return false;
    }
  }
  if (filters?.reasonFilter) {
    const { name, minWeight } = filters.reasonFilter;
    if (name && typeof minWeight !== "number") {
      return false;
    }
    if (typeof minWeight === "number" && !name) {
      return false;
    }
  }
  if (filters?.dnaFilter) {
    const { minDna, maxDna } = filters.dnaFilter;
    if ((!minDna && minDna !== 0) || !maxDna || minDna >= maxDna) {
      return false;
    }
  }
  if (!filters?.offsetDaysFromCurrent) {
    return false;
  }
  return true;
}
