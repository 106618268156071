import type { FC } from "react";
import { Spin, Typography } from "antd";
import { Button, Checkbox, Form, Input, Card, Row, Col } from "antd";
import logo from "../../common/images/logo-large.png";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useAuth } from "../../common/helpers/auth";
import * as authAPI from "../../api/authApi";
import { notify } from "../../common/helpers/notify";
import { EnableUserDto } from "../../api/dtos/authApiRequests";
const { Text, Title } = Typography;

const MAX_EMAIL_LENGTH = 64;
const MIN_EMAIL_LENGTH = 6;
const MAX_NAME_LENGTH = 32;
const MIN_NAME_LENGTH = 2;

export const ConfirmUserPage: FC = () => {
  let { id: userId } = useParams();
  const [form] = Form.useForm();
  const auth: any = useAuth();
  const { mutate: enableUser, isLoading: isLoadingConfirmUser }: any =
    useMutation(authAPI.enableUser, {
      onSuccess: () => {
        auth.login({
          email: form.getFieldValue("email"),
          password: form.getFieldValue("password"),
        });
        form.resetFields();
      },
      onError: () =>
        notify.error(
          "Unable to activate user. Please contact our tech support."
        ),
    });
  const onFinish = async (values: EnableUserDto) => {
    const enableUserDto: EnableUserDto = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    };
    enableUser(enableUserDto);
  };

  const {
    isLoading,
    isError,
    data: user,
  } = useQuery([userId], authAPI.getUserForActivation);
  if (isError)
    return <Title>An error has occurred. Try reloading this page.</Title>;
  return (
    <Row gutter={24}>
      <Col xs={24}>
        <div style={{ textAlign: "center", marginTop: "2%" }}>
          <img src={logo} className="login-logo" alt="logo" />
          <br />
          <Text style={{ color: "rgb(131 192 238)" }} className="login-subline">
            Driving equitable access to healthcare
          </Text>
        </div>
      </Col>
      <Col xs={12} offset={6} style={{ marginTop: 30 }}>
        <Spin spinning={isLoading || isLoadingConfirmUser}>
          <Card title={"Welcome! Add your profile info below:"}>
            {user && (
              <Form
                form={form}
                size="large"
                name="normal_login"
                className="login-form"
                labelCol={{ span: 7 }}
                initialValues={{
                  id: user.id,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                }}
                onFinish={onFinish}
              >
                <Form.Item label={false} name="id" hidden={true}>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Name!",
                    },
                    {
                      min: MIN_NAME_LENGTH,
                      message: `Name min length is ${MIN_NAME_LENGTH} characters`,
                    },
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Name max length is ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Surname"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Surname!",
                    },
                    {
                      min: MIN_NAME_LENGTH,
                      message: `Surname min length is ${MIN_NAME_LENGTH} characters`,
                    },
                    {
                      max: MAX_NAME_LENGTH,
                      message: `Surname max length is ${MAX_NAME_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      min: MIN_EMAIL_LENGTH,
                      message: `Email min length is ${MIN_EMAIL_LENGTH} characters`,
                    },
                    {
                      max: MAX_EMAIL_LENGTH,
                      message: `Email max length is ${MAX_EMAIL_LENGTH} characters`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                    {
                      min: 6, //TBD (store in shared config?)
                      message: `Password min length is 6 characters`,
                    },
                    {
                      max: 16,
                      message: `Password max length is 16 characters`,
                    },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item
                  label="Confirm password"
                  name="repeatPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your Password!",
                    },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "Please read and accept terms of service"
                              )
                            ),
                    },
                  ]}
                  wrapperCol={{
                    xs: {
                      span: 24,
                      offset: 0,
                    },
                    sm: {
                      span: 16,
                      offset: 7,
                    },
                  }}
                >
                  <Checkbox style={{ color: "#1890ff" }}>
                    Click here to agree to our Terms of Service
                  </Checkbox>
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ minWidth: 200 }}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            )}
            <Text>
              <a href={"mailto:info@deep-medical.ai"}>Contact us</a> with any
              questions.
            </Text>
          </Card>
        </Spin>
      </Col>
    </Row>
  );
};
