import { Spin, TreeSelect } from "antd";
import { ReactElement, useState } from "react";
import { useAuth } from "../../../helpers/auth";
import type { SelectCommonPlacement } from "antd/es/_util/motion";
import { useMutation } from "react-query";
import * as usersAPI from "../../../../api/usersApi";
import { SETTING_NAMES, SETTING_TYPES } from "../../../constants/SETTINGS";
import { useQueryClient } from "react-query";
import { ClinicDto } from "../../../dtos/ClinicDto";

const { SHOW_CHILD } = TreeSelect;

type ClinicsMultiSelectProps = {
  isHidden: boolean;
  setIsUpdatingUserSettings: any;
};

type MenuClinicWithSpecialty = {
  id: string;
  name: string;
  hospital: { id: string; name: string };
  specialty: { id: string; name: string };
};

type TreeNode = {
  title: string;
  value: string;
  children: Record<string, TreeNode>;
};
type TreeMap = Record<string, TreeNode>;

export const ClinicsMultiSelect: React.FC<ClinicsMultiSelectProps> = ({
  setIsUpdatingUserSettings,
  isHidden,
}: any) => {
  const { user }: any = useAuth();
  /** Clinics can be in multiple specialties, thats why we use composite keys */
  const clinicsCompositeKeys: Record<string, string[]> =
    user?.clinics.length > 0
      ? Object.fromEntries(
          user.clinics.map((clinic: ClinicDto) => [
            clinic.id,
            clinic.specialties.map(
              (specialty) => `${specialty.id}_${clinic.id}`
            ),
          ])
        )
      : {};
  const initiallySelectedKeys: any[] = [];
  for (const clinicId of user.selectedClinics) {
    clinicsCompositeKeys[clinicId].forEach((compositeClinicId) => {
      initiallySelectedKeys.push(compositeClinicId);
    });
  }
  const [isLoadingExtendedTimeout, setIsLoadingExtendedTimeout] =
    useState<any>(null);
  const [isLoadingExtended, setIsLoadingExtended] = useState<boolean>(false);
  const [value, setValue]: any = useState(initiallySelectedKeys);
  const queryClient = useQueryClient();
  const { isLoading, mutate: updateSettings }: any = useMutation(
    usersAPI.updateMySettings,
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === "findBookedDnaAppts",
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[2] === "getOverview",
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === "findCancelledAppts",
        });
      },
    }
  );

  const duplicateClinicsBySpecialty = (
    clinics: ClinicDto[]
  ): MenuClinicWithSpecialty[] => {
    const result: MenuClinicWithSpecialty[] = [];
    clinics.forEach((clinic) => {
      clinic.specialties.forEach((specialty) => {
        result.push({
          id: clinic.id,
          hospital: clinic.hospital,
          name: clinic.name,
          specialty: specialty,
        });
      });
    });
    return result;
  };

  /**
   * Also removes unused fields.
   */
  const createClinicsTree = (clinics: ClinicDto[]) => {
    const clinicsWithSpecialties = duplicateClinicsBySpecialty(clinics);
    const treeMap: TreeMap = {};
    clinicsWithSpecialties.forEach((clinic) => {
      if (!treeMap[clinic.hospital.id]) {
        treeMap[clinic.hospital.id] = {
          title: clinic.hospital.name,
          value: clinic.hospital.id,
          children: {},
        };
      }
      if (!treeMap[clinic.hospital.id].children[clinic.specialty.id]) {
        treeMap[clinic.hospital.id].children[clinic.specialty.id] = {
          title: clinic.specialty.name,
          value: `${clinic.hospital.id}_${clinic.specialty.id}`, // HAS TO BE UNIQUE TREE-wide
          children: {},
        };
      }
      if (
        !treeMap[clinic.hospital.id].children[clinic.specialty.id].children[
          clinic.id
        ]
      ) {
        treeMap[clinic.hospital.id].children[clinic.specialty.id].children[
          clinic.id
        ] = {
          title: clinic.name,
          value: `${clinic.specialty.id}_${clinic.id}`, // HAS TO BE UNIQUE TREE-wide
          children: {},
        };
      }
    });
    const toTreeNode = (node: {
      title: string;
      key: string;
      value: string;
      children: any[];
    }): any => {
      if (!node) return;
      const { title, key, value, children } = node;
      return {
        title,
        key,
        value,
        children:
          children && Object.values(children).length > 0
            ? Object.values(children).map((child) => toTreeNode(child))
            : [],
      };
    };
    const treeData = Object.values(treeMap).map((node: any) =>
      toTreeNode(node)
    );
    return treeData;
  };

  const onChange = async (selectedValues: string[]) => {
    setIsUpdatingUserSettings(true);
    let updatedCompositeClinicsIds: string[] = [];
    if (value.length > selectedValues.length) {
      if (selectedValues.length !== 0) {
        const removedClinicIds: string[] = value.filter(
          (item: string) => !selectedValues.includes(item)
        );
        let allClinicIdsToRemove: string[] = [];
        for (const removedCompositeClinicId of removedClinicIds) {
          const removedClinicId = removedCompositeClinicId.split("_")[1];
          allClinicIdsToRemove = allClinicIdsToRemove.concat(
            clinicsCompositeKeys[removedClinicId]
          );
        }
        updatedCompositeClinicsIds = selectedValues.filter(
          (item) => !allClinicIdsToRemove.includes(item)
        );
      }
    } else {
      const addedClinicIds: string[] = selectedValues.filter(
        (item: string) => !value.includes(item)
      );
      let allClinicIdsToAdd: string[] = [];
      for (const addedCompositeClinicId of addedClinicIds) {
        const addedClinicId = addedCompositeClinicId.split("_")[1];
        allClinicIdsToAdd = allClinicIdsToAdd.concat(
          clinicsCompositeKeys[addedClinicId]
        );
      }
      updatedCompositeClinicsIds = [...value, ...allClinicIdsToAdd];
    }
    const clinicIdsList: string[] = [];
    for (const val of updatedCompositeClinicsIds) {
      const selectedClinicId = val.split("_")[1];
      if (!clinicIdsList.includes(selectedClinicId))
        clinicIdsList.push(selectedClinicId);
    }
    setValue([...updatedCompositeClinicsIds]);
    await updateSettings({
      name: SETTING_NAMES.SELECTED_CLINICS,
      type: SETTING_TYPES.JSON,
      valueJson: clinicIdsList,
    });
  };

  const treeData = createClinicsTree(user?.clinics ?? []);
  let placement: SelectCommonPlacement = "bottomRight";
  const tProps = {
    allowClear: true,
    autoClearSearchValue: false,
    showSearch: true,
    treeLine: true,
    treeData,
    value,
    onChange,
    placement,
    maxTagCount: 0,
    filterTreeNode: (search: string, item: any) => {
      return item.title.toLowerCase().includes(search.toLowerCase());
    },
    treeCheckable: true,
    treeDefaultExpandAll: true,
    showCheckedStrategy: SHOW_CHILD,
    maxTagPlaceholder: (omittedValues: any) => {
      if (omittedValues.length === 1)
        return omittedValues.length + " item selected";
      return omittedValues.length + " items selected";
    },
    placeholder: "Select or search clinic(s)",
    listHeight: 800,
    popupMatchSelectWidth: 550,
    style: {
      paddingTop: 17,
      paddingRight: 15,
      minWidth: 350,
      display: "inline-block",
    },
  };
  if (isHidden) tProps.style.display = "none";
  if (isLoadingExtended !== isLoading && !isLoadingExtendedTimeout) {
    setIsLoadingExtended(isLoading);
    const timeout = setTimeout(() => {
      setIsLoadingExtended(!isLoading);
      setIsLoadingExtendedTimeout(null);
    }, 1000);
    setIsLoadingExtendedTimeout(timeout);
  }
  return (
    <TreeSelect
      {...tProps}
      dropdownRender={(menu: ReactElement<any>) => (
        <Spin spinning={isLoadingExtended}>{menu}</Spin>
      )}
    />
  );
};
