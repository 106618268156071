import { FC, useCallback, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Switch,
  Button,
  Radio,
  Space,
  Popconfirm,
  Tag,
} from "antd";
import { ActionDto } from "../../../common/dtos/ActionDto";
import {
  ACTION_TYPES,
  CALL_OUTCOMES,
  getOutcomeText,
} from "../../../common/helpers/actions";
import TextArea from "antd/lib/input/TextArea";
import { useMutation } from "react-query";
import * as apptsApi from "../../../api/apptsApi";
import { notify } from "../../../common/helpers/notify";
import { useState } from "react";
import { CALLHASHTAGS, hasTag, removeTag } from "../constants/CALLHASHTAGS";
import { ApptDto } from "../../../common/dtos/ApptDto";
import { dayjs } from "../../../common/helpers/dayjs";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import { BirthDateWithGender } from "../../../common/components/BirthDateWithGender";

interface EditActionModalProps {
  appt: ApptDto;
  action: ActionDto;
  clinicId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const { CheckableTag } = Tag;

export const EditActionModal: FC<EditActionModalProps> = ({
  appt,
  clinicId,
  onClose,
  action,
  onSuccess,
}: EditActionModalProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([""]);
  const [form] = Form.useForm();
  const { mutate: updateAction, isLoading } = useMutation(
    (updatedAction: any) => apptsApi.updateAction(updatedAction),
    {
      onSuccess: async () => {
        notify.success("Action updated.");
        onSuccess();
        onClose();
      },
      onError: () => notify.error("Unable to update the action."),
    }
  );
  const { mutate: cancelAction, isLoading: isLoadingCancelAction }: any =
    useMutation(apptsApi.cancelAction, {
      onSuccess: async () => {
        notify.success("Action removed.");
        onSuccess();
        onClose();
      },
      onError: () => notify.error("Unable to remove the action."),
    });

  const initTags = useCallback(() => {
    const callNote = form.getFieldValue("callNote");
    const containedHashtags: string[] = [];
    Object.entries(CALLHASHTAGS).forEach(([, hashtag]) => {
      if (hasTag(hashtag, callNote)) containedHashtags.push(hashtag);
    });
    setSelectedTags(containedHashtags);
  }, [form]);

  useEffect(() => {
    initTags();
  }, [initTags]);

  const onTagClick = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    const callNote = form.getFieldValue("callNote") ?? "";
    if (!hasTag(CALLHASHTAGS[tag], callNote)) {
      form.setFieldsValue({
        callNote: callNote + " " + CALLHASHTAGS[tag],
      });
    } else {
      form.setFieldsValue({
        callNote: removeTag(tag, callNote),
      });
    }
  };
  const initialValues = {
    outcome: action.outcome,
    callNote: action.callNote,
    isDnaPrevented: action.isDnaPrevented,
  };
  const isWithPii = appt.patient.patientIdRaw != null ? true : false;
  return (
    <Modal
      title={`Edit action: ${getOutcomeText(action.outcome)}`}
      width={isWithPii ? 860 : 520}
      style={isWithPii ? { minWidth: 860 } : { minWidth: 520 }}
      open={true}
      destroyOnClose={true}
      confirmLoading={isLoading || isLoadingCancelAction}
      onCancel={onClose}
      footer={
        <div>
          <Popconfirm
            title="Delete action?"
            style={{ cursor: "pointer" }}
            onConfirm={() =>
              cancelAction({
                clinicId,
                apptId: appt.id,
                actionId: action.id,
              })
            }
          >
            <Button danger style={{ float: "left" }}>
              Delete
            </Button>
          </Popconfirm>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={async () => form.submit()}>
            Confirm
          </Button>
        </div>
      }
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {isWithPii && (
          <div className="modal-patient-side">
            {appt.patient.firstName && appt.patient.lastName && (
              <h3>
                <UserOutlined
                  style={{
                    color: "#fff",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                />{" "}
                {appt.patient.lastName.toUpperCase()}, {appt.patient.firstName}
              </h3>
            )}
            <ul className="patient-pii">
              {appt.patient.patientIdRaw && (
                <li>
                  <span className="color-gray">Patient ID</span>{" "}
                  <span style={{ fontWeight: "500" }}>
                    {appt.patient.patientIdRaw}
                  </span>
                </li>
              )}
              <li>
                <span className="color-gray">Birth date</span>
                <span>
                  <BirthDateWithGender
                    birthDate={appt.patient.birthDate}
                    gender={appt.patient.gender}
                  />
                </span>
              </li>
              {appt.patient.phone && (
                <li>
                  <span className="color-gray">Phone</span>{" "}
                  <span>{appt.patient.phone}</span>
                </li>
              )}
              {appt.patient.phoneLandline && (
                <li>
                  <span className="color-gray">Phone landline</span>{" "}
                  <span>{appt.patient.phoneLandline}</span>
                </li>
              )}
            </ul>
            <h3>
              <ClockCircleOutlined
                style={{
                  color: "#fff",
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
              />{" "}
              {dayjs.utc(appt.datetime).tz().format("HH:mm (DD.MM)")}
            </h3>
            <ul className="patient-pii">
              <li>
                <span className="color-gray">Specialty</span>{" "}
                <span>{appt.specialty.name}</span>
              </li>
              <li>
                <span
                  className="color-gray"
                  style={{ paddingRight: 10, alignSelf: "flex-start" }}
                >
                  Clinic
                </span>
                <span style={{ textAlign: "right" }}>
                  {appt.clinic.name} ({appt.clinic.code})
                </span>
              </li>
              {appt.site && (
                <li>
                  <span className="color-gray">Site</span>
                  <span style={{ textAlign: "right" }}>{appt.site}</span>
                </li>
              )}
              {appt.consultationMechanism && (
                <li>
                  <span className="color-gray">Consultation mechanism</span>
                  <span style={{ textAlign: "right" }}>
                    {appt.consultationMechanism}
                  </span>
                </li>
              )}
            </ul>
          </div>
        )}
        <div style={{ flex: "1.8" }}>
          <Form
            form={form}
            labelCol={{ span: 6 }}
            onFinish={(values) =>
              updateAction({
                clinicId,
                id: action.id,
                ...values,
              })
            }
            autoComplete="off"
            size="large"
            name="update_action"
            initialValues={{ ...initialValues }}
          >
            {action.type === ACTION_TYPES.CALL && (
              <Form.Item
                label="Outcome"
                name="outcome"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={CALL_OUTCOMES.CONFIRMED}>
                      Patient confirmed attendance
                    </Radio>
                    <Radio value={CALL_OUTCOMES.RESCHEDULED}>Rescheduled</Radio>
                    <Radio value={CALL_OUTCOMES.RESCHEDULED_VIRTUAL}>
                      Rescheduled as virtual appointment
                    </Radio>
                    <Radio value={CALL_OUTCOMES.ESCALATION_CALL}>
                      Escalation - call requested
                    </Radio>
                    <Radio value={CALL_OUTCOMES.PATIENT_CANCELLED}>
                      Patient cancelled
                    </Radio>
                    {/* <Radio value={CALL_OUTCOMES.NO_ANSWER}>No answer</Radio> */}
                  </Space>
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              label="Call notes:"
              name="callNote"
              style={{
                marginTop: 15,
                marginBottom: 7,
              }}
            >
              <TextArea
                rows={6}
                value={action.callNote ?? ""}
                onChange={() => initTags()}
              />
            </Form.Item>
            <Row gutter={24}>
              <Col xs={0} sm={6}></Col>
              <Col xs={24} sm={18} style={{ marginBottom: 15, paddingLeft: 6 }}>
                <Space size={[0, 8]} wrap>
                  {Object.entries(CALLHASHTAGS).map(([tag, hashtag]) => (
                    <CheckableTag
                      className="extra-outcome-tag"
                      key={tag}
                      checked={hasTag(hashtag, form.getFieldValue("callNote"))}
                      onChange={(checked) => onTagClick(tag, checked)}
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </Space>
              </Col>
            </Row>
            <Form.Item
              label="DNA prevented"
              name="isDnaPrevented"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
