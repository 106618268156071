import {
  Button,
  Form,
  Tooltip,
  Modal,
  Radio,
  Space,
  Switch,
  Tag,
  Col,
  Row,
} from "antd";
import { useState, useCallback, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as apptsAPI from "../../../api/apptsApi";
import { MdPhoneInTalk } from "react-icons/md";
import { ACTION_TYPES, CALL_OUTCOMES } from "../../../common/helpers/actions";
import TextArea from "antd/lib/input/TextArea";
import { notify } from "../../../common/helpers/notify";
import { CALLHASHTAGS, hasTag, removeTag } from "../constants/CALLHASHTAGS";
import { PatientDto } from "../../../common/dtos/PatientDto";
import { ApptDto } from "../../../common/dtos/ApptDto";
import { dayjs } from "../../../common/helpers/dayjs";
import { BirthDateWithGender } from "../../../common/components/BirthDateWithGender";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";

interface ActionCalledType {
  clinicId: string;
  appt: ApptDto;
  patient: PatientDto; // todo: add type
  refetchAppts: any;
  collapseActioned: any;
  isEnabled: boolean;
}

const { CheckableTag } = Tag;

export const ActionCalled: any = ({
  clinicId,
  appt,
  patient,
  refetchAppts,
  collapseActioned,
}: ActionCalledType) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([""]);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState<boolean>(false);
  const { mutate: addAction, isLoading }: any = useMutation(
    apptsAPI.addAction,
    {
      onSuccess: async () => {
        notify.success("Action saved.");
        setIsOutcomeModalOpen(false);
        await refetchAppts();
        form.resetFields();
        setTimeout(() => collapseActioned(appt.id), 1000);
      },
      onError: () => {
        notify.error("Unable to add action. Please try again.");
      },
    }
  );
  const initTags = useCallback(() => {
    const containedHashtags: string[] = [];
    if (isOutcomeModalOpen) {
      const callNote = form.getFieldValue("callNote");
      Object.entries(CALLHASHTAGS).forEach(([, hashtag]) => {
        if (hasTag(hashtag, callNote)) containedHashtags.push(hashtag);
      });
    }
    setSelectedTags(containedHashtags);
  }, [form, isOutcomeModalOpen]);

  useEffect(() => {
    if (isOutcomeModalOpen) initTags();
  }, [isOutcomeModalOpen, initTags]);
  // }, [initTags]);
  const onTagClick = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    const callNote = form.getFieldValue("callNote") ?? "";
    if (!hasTag(CALLHASHTAGS[tag], callNote)) {
      form.setFieldsValue({
        callNote: callNote + " " + CALLHASHTAGS[tag],
      });
    } else {
      form.setFieldsValue({
        callNote: removeTag(tag, callNote),
      });
    }
  };
  const isWithPii = patient.patientIdRaw != null ? true : false;
  return (
    <>
      <Modal
        title='New action "Called"'
        width={isWithPii ? 860 : 520}
        style={isWithPii ? { minWidth: 860 } : { minWidth: 520 }}
        open={isOutcomeModalOpen}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { minWidth: 90 } }}
        onOk={() => {
          queryClient.cancelQueries(["findBookedDnaAppts"], { exact: false });
          form.submit();
        }}
        okText="Confirm"
        onCancel={() => setIsOutcomeModalOpen(false)}
      >
        {isOutcomeModalOpen && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {isWithPii && (
              <div className="modal-patient-side">
                {patient.firstName && patient.lastName && (
                  <h3>
                    <UserOutlined
                      style={{
                        color: "#fff",
                        marginLeft: "3px",
                        marginRight: "3px",
                      }}
                    />{" "}
                    {patient.lastName.toUpperCase()}, {patient.firstName}
                  </h3>
                )}
                <ul className="patient-pii">
                  {patient.patientIdRaw && (
                    <li>
                      <span className="color-gray">Patient ID</span>{" "}
                      <span style={{ fontWeight: "500" }}>
                        {patient.patientIdRaw}
                      </span>
                    </li>
                  )}
                  <li>
                    <span className="color-gray">Birth date</span>
                    <span>
                      <BirthDateWithGender
                        birthDate={patient.birthDate}
                        gender={patient.gender}
                      />
                    </span>
                  </li>
                  {patient.phone && (
                    <li>
                      <span className="color-gray">Phone</span>{" "}
                      <span>{patient.phone}</span>
                    </li>
                  )}
                  {patient.phoneLandline && (
                    <li>
                      <span className="color-gray">Phone landline</span>{" "}
                      <span>{patient.phoneLandline}</span>
                    </li>
                  )}
                </ul>
                <h3>
                  <ClockCircleOutlined
                    style={{
                      color: "#fff",
                      marginLeft: "3px",
                      marginRight: "3px",
                    }}
                  />{" "}
                  {dayjs.utc(appt.datetime).tz().format("HH:mm (DD.MM)")}
                </h3>
                <ul className="patient-pii">
                  <li>
                    <span className="color-gray">Specialty</span>{" "}
                    <span>{appt.specialty.name}</span>
                  </li>
                  <li>
                    <span
                      className="color-gray"
                      style={{ paddingRight: 10, alignSelf: "flex-start" }}
                    >
                      Clinic
                    </span>
                    <span style={{ textAlign: "right" }}>
                      {appt.clinic.name} ({appt.clinic.code})
                    </span>
                  </li>
                  {appt.site && (
                    <li>
                      <span className="color-gray">Site</span>
                      <span style={{ textAlign: "right" }}>{appt.site}</span>
                    </li>
                  )}
                  {appt.consultationMechanism && (
                    <li>
                      <span className="color-gray">Consultation mechanism</span>
                      <span style={{ textAlign: "right" }}>
                        {appt.consultationMechanism}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            )}
            <div style={{ flex: "1.8" }}>
              <Form
                labelCol={{ span: 6 }}
                form={form}
                className={"disable-select"}
                initialValues={{ isDnaPrevented: false }}
                onFinish={(data) => {
                  if (data.outcome === null ?? data.outcome === undefined) {
                    notify.error("Please select a call outcome.");
                  } else {
                    addAction({
                      clinicId: clinicId,
                      type: ACTION_TYPES.CALL,
                      apptId: appt.id,
                      callNote: data.callNote ?? null,
                      isDnaPrevented: data.isDnaPrevented,
                      ...data,
                    });
                  }
                }}
              >
                <Form.Item
                  label="Outcome"
                  name="outcome"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value={CALL_OUTCOMES.CONFIRMED}>
                        Patient confirmed attendance
                      </Radio>
                      <Radio value={CALL_OUTCOMES.RESCHEDULED}>
                        Rescheduled
                      </Radio>
                      <Radio value={CALL_OUTCOMES.RESCHEDULED_VIRTUAL}>
                        Rescheduled as virtual appointment
                      </Radio>
                      <Radio value={CALL_OUTCOMES.ESCALATION_CALL}>
                        Escalation - call requested
                      </Radio>
                      <Radio value={CALL_OUTCOMES.PATIENT_CANCELLED}>
                        Patient cancelled
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="callNote"
                  label="Call notes:"
                  style={{
                    marginTop: 15,
                    marginBottom: 7,
                  }}
                >
                  <TextArea
                    rows={6}
                    placeholder="max 500 characters"
                    maxLength={500}
                    onChange={() => initTags()}
                  />
                </Form.Item>
                <Row gutter={24}>
                  <Col xs={0} sm={6}></Col>
                  <Col
                    xs={24}
                    sm={18}
                    style={{ marginBottom: 15, paddingLeft: 6 }}
                  >
                    <Space size={[0, 8]} wrap>
                      {Object.entries(CALLHASHTAGS).map(([tag, hashtag]) => (
                        <CheckableTag
                          className="extra-outcome-tag"
                          key={tag}
                          checked={hasTag(
                            hashtag,
                            form.getFieldValue("callNote")
                          )}
                          onChange={(checked) => onTagClick(tag, checked)}
                        >
                          {tag}
                        </CheckableTag>
                      ))}
                    </Space>
                  </Col>
                </Row>
                <Form.Item
                  label="DNA prevented"
                  name="isDnaPrevented"
                  style={{ marginBottom: 0 }}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
      </Modal>
      <>
        <Tooltip title={"Called"}>
          <Button
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            className={"btn-recommendation "}
            shape="circle"
            style={{ backgroundColor: "#ccc", borderColor: "#ccc" }}
            onClick={(e) => setIsOutcomeModalOpen(true)}
            icon={<MdPhoneInTalk />}
          />
        </Tooltip>
      </>
    </>
  );
};
