import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Button, Space } from "antd";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as commsApi from "../../../../api/commApi";
import { notify } from "../../../../common/helpers/notify";

interface DeleteCommCredentialsProps {
  commCredentialsId: string;
}

export const DeleteCommCredentialsButton: FC<DeleteCommCredentialsProps> = ({
  commCredentialsId,
}) => {
  const queryClient = useQueryClient();
  const [isPopConfirmOpen, setIsPopConfirmOpen] = useState<boolean>(false);
  const deleteCommMutation = useMutation(commsApi.deleteCommCredentials, {
    onSuccess: () => {
      notify.success("Credentials were successsfully deleted.");
      queryClient.invalidateQueries(["get-comm-credentials"]);
    },
    onSettled: () => setIsPopConfirmOpen(false),
    onError: () => notify.error("Unable to delete the credentials."),
  });
  return (
    <Popconfirm
      title="Delete the set of credentials?"
      open={isPopConfirmOpen}
      onCancel={() => setIsPopConfirmOpen(false)}
      onConfirm={() => deleteCommMutation.mutate(commCredentialsId)}
      okButtonProps={{ loading: deleteCommMutation.isLoading }}
      cancelButtonProps={{ disabled: deleteCommMutation.isLoading }}
    >
      <Space>
        <Button
          onClick={() => setIsPopConfirmOpen(true)}
          shape="circle"
          icon={<DeleteOutlined style={{ color: "red" }} />}
          size={"middle"}
        />
      </Space>
    </Popconfirm>
  );
};
