import { useState, type FC } from "react";
import {
  Card,
  Typography,
  Col,
  Row,
  Spin,
  Form,
  Button,
  Input,
  Table,
  Alert,
} from "antd";
import { useQuery } from "react-query";
import { BackButton } from "../../common/components/BackButton";
import { useForm } from "antd/es/form/Form";
import * as adminApiClinics from "../../api/adminApiClinics";
import { SelectClient } from "../users/components/SelectClient";
import { Link } from "react-router-dom";
import { ClinicDto } from "../../common/dtos/ClinicDto";
import { truncate } from "../../common/helpers/truncate";

const { Title, Text } = Typography;
const defaultSortOrder: any = "descend";

const MIN_SEARCH_LENGTH = 3;
const MAX_SEARCH_LENGTH = 36; // not sure why would anyone need longer

const columns = [
  {
    width: 20,
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (text: string, record: any, index: number) => {
      return (
        <Link to={`/admin/clinics/view/${record.id}`}>
          {truncate(text, 64)}
        </Link>
      );
    },
  },
  {
    defaultSortOrder,
    width: 190,
    title: "Code",
    dataIndex: "code",
  },
  {
    title: "Hospital",
    width: 150,
    dataIndex: "hospital",
    render: function (text: any, record: any, index: any) {
      return <Text>{truncate(record.hospital.name, 24)}</Text>;
    },
  },
  {
    title: "Cap (BB)",
    width: 100,
    dataIndex: "cap",
    render: function (cap: number | null) {
      return <Text>{cap}</Text>;
    },
  },
  {
    title: "Threshold (DNA)",
    width: 140,
    dataIndex: "threshold",
    render: (threshold: number | null) => threshold,
  },
  {
    width: 20,
  },
];

export const SearchClinicsPage: FC = () => {
  const [form] = useForm<{ client: string; searchedText: string }>();
  const [searchRequest, setSearchRequest] = useState<{
    clientId: string;
    searchedText: string;
  }>({
    clientId: "",
    searchedText: "",
  });
  const [clinics, setClinics] = useState<ClinicDto[]>();
  const { isLoading, isError, error } = useQuery(
    [searchRequest.clientId, searchRequest.searchedText, "searchClinics"],
    () =>
      adminApiClinics.searchClinics(
        searchRequest.searchedText,
        searchRequest.clientId
      ),
    {
      enabled:
        searchRequest.clientId !== "" && searchRequest.searchedText !== "",
      onSuccess: (response: ClinicDto[]) => {
        const clinicsWithKeys = response.map((c) => {
          return {
            key: c.id,
            ...c,
          };
        });
        setClinics(clinicsWithKeys);
      },
    }
  );

  const onFinish = async (values: { client: string; searchedText: string }) => {
    setSearchRequest({
      clientId: values.client,
      searchedText: values.searchedText.trim(),
    });
  };

  const formStyle: React.CSSProperties = {
    maxWidth: "none",
    background: "rgba(0, 0, 0, 0.02)",
    borderRadius: 8,
    padding: 24,
    paddingBottom: 0,
  };

  return (
    <>
      <Title level={3}>{`Search clinics`}</Title>
      <Row gutter={24}>
        <Col xs={24}>
          <Spin spinning={isLoading} size="large">
            <Card
              size="small"
              bodyStyle={{ padding: 0 }}
              headStyle={{ paddingTop: 7, paddingBottom: 7 }}
              title={<BackButton />}
            >
              <Form
                form={form}
                style={formStyle}
                labelCol={{ span: 8 }}
                onFinish={onFinish}
              >
                <Row gutter={24}>
                  <Col xs={10}>
                    <Form.Item
                      label="Select client"
                      name="client"
                      style={{ marginBottom: 0 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select the client!",
                        },
                      ]}
                    >
                      <SelectClient
                        placeholder="Select client"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={10}>
                    <Form.Item
                      label="Clinic code or name"
                      name="searchedText"
                      style={{ marginBottom: 0 }}
                      rules={[
                        {
                          required: true,
                          message: "Please type the searched string!",
                        },
                        {
                          min: MIN_SEARCH_LENGTH,
                          message: `Name min length is ${MIN_SEARCH_LENGTH} characters`,
                        },
                        {
                          max: MAX_SEARCH_LENGTH,
                          message: `Name max length is ${MAX_SEARCH_LENGTH} characters`,
                        },
                      ]}
                    >
                      <Input name="searchedText" />
                    </Form.Item>
                  </Col>
                  <Col xs={4}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        style={{ minWidth: 130 }}
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              {isError && (
                <Alert
                  type="error"
                  showIcon={true}
                  message={(error as Error).message}
                  style={{ margin: 12 }}
                />
              )}

              <Table
                dataSource={clinics}
                size="middle"
                columns={columns}
                style={{ marginTop: 12 }}
                pagination={{
                  defaultPageSize: 30,
                  hideOnSinglePage: true,
                }}
              />
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};
