import type { FC } from "react";
import { Typography, Alert, Spin } from "antd";
import { Button, Form, Input, Card } from "antd";
import logo from "../../common/images/logo-large.png";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import * as authAPI from "../../api/authApi";
import { notify } from "../../common/helpers/notify";

const { Text } = Typography;

export const RecoverPassPage: FC = () => {
  const {
    mutate: requestResetPassword,
    isSuccess,
    isError,
    isLoading,
  }: any = useMutation(authAPI.requestResetPassword, {
    onSuccess: () => notify.success("We've just sent you an email."),
    onError: () => notify.error("Something went wrong."),
  });
  const onFinish = (values: any) => {
    const { email } = values;
    requestResetPassword({ email });
  };
  if (isError) {
    return (
      <>
        <div className="login-form-top">
          <img src={logo} className="login-logo" alt="logo" />
          <br />
          <Text style={{ color: "rgb(131 192 238)" }} className="login-subline">
            AI Enhanced Decision Making.
          </Text>
        </div>
        <div className="login-form-container">
          <Card title={"Pasword reset failed."}>
            <Alert
              description="We couldn't find the address in our system or a reset link may have already been sent. Please check your email, as reset links are valid for 24 hours."
              type="error"
              style={{ marginBottom: 15 }}
            />
          </Card>
        </div>
      </>
    );
  }
  if (isSuccess) {
    return (
      <>
        <div className="login-form-top">
          <img src={logo} className="login-logo" alt="logo" />
          <br />
          <Text style={{ color: "rgb(131 192 238)" }} className="login-subline">
            AI Enhanced Decision Making.
          </Text>
        </div>
        <div className="login-form-container">
          <Card title={"Please check your email."}>
            <Text>
              To proceed, please use link from the email that we've just sent to
              you.
            </Text>
          </Card>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="login-form-top">
        <img src={logo} className="login-logo" alt="logo" />
        <br />
        <Text style={{ color: "rgb(131 192 238)" }} className="login-subline">
          AI Enhanced Decision Making.
        </Text>
      </div>
      <div className="login-form-container">
        <Spin spinning={isLoading}>
          <Card title={"Please enter your email."}>
            <Form
              size="large"
              name="reset-password"
              className="login-form"
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  autoComplete="off"
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn-login">
                  Request password reset
                </Button>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Link to="/login">Back to Sign-in</Link>
              </Form.Item>
            </Form>
          </Card>
        </Spin>
      </div>
    </>
  );
};
