import { Button, Tooltip } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as apptsAPI from "../../../api/apptsApi";
import { MdPhoneMissed } from "react-icons/md";
import { ACTION_TYPES, CALL_OUTCOMES } from "../../../common/helpers/actions";
import { notify } from "../../../common/helpers/notify";

interface ActionCalledType {
  clinicId: string;
  apptId: string;
  refetchAppts: any;
  isEnabled: boolean;
}

export const ActionCalledNoAnswer: any = ({
  clinicId,
  apptId,
  refetchAppts,
}: ActionCalledType) => {
  const queryClient = useQueryClient();
  useState<boolean>(false);
  const { mutate: addAction, isLoading }: any = useMutation(
    apptsAPI.addAction,
    {
      onSuccess: async () => {
        notify.success("Action saved.");
        // setIsOutcomeModalOpen(false);
        await refetchAppts();
      },
    }
  );
  return (
    <>
      <Tooltip title={"Called, no answer"}>
        <Button
          disabled={isLoading}
          htmlType="submit"
          type="primary"
          className={"btn-recommendation "}
          shape="circle"
          style={{ backgroundColor: "#ccc", borderColor: "#ccc" }}
          onClick={(e) => {
            queryClient.cancelQueries(["findBookedDnaAppts"], { exact: false });
            addAction({
              clinicId: clinicId,
              type: ACTION_TYPES.CALL,
              apptId: apptId,
              outcome: CALL_OUTCOMES.NO_ANSWER,
            });
          }}
          icon={<MdPhoneMissed />}
        />
      </Tooltip>
    </>
  );
};
