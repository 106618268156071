import { MdCheckBox, MdCancel } from "react-icons/md";
import { Table, Typography } from "antd";
import { FC } from "react";
import { dayjs } from "../../../common/helpers/dayjs";
import { useNavigate } from "react-router-dom";
import { BBRoutineDto } from "../../../api/dtos/adminApiBBRoutinesDtos";
import { ROUTES } from "../../../common/constants/ROUTES";
const { Text } = Typography;

const defaultSortOrder: any = "descend";

interface BBRoutinesTableProps {
  data: BBRoutineDto[];
  isLoadingFindBBRoutines: boolean;
}

const columns = [
  {
    width: 20,
  },
  {
    defaultSortOrder,
    title: "Client code",
    // width: 140,
    dataIndex: "client",
    render: (client: { code: string }, record: any, index: number) => {
      return <Text>{client.code}</Text>;
    },
  },
  // {
  //  is this a good idea?
  //   title: "BB done today",
  //   key: "bbDoneToday",
  //   width: 140,
  //   render: (BBRoutine: BBRoutineDto, record: any, index: any) => 40,
  // },
  {
    title: "Last OK finish",
    dataIndex: "lastRunFinished",
    width: 140,
    render: (lastRunFinished: string) => {
      return lastRunFinished ? (
        <Text>{dayjs.utc(lastRunFinished).tz().format("HH:mm DD.MM")}</Text>
      ) : (
        <Text>-</Text>
      );
    },
  },
  {
    title: "Last OK duration",
    dataIndex: "lastRunTime",
    width: 155,
    render: (lastRunTime: number) =>
      lastRunTime !== undefined ? <Text>{lastRunTime}s</Text> : <Text>-</Text>,
  },
  {
    title: "Is live",
    dataIndex: "isLive",
    width: 100,
    render: (isLive: boolean) =>
      isLive ? (
        <MdCheckBox style={{ color: "green", fontSize: 18 }} />
      ) : (
        <MdCancel style={{ color: "red", fontSize: 18 }} />
      ),
  },
  {
    title: "Is enabled",
    dataIndex: "isEnabled",
    key: "isEnabled",
    width: 100,
    render: (isEnabled: boolean) =>
      isEnabled ? (
        <MdCheckBox style={{ color: "green", fontSize: 18 }} />
      ) : (
        <MdCancel style={{ color: "red", fontSize: 18 }} />
      ),
  },
  { width: 20 },
];

export const BBRoutinesTable: FC<BBRoutinesTableProps> = ({
  data,
  isLoadingFindBBRoutines,
}) => {
  const navigate = useNavigate();

  return (
    <Table
      size="middle"
      loading={isLoadingFindBBRoutines}
      onRow={(record) => ({
        onClick: () => navigate(`${ROUTES.ADMIN_BB_ROUTINES}/${record.id}`),
      })}
      rowKey={(row) => row.id}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
};
