import axios, { AxiosError, AxiosResponse } from "axios";
import { ROUTES } from "../common/constants/ROUTES";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  headers: {
    Expires: 0,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    "Content-Type": "application/json",
  },
});

// Less than optimal solution currently used.
// Could be done more nicely if react-router-dom worked outside of components (history).
// Current approach is uses unstable history router.
// Has potential issues with history lib dependency missmatch
// (internally used in router) and directly defined version in package.json
api.interceptors.response.use(
  /** Normalize response by returning data directly */
  (response: AxiosResponse) => response.data,
  (error: AxiosError | Error | any) => {
    /** Handle redirection in case API responds with unauthorized */
    if (
      error.response !== undefined &&
      error.response.status === 403 &&
      !window.location.href.includes(ROUTES.LOGIN) &&
      !window.location.href.includes(ROUTES.RECOVER_PASS) &&
      !window.location.href.includes("/reset-password") &&
      !window.location.href.includes("/confirm-user/")
    ) {
      window.location.replace(ROUTES.LOGIN);
    }
    /** Normalizes error message */
    let normalizedError;
    if (axios.isAxiosError(error)) {
      normalizedError = new Error(
        error?.response?.data.message ?? "Http errror"
      );
    } else if (error instanceof Error) {
      normalizedError = new Error("Application error");
    } else {
      /**
       * The browser and node.js can throw native errors that don't extend the Error class.
       * (Ie. GeolocationPositionError is not an Error and it may not contain a stack trace).
       */
      normalizedError = new Error("Unknown error");
    }
    return Promise.reject(normalizedError);
  }
);

export { api };
