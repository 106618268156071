import { api } from "../api";

/**
 * Returns count of affected appts.
 */
export const cancelByClinic = async (clinicId: string): Promise<number> => {
  const response = (await api.post(
    `/admin/appts/cancel/clinic/${clinicId}`,
    {},
    {
      withCredentials: true,
    }
  )) as number;
  return response;
};

export const cancelByClinics = async (batchCancelApptsDto: {
  clientId: string;
  codes: { clinic_code: string }[];
}): Promise<number> => {
  const response = (await api.post(
    `/admin/appts/cancel/clinics`,
    batchCancelApptsDto,
    {
      withCredentials: true,
    }
  )) as number;
  return response;
};
