import { useState } from "react";
import type { SelectProps } from "antd/es/select";
import * as adminAPI from "../../../api/adminApi";
import { Select, Spin } from "antd";
import { useQuery } from "react-query";
import { notify } from "../../../common/helpers/notify";
import type { SelectOptionDto } from "../../../common/dtos/SelectOptionDto";
import { RoleDto } from "../../../common/dtos/RoleDto";

export function SelectRole({ ...props }: SelectProps) {
  const [options, setOptions] = useState<SelectOptionDto[]>([]);
  const { isLoading: isGetClientsLoading }: any = useQuery(
    ["getRoles"],
    adminAPI.getRoles,
    {
      onSuccess: (roles: RoleDto[]) => {
        const clientsOpts: SelectOptionDto[] = roles.map((role: RoleDto) => {
          return {
            label: role.name,
            value: role.id,
            key: role.id,
          };
        });
        setOptions(clientsOpts);
      },
      onError: (error: Error) =>
        notify.error(error.message ?? "Unable to get roles."),
    }
  );
  return (
    <Select
      labelInValue={false}
      filterOption={false}
      notFoundContent={
        isGetClientsLoading ? <Spin size="small" /> : "no results"
      }
      {...props}
      options={options}
    />
  );
}
