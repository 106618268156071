import {
  ManOutlined,
  QuestionOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { FC } from "react";
import { GENDERS } from "../constants/GENDERS";

const { Text } = Typography;

interface BirthDateWithGenderProps {
  birthDate: string;
  gender: number;
}

export const BirthDateWithGender: FC<BirthDateWithGenderProps> = ({
  birthDate,
  gender,
}: BirthDateWithGenderProps) => {
  let genderIcon = <QuestionOutlined style={{ color: "#ccc" }} />;
  if (gender === GENDERS.MALE) {
    genderIcon = <ManOutlined style={{ color: "#468ea7" }} />;
  } else if (gender === GENDERS.FEMALE) {
    genderIcon = <WomanOutlined style={{ color: "#f36c84" }} />;
  }
  return (
    <>
      {birthDate && <Text> {birthDate}</Text>}
      {gender !== undefined && (
        <Text style={{ marginLeft: 5 }}>{genderIcon}</Text>
      )}
    </>
  );
};
