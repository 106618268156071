import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "../constants/ROUTES";
import { ListApptsPage } from "../../hospital/list/ListApptsPage";
import { LoginPage } from "../../public/login/LoginPage";
import { ConfirmUserPage } from "../../public/confirm-user/ConfirmUserPage";
import { RecoverPassPage } from "../../public/recover-pass/RecoverPassPage";
import { ConfirmRecoverPassPage } from "../../public/confirm-recover-pass/ConfirmRecoverPassPage";
import { CalendarPage } from "../../hospital/calendar/CalendarPage";
import { SettingsPage } from "../../hospital/settings/SettingsPage";
import { DashboardPage } from "../../admin/dashboard/DashboardPage";
import { ListEmailLogsPage } from "../../admin/email-logs/ListEmailLogsPage";
import { ListClinicsPage } from "../../admin/clinics/ListClinicsPage";
import { ViewClinicPage } from "../../admin/clinics/ViewClinicPage";
import { ListUsersPage } from "../../admin/users/ListUsersPage";
import { ListSyncsPage } from "../../admin/syncs/ListSyncsPage";
import { CreateUserPage } from "../../admin/users/CreateUserPage";
import { EditUserPage } from "../../admin/users/EditUserPage";
import { useAuth } from "../helpers/auth";
import { HospitalLayout } from "./HospitalLayout/HospitalLayout";
import { AdminLayout } from "./AdminLayout/AdminLayout";
import { PublicLayout } from "./PublicLayout/PublicLayout";
import { ListCancelledApptsPage } from "../../hospital/cancellations/ListCancelledApptsPage";
import { ListActionsPage } from "../../hospital/actions/ListActionsPage";
import { Insights } from "../../hospital/insights/InsightsPage";
import { CreateSmsJobPage } from "../../admin/sms-jobs/CreateSmsJobPage";
import { EditSmsJobPage } from "../../admin/sms-jobs/EditSmsJobPage";
import { ListSmsJobsPage } from "../../admin/sms-jobs/ListSmsJobsPage";
import { ListSmsLogsPage } from "../../admin/sms-jobs/ListSmsLogsPage";
import { ListCommsPage } from "../../admin/comms/ListCommsPage";
import { CreateCommPage } from "../../admin/comms/CreateCommPage";
import { EditCommPage } from "../../admin/comms/EditCommPage";
import { ListCommMessagesPage } from "../../admin/comms/ListCommMessagesPage";
import { ListCommCredentialsPage } from "../../admin/comms/credentials/ListCommCredentialsPage";
import { CreateCommCredentialsPage } from "../../admin/comms/credentials/CreateCommCredentialsPage";
import { UpdateThresholdsPage } from "../../admin/clinics/UpdateThresholdsPage";
import { ListBBRoutinesPage } from "../../admin/bb-routines/ListBBRoutinesPage";
import { ViewBBRoutinePage } from "../../admin/bb-routines/ViewBBRoutinePage";
import { SearchClinicsPage } from "../../admin/clinics/SearchClinicsPage";
import { CancelApptsByClinicsPage } from "../../admin/clinics/CancelApptsByClinicsPage";

function RequireAuth({ children, redirectTo }: any) {
  const { isLoadingCurrentUser, user }: any = useAuth();
  if (isLoadingCurrentUser) return <></>; // prevents redirect to login before user is set
  return user ? children : <Navigate to={redirectTo} />;
}

function RequireDMAdmin({ children, redirectTo }: any) {
  const { isLoadingCurrentUser, user }: any = useAuth();
  if (isLoadingCurrentUser) return <></>; // prevents redirect to login before user is set
  return user && user.isSuperAdmin ? children : <Navigate to={redirectTo} />;
}

export const AppRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route
        path={ROUTES.CONFIRM_USER}
        element={
          <PublicLayout>
            <ConfirmUserPage />
          </PublicLayout>
        }
      />
      <Route
        path={ROUTES.LOGIN}
        element={
          <PublicLayout>
            <LoginPage />
          </PublicLayout>
        }
      />
      <Route
        path={ROUTES.RECOVER_PASS}
        element={
          <PublicLayout>
            <RecoverPassPage />
          </PublicLayout>
        }
      />
      <Route
        path={ROUTES.CONFIRM_RECOVER_PASS}
        element={
          <PublicLayout>
            <ConfirmRecoverPassPage />
          </PublicLayout>
        }
      />
      <Route
        path={ROUTES.APPTS}
        element={
          <RequireAuth redirectTo="/login">
            <HospitalLayout>
              <ListApptsPage />
            </HospitalLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.APPTS_DATE}
        element={
          <RequireAuth redirectTo="/login">
            <HospitalLayout>
              <ListApptsPage />
            </HospitalLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.APPTS_CALENDAR}
        element={
          <RequireAuth redirectTo="/login">
            <HospitalLayout>
              <CalendarPage />
            </HospitalLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.CANCELLATIONS}
        element={
          <RequireAuth redirectTo="/login">
            <HospitalLayout>
              <ListCancelledApptsPage />
            </HospitalLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.ACTIONS}
        element={
          <RequireAuth redirectTo="/login">
            <HospitalLayout>
              <ListActionsPage />
            </HospitalLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.SETTINGS}
        element={
          <RequireAuth redirectTo="/login">
            <HospitalLayout>
              <SettingsPage />
            </HospitalLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.ADMIN_HOME}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <DashboardPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_EMAIL_LOGS}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListEmailLogsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_CLINICS}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListClinicsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_CLINICS_VIEW}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ViewClinicPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_CLINICS_THRESHOLDS}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <UpdateThresholdsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_CLINICS_SEARCH}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <SearchClinicsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_CLINICS_APPTS_CANCELLATION}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <CancelApptsByClinicsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_SYNC}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListSyncsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_USERS}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListUsersPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_USERS_INVITE}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <CreateUserPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_USERS_EDIT}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <EditUserPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.INSIGHTS}
        element={
          <RequireAuth redirectTo="/login">
            <HospitalLayout>
              <Insights />
            </HospitalLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.ADMIN_COMMS}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListCommsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_COMM_CREDENTIALS}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListCommCredentialsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_COMM_CREDENTIALS_CREATE}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <CreateCommCredentialsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_COMMS_CREATE}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <CreateCommPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_COMMS_EDIT}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <EditCommPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_COMM_MESSAGES_VIEW}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListCommMessagesPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_SMS_JOBS}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListSmsJobsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_SMS_JOBS_CREATE}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <CreateSmsJobPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_SMS_JOBS_EDIT}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <EditSmsJobPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_SMS_JOBS_LOGS_VIEW}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListSmsLogsPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_BB_ROUTINES}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ListBBRoutinesPage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
      <Route
        path={ROUTES.ADMIN_BB_ROUTINES_VIEW}
        element={
          <RequireDMAdmin redirectTo="/login">
            <AdminLayout>
              <ViewBBRoutinePage />
            </AdminLayout>
          </RequireDMAdmin>
        }
      />
    </Routes>
  );
};
