import { FC } from "react";
import { Card, Spin } from "antd";
import { useQuery } from "react-query";
import * as smsJobsApi from "../../../api/smsApi";

export const MessagePreview: FC = () => {
  const { isLoading, data, isSuccess } = useQuery(
    "message-template",
    smsJobsApi.getMessageTemplate,
    {
      cacheTime: 3600, // 1h
    }
  );

  return (
    <Card title="Message preview">
      <Spin spinning={isLoading}>{isSuccess && data}</Spin>
    </Card>
  );
};
