import type { FC } from "react";
import { Typography, Card, Alert } from "antd";
import { useQuery } from "react-query";
import * as adminBBRoutinesApi from "../../api/adminBBRoutinesApi";
import { BBRoutinesTable } from "./components/BBRoutinesTable";
import { notify } from "../../common/helpers/notify";

const { Title } = Typography;

export const ListBBRoutinesPage: FC = () => {
  const {
    isLoading: isLoadingFindBBRoutines,
    isError,
    data,
    error,
  } = useQuery(["findBBRoutines"], adminBBRoutinesApi.findAll, {
    onError: () => notify.error(`Error getting routines list.`),
  });
  if (isError)
    return (
      <Alert type="error" showIcon={true} message={(error as Error).message} />
    );
  return (
    <>
      <Title level={3}>Backup-booking routines</Title>
      <Card
        size="small"
        bodyStyle={{ padding: 0 }}
        headStyle={{ paddingTop: 7, paddingBottom: 7 }}
      >
        <BBRoutinesTable
          data={data?.results ?? []}
          isLoadingFindBBRoutines={isLoadingFindBBRoutines}
        />
      </Card>
    </>
  );
};
