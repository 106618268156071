import { PlusOutlined } from "@ant-design/icons";
import { TablePaginationConfig, Alert, Typography, Card, Button } from "antd";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import * as smsJobsApi from "../../api/smsApi";
import { ROUTES } from "../../common/constants/ROUTES";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { SmsJobDto } from "./dtos/SmsJobDto";
import { SmsJobsTable } from "./components/SmsJobsTable";
import { notify } from "../../common/helpers/notify";
const { Title } = Typography;
const PAGE_SIZE = 20;

export const ListSmsJobsPage: FC = () => {
  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: PAGE_SIZE,
    sortBy: "created",
    order: "ASC",
    total: 0,
  });
  const { data, isError, isLoading } = useQuery(
    ["get-sms-jobs", ...Object.values(pagination)],
    async () => {
      return smsJobsApi.getSmsJobs(pagination.pageSize, pagination.current);
    },
    {
      onSuccess: (data) => {
        let current = pagination.current;
        if (data?.results.length === 0 && pagination.current > 1) {
          --current;
        }
        setPagination({
          ...pagination,
          current: current,
          total: data.total,
        });
      },
      onError: () => notify.error("Unable to get message routines."),
    }
  );
  const smsJobs: SmsJobDto[] = (data?.results as SmsJobDto[]) || [];
  const handlePaginationChange = (newPagination: TablePaginationConfig) => {
    setPagination({
      ...pagination,
      current: newPagination.current ?? 1,
      pageSize: newPagination.pageSize ?? PAGE_SIZE,
    });
  };
  if (isError) {
    return (
      <Alert
        message="Error"
        description="Unable to get message routines."
        type="error"
        showIcon
        style={{ marginBottom: 15 }}
      />
    );
  }
  return (
    <>
      <Title level={3}>Messaging routines</Title>
      <Card
        size="small"
        title={
          <Link to={ROUTES.ADMIN_SMS_JOBS_CREATE}>
            <Button type="primary">
              <PlusOutlined />
              Create
            </Button>
          </Link>
        }
      >
        <SmsJobsTable
          data={smsJobs}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePaginationChange}
        />
      </Card>
    </>
  );
};
