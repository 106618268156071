import { useState } from "react";
import type { SelectProps } from "antd/es/select";
import * as adminAPI from "../../../api/adminApi";
import { Select, Spin } from "antd";
import { useQuery } from "react-query";
import { ClientDto } from "../../../common/dtos/ClientDto";
import { notify } from "../../../common/helpers/notify";
import { SelectOptionDto } from "../../../common/dtos/SelectOptionDto";

export function SelectClient({ ...props }: SelectProps) {
  const [options, setOptions] = useState<SelectOptionDto[]>([]);
  const { isLoading: isGetClientsLoading }: any = useQuery(
    ["getClients"],
    adminAPI.getClients,
    {
      onSuccess: (clients: ClientDto[]) => {
        const clientsOpts: SelectOptionDto[] = clients.map((client: any) => {
          return {
            label: client.code,
            value: client.id,
            key: client.id,
          };
        });
        setOptions(clientsOpts);
      },
      onError: (error: Error) =>
        notify.error(error.message ?? "Unable to get clients."),
    }
  );
  return (
    <Select
      labelInValue={false}
      filterOption={false}
      notFoundContent={
        isGetClientsLoading ? <Spin size="small" /> : "no results"
      }
      {...props}
      options={options}
    />
  );
}
