import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, Button, Space } from "antd";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as smsJobsApi from "../../../api/smsApi";
import { notify } from "../../../common/helpers/notify";

interface DeleteSmsJobProps {
  smsJobId: string;
}

export const DeleteSmsJobButton: FC<DeleteSmsJobProps> = ({ smsJobId }) => {
  const queryClient = useQueryClient();
  const [isPopConfirmOpen, setIsPopConfirmOpen] = useState<boolean>(false);
  const deleteSmsJobMutation = useMutation(smsJobsApi.deleteSmsJob, {
    onSuccess: () => {
      notify.success("Routine was deleted.");
      queryClient.invalidateQueries(["get-sms-jobs"]);
    },
    onSettled: () => {
      setIsPopConfirmOpen(false);
    },
    onError: () => {
      notify.error("Unable to delete a routine.");
    },
  });
  return (
    <Popconfirm
      title="Delete this routine?"
      open={isPopConfirmOpen}
      onCancel={() => setIsPopConfirmOpen(false)}
      onConfirm={() => deleteSmsJobMutation.mutate(smsJobId)}
      okButtonProps={{ loading: deleteSmsJobMutation.isLoading }}
      cancelButtonProps={{ disabled: deleteSmsJobMutation.isLoading }}
    >
      <Space>
        <Button
          onClick={() => setIsPopConfirmOpen(true)}
          shape="circle"
          icon={<DeleteOutlined style={{ color: "red" }} />}
          size={"middle"}
        />
      </Space>
    </Popconfirm>
  );
};
