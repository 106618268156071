import type { FC } from "react";
import {
  Card,
  Typography,
  Col,
  Row,
  Spin,
  Form,
  Upload,
  Button,
  Popover,
} from "antd";
import { useMutation } from "react-query";
import { BackButton } from "../../common/components/BackButton";
import { RcFile } from "antd/lib/upload";
import { useForm } from "antd/es/form/Form";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { readFileContent } from "../comms/utils/file";
import { notify } from "../../common/helpers/notify";
import * as adminApiClinics from "../../api/adminApiClinics";
import { UpdateThresholdsDto } from "../../api/dtos/adminApiClinicsDtos";

const { Title, Text } = Typography;

export interface ThresholdsFormValues {
  file?: RcFile;
}

const schema =
  '{ "thresholds": [ { "client_code": "TST", "clinic_code": "BTLSTRGL",  "threshold": 16,  "cap": 3 } ] }';

export const UpdateThresholdsPage: FC = () => {
  const [form] = useForm<ThresholdsFormValues>();
  const { mutate: updateThresholds, isLoading: isLoadingUpdateThresholds } =
    useMutation(
      (updateThresholdsDto: UpdateThresholdsDto) =>
        adminApiClinics.updateThresholds(updateThresholdsDto),
      {
        onSuccess: ({
          affectedClinicsCount,
          missingClinicsCount,
        }: {
          affectedClinicsCount: number;
          missingClinicsCount: number;
        }) =>
          notify.success(
            `Clinics updated: ${affectedClinicsCount}, missing: ${missingClinicsCount}`
          ),
        onError: ({
          affectedClinicsCount,
          missingClinicsCount,
        }: {
          affectedClinicsCount: number;
          missingClinicsCount: number;
        }) =>
          notify.error(
            `Error updating clinics. Updated: ${affectedClinicsCount}, missing: ${missingClinicsCount}`
          ),
      }
    );

  const onFinish = async (values: ThresholdsFormValues) => {
    const file = values?.file;
    if (!file) {
      notify.error("Please select the file first.");
      return false;
    }
    try {
      const content = await readFileContent(file);
      const thresholds = JSON.parse(content);
      updateThresholds(thresholds);
    } catch (e) {
      notify.error(
        "Unable to parse the JSON file. Please verify the format and values."
      );
    }
  };

  return (
    <>
      <Title level={3}>{`Update clinics thresholds & caps`}</Title>
      <Row gutter={24}>
        <Col xs={14}>
          <Spin spinning={isLoadingUpdateThresholds} size="large">
            <Card
              title={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <BackButton />{" "}
                  <Popover
                    placement="bottom"
                    title="Info"
                    overlayInnerStyle={{ maxWidth: 300 }}
                    content={
                      <>
                        <Text>Current schema: {schema}</Text>
                        <ul>
                          <li>missing clinics are ignored</li>
                          <li>max clinics count in JSON 20k</li>
                          <li>max JSON size 3MB</li>
                          <li>rounds thresholds to two decimals</li>
                          <li>converts thresholds to percentages</li>
                          <li>
                            default threshold is null (fallback to
                            isPredictedDna)
                          </li>
                          <li>default cap is 0 (BB not allowed)</li>
                        </ul>
                      </>
                    }
                  >
                    <Button>
                      <InfoCircleOutlined />
                    </Button>
                  </Popover>
                </div>
              }
              size="small"
              bordered={false}
              headStyle={{ paddingTop: 7, paddingBottom: 7 }}
            >
              <Form
                form={form}
                labelCol={{ span: 5 }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="JSON file"
                  name="file"
                  required={true}
                  valuePropName="file"
                  getValueFromEvent={(event) => {
                    return event.file;
                  }}
                  rules={[
                    { required: true, message: "Please upload a JSON file." },
                  ]}
                >
                  <Upload
                    accept=".json"
                    maxCount={1}
                    name="file"
                    multiple={false}
                    beforeUpload={() => {
                      return false; // no default http request
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Select file</Button>
                  </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    style={{ minWidth: 100 }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};
