import "./calendar.css";
import type { FC } from "react";
import {
  Typography,
  Calendar,
  Badge,
  Card,
  DatePicker,
  Spin,
  Form,
  Button,
} from "antd";
import { dayjs } from "../../common/helpers/dayjs";
import { useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import * as apptsAPI from "../../api/apptsApi";
import { Link, useNavigate } from "react-router-dom";
import { useClinicsMultiselectContext } from "../../common/components/HospitalLayout/HospitalLayout";
import { ROUTES } from "../../common/constants/ROUTES";

const { Title } = Typography;
const validRangeStart = dayjs.utc("2023", "YYYY");
const validRangeEnd = dayjs.utc("2025", "YYYY");
const dateToday = dayjs.utc();

export const CalendarPage: FC = () => {
  const { setIsUpdatingUserSettings }: any = useClinicsMultiselectContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(
    dayjs.utc().tz()
  );
  const [calendarData, setCalendarData] = useState<any[]>([]);
  const [isLoadingExtended, setIsLoadingExtended] = useState<boolean>(true);
  const { isLoading, isError } = useQuery(
    [selectedDate?.format("M"), selectedDate?.format("YYYY"), "getOverview"],
    apptsAPI.getOverview,
    {
      onSuccess: (res: any) => {
        if (res.jsonString !== undefined) {
          setCalendarData(
            Object.entries(JSON.parse(res.jsonString)).reduce(
              // eslint-disable-next-line no-sequences
              (arr: any, [key, value]) => ((arr[key] = value), arr),
              []
            )
          );
        }
        setIsLoadingExtended(false);
      },
      onError: () => setIsLoadingExtended(false),
      onSettled: () => setIsUpdatingUserSettings(false),
    }
  );
  if (isError)
    return <Title>An error has occurred. Try reloading this page.</Title>;

  const getListData = (value: dayjs.Dayjs) => {
    let listData: any[] = [];
    const key: any = value.format("DD-MM-YYYY");
    if (calendarData[key] === undefined) {
      return listData;
    }
    if (calendarData[key].dnaCount !== undefined) {
      listData.push({
        type: "warning",
        content: calendarData[key].dnaCount + " DNA",
      });
    }
    if (calendarData[key].actedCount !== undefined) {
      listData.push({
        type: "success",
        content: calendarData[key].actedCount + " acted",
      });
    }
    return listData;
  };

  const onDateChange = (date: dayjs.Dayjs, mode: string) => {
    return false;
  };

  const onMonthClick = (date: dayjs.Dayjs) => {
    navigate("/schedules/" + date.format("D-M-YYYY"));
  };

  const renderCell = (value: dayjs.Dayjs) => {
    const listData = getListData(value);
    var isWeekend = value.day() % 6 === 0;
    let cellContainerClass = "ant-picker-cell-inner ant-picker-calendar-date";
    if (isWeekend) {
      cellContainerClass += " ant-picker-cell-inner-weekend";
    }
    return (
      <div className={cellContainerClass}>
        <div className="ant-picker-calendar-date-value">{value.date()}</div>
        <div className="ant-picker-calendar-date-content">
          <ul className="events">
            {listData.map((item: any) => (
              <li key={item.content}>
                <Badge status={item.type} text={item.content} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div style={{ width: 500, display: "flex", flexDirection: "row" }}>
        <Form style={{ padding: "0px 12px 6px" }}>
          <Form.Item style={{ marginBottom: 3 }}>
            <DatePicker
              size="middle"
              style={{ width: 279 }}
              value={selectedDate}
              allowClear={false}
              defaultValue={dayjs.utc()}
              format={"MMMM YYYY"}
              picker="month"
              onChange={(date: any) => {
                queryClient.cancelQueries(
                  [
                    selectedDate?.format("M"),
                    selectedDate?.format("YYYY"),
                    "getOverview",
                  ],
                  {
                    exact: true,
                  }
                );
                setIsLoadingExtended(true);
                setSelectedDate(date);
              }}
              disabledDate={(current) => {
                return current && current.isBefore(dateToday);
              }}
            />
          </Form.Item>
        </Form>
        <Link to={ROUTES.APPTS} style={{ marginLeft: 10 }}>
          <Button>Daily view</Button>
        </Link>
      </div>
    );
  };
  return (
    <>
      <Title level={3}>
        <span style={{ fontWeight: 400 }}>Overview</span>{" "}
        {selectedDate?.format("MMMM")} {selectedDate?.format("YYYY")}
      </Title>
      <Spin
        tip="Loading..."
        spinning={isLoading || isLoadingExtended}
        size={"large"}
      >
        {renderHeader()}
        <Card size="small" bodyStyle={{ padding: 0 }}>
          <Calendar
            mode="month"
            value={selectedDate}
            validRange={[validRangeStart, validRangeEnd]}
            onSelect={(date) => onMonthClick(date)}
            onPanelChange={(date, mode) => onDateChange(date, mode)}
            fullCellRender={renderCell}
            headerRender={() => <></>}
          />
        </Card>
      </Spin>
    </>
  );
};
