import type { FC } from "react";
import { Spin, Typography } from "antd";
import { Button, Form, Input, Card } from "antd";
import logo from "../../common/images/logo-large.png";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import * as authAPI from "../../api/authApi";
import { useParams } from "react-router-dom";
import { notify } from "../../common/helpers/notify";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

export const ConfirmRecoverPassPage: FC = () => {
  let { id: resetPasswordId } = useParams();
  const navigate = useNavigate();
  const {
    mutate: confirmResetPassword,
    isError,
    isLoading,
  }: any = useMutation(authAPI.confirmResetPassword, {
    onSuccess: () => {
      notify.success("Your password was updated. Please log-in.");
      navigate("/login");
    },
    onError: () => notify.error("Something went wrong."),
  });
  const onFinish = ({ newPassword }: any) => {
    confirmResetPassword({ resetPasswordId, newPassword });
  };
  if (isError) {
    <>
      <div className="login-form-top">
        <img src={logo} className="login-logo" alt="logo" />
        <br />
        <Text style={{ color: "rgb(131 192 238)" }} className="login-subline">
          AI Enhanced Decision Making.
        </Text>
      </div>
      <div className="login-form-container">
        <Card title={"Please enter your email."}>
          <Text>
            Something went wrong. It's possible that link has expired, please
            try to request a new one or contact us if issue persists.
          </Text>
        </Card>
      </div>
    </>;
  }
  return (
    <>
      <div className="login-form-top">
        <img src={logo} className="login-logo" alt="logo" />
        <br />
        <Text style={{ color: "rgb(131 192 238)" }} className="login-subline">
          AI Enhanced Decision Making.
        </Text>
      </div>
      <div className="login-form-container">
        <Spin spinning={isLoading}>
          <Card title={"Please enter your new password."}>
            <Form
              size="large"
              name="confirm-reset-password"
              className="login-form"
              onFinish={onFinish}
            >
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                  {
                    min: 6, //TBD (store in shared config?)
                    message: `Password min length is 6 characters`,
                  },
                  {
                    max: 16,
                    message: `Password max length is 16 characters`,
                  },
                ]}
              >
                <Input placeholder="New password" type="password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn-login">
                  Confirm password reset
                </Button>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Link to="/login">Back to Sign-in</Link>
              </Form.Item>
            </Form>
          </Card>
        </Spin>
      </div>
    </>
  );
};
