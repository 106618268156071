import { ClinicDto } from "../common/dtos/ClinicDto";
import { UserDto } from "../common/dtos/UserDto";
import { ClientDto } from "../common/dtos/ClientDto";
import { api } from "./api";
import {
  GetApptsForClinicResponseDto,
  GetClinicsResponseDto,
  GetEmailLogsResponseDto,
  GetUsersResponseDto,
} from "./dtos/adminApiResponses";
import { RoleDto } from "../common/dtos/RoleDto";
import { CreateUserDto, UpdateUserDto } from "./dtos/usersApiRequests";

export const createUser = async (
  createUserDto: CreateUserDto
): Promise<void> => {
  await api.post("/admin/users", createUserDto, {
    withCredentials: true,
  });
};

export const inviteUser = async ({
  userId,
  email,
}: {
  userId: string;
  email: string;
}): Promise<void> => {
  await api.post(
    "/admin/users/invite/" + userId,
    { email },
    {
      withCredentials: true,
    }
  );
};

export const updateUser = async (
  updateUserDto: UpdateUserDto
): Promise<void> => {
  await api.patch("/admin/users/" + updateUserDto.id, updateUserDto, {
    withCredentials: true,
  });
};

export const getUser = async ({ queryKey }: any): Promise<UserDto> => {
  const [userId] = queryKey;
  const response = (await api.get("/admin/users/" + userId, {
    withCredentials: true,
  })) as UserDto;
  return response;
};

export const getClinic = async ({ queryKey }: any): Promise<ClinicDto> => {
  const [clinicId] = queryKey;
  const response = (await api.get("/admin/clinics/" + clinicId, {
    withCredentials: true,
  })) as ClinicDto;
  return response;
};

export const findApptsForClinic = async ({
  queryKey,
}: any): Promise<GetApptsForClinicResponseDto> => {
  const [clinicId, page, pageSize] = queryKey;
  const response = (await api.get("/admin/clinics/appts/" + clinicId, {
    withCredentials: true,
    params: {
      limit: pageSize,
      page,
    },
  })) as GetApptsForClinicResponseDto;
  return response;
};

export const getUsers = async (): Promise<GetUsersResponseDto> => {
  const response = (await api.get("/admin/users/", {
    withCredentials: true,
  })) as GetUsersResponseDto;
  return response;
};

export const findClinics = async ({
  queryKey,
}: any): Promise<GetClinicsResponseDto> => {
  const [current, pageSize, sortBy, order] = queryKey;
  const response = (await api.get(
    `/admin/clinics?page=${current}&limit=${pageSize}&sortBy=${sortBy}&order=${order}`,
    {
      withCredentials: true,
    }
  )) as GetClinicsResponseDto;
  return response;
};

export const getEmailLogs = async ({ queryKey }: any): Promise<any> => {
  const [page, pageSize, sortBy, order] = queryKey;
  const response = (await api.get("/admin/email-logs", {
    withCredentials: true,
    params: {
      page,
      limit: pageSize,
      sortBy,
      order,
    },
  })) as GetEmailLogsResponseDto;
  return response;
};

export const searchUsers = async (
  searchedText: string,
  clientId: string
): Promise<Partial<UserDto>[]> => {
  const response = (await api.get("/admin/users/search/client", {
    withCredentials: true,
    params: {
      searchedText,
      clientId,
    },
  })) as UserDto[];
  return response;
};

export const getClients = async (): Promise<ClientDto[]> => {
  const response = (await api.get("/admin/clients", {
    withCredentials: true,
  })) as ClientDto[];
  return response;
};

export const getRoles = async (): Promise<RoleDto[]> => {
  const response = (await api.get("/admin/roles", {
    withCredentials: true,
  })) as RoleDto[];
  return response;
};

export const getSyncs = async (): Promise<any> => {
  const response = await api.get("/admin/syncs/", {
    withCredentials: true,
  });
  return response;
};

export const getStats = async (): Promise<any> => {
  const response = await api.get("/admin/stats", {
    withCredentials: true,
  });
  return response;
};
