import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getSmsLogs } from "../../api/smsApi";
import { SmsLog } from "./dtos/SmsLogDto";
import { SmsLogsTable } from "./components/SmsLogsTable";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { Card, TablePaginationConfig, Typography } from "antd";
import { BackButton } from "../../common/components/BackButton";

const PAGE_SIZE = 15;

const { Title } = Typography;

export const ListSmsLogsPage: FC = () => {
  const { smsJobId } = useParams() as { smsJobId: string };

  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: PAGE_SIZE,
    sortBy: "created",
    order: "ASC",
    total: 0,
  });
  const handlePaginationChange = (newPagination: TablePaginationConfig) => {
    setPagination({
      ...pagination,
      current: newPagination.current ?? 1,
      pageSize: newPagination.pageSize ?? PAGE_SIZE,
    });
  };

  const { data, isLoading } = useQuery(
    ["get-sms-logs", smsJobId, ...Object.values(pagination)],
    () => getSmsLogs(smsJobId, pagination.pageSize, pagination.current),
    {
      onSuccess: (data: any) => {
        setPagination({
          ...pagination,
          total: data.total,
        });
      },
    }
  );
  const smsLogs: SmsLog[] = data?.results ?? [];
  return (
    <>
      <Title level={3}>Sms Logs</Title>
      <Card title={<BackButton />} size="small">
        <SmsLogsTable
          isLoading={isLoading}
          data={smsLogs}
          onPaginationChange={handlePaginationChange}
          pagination={pagination}
        />
      </Card>
    </>
  );
};
