import { notification } from "antd";

const duration = 3;

class Notify {
  success(message: string) {
    notification["success"]({
      message,
      duration,
    });
  }
  error(message: string) {
    notification["error"]({
      message,
      duration,
    });
  }
  warning(message: string) {
    notification["warning"]({
      message,
      duration,
    });
  }
  info(message: string) {
    notification["info"]({
      message,
      duration,
    });
  }
}

export const notify = new Notify();
