import { FC } from "react";
import { Button, Typography, Popconfirm } from "antd";
const { Text } = Typography;

export const PrivacySettingsTab: FC = () => (
  <>
    <Text>
      This will delete your profile. If you need a new profile, you'll need an
      administrator to give you a new login.
      <br />
      <br />
    </Text>
    <Popconfirm title="Please contact us using email">
      <Button danger={true} disabled={true}>
        Delete my profile
      </Button>
    </Popconfirm>
  </>
);
