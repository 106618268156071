import { ReactNode, FC } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import logo from "../../../common/images/logo.png";
import { useLocation } from "react-router-dom";
import { UserDropdown } from "../UserDropdown";
import { Footer } from "../Footer";
import { ROUTES } from "../../constants/ROUTES";

const { Header, Content } = Layout;

type AdminLayoutProps = {
  children: ReactNode;
};

const menuItems = [
  {
    key: ROUTES.ADMIN_HOME,
    label: <Link to={ROUTES.ADMIN_HOME}>Home</Link>,
  },
  {
    key: ROUTES.ADMIN_CLINICS,
    label: <Link to={ROUTES.ADMIN_CLINICS}>Clinics</Link>,
  },
  {
    key: ROUTES.ADMIN_USERS,
    label: <Link to={ROUTES.ADMIN_USERS}>Users</Link>,
  },
  {
    key: ROUTES.ADMIN_SYNC,
    label: <Link to={ROUTES.ADMIN_SYNC}>Syncs</Link>,
  },
  {
    key: ROUTES.ADMIN_EMAIL_LOGS,
    label: <Link to={ROUTES.ADMIN_EMAIL_LOGS}>Emails</Link>,
  },
  {
    key: ROUTES.ADMIN_COMMS,
    label: <Link to={ROUTES.ADMIN_COMMS}>Connects</Link>,
  },
  {
    key: ROUTES.ADMIN_BB_ROUTINES,
    label: <Link to={ROUTES.ADMIN_BB_ROUTINES}>BB Routines</Link>,
  },
];

export const AdminLayout: FC<AdminLayoutProps> = (props: AdminLayoutProps) => {
  const location = useLocation();
  let selectedKey = location.pathname;
  if (selectedKey.startsWith(ROUTES.ADMIN_BB_ROUTINES)) {
    selectedKey = ROUTES.ADMIN_BB_ROUTINES;
  } else if (selectedKey.startsWith(ROUTES.ADMIN_CLINICS)) {
    selectedKey = ROUTES.ADMIN_CLINICS;
  }
  return (
    <>
      <Header style={{ padding: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            margin: "0 auto",
            maxWidth: "1300px",
            padding: "0 50px",
          }}
        >
          <Link to="/">
            <div className="logo">
              <img src={logo} className="logo-img" alt="logo" />
            </div>
          </Link>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Menu
              theme="dark"
              mode="horizontal"
              style={{ minWidth: 650 }}
              defaultSelectedKeys={["1"]}
              selectedKeys={[selectedKey]}
              items={menuItems}
            />
            <UserDropdown />
          </div>
        </div>
      </Header>
      <Content
        style={{
          display: "block",
          width: "100%",
          margin: "0 auto",
          padding: "20px 50px",
          maxWidth: "1300px",
        }}
      >
        {props.children}
      </Content>
      <Footer />
    </>
  );
};
