import { FC, useState, Fragment } from "react";
import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Switch,
  Upload,
  UploadFile,
} from "antd";
import { SelectClinics } from "../../users/components/SelectClinics";
import { useForm } from "antd/es/form/Form";
import { ApptsFilterFormDto } from "../dtos/CommDto";
import { SelectClient } from "../../users/components/SelectClient";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { converStringToFile, readFileContent } from "../utils/file";
import { CommDto } from "../dtos/CommDto";
interface CommFormProps {
  onSubmit: (value: CommFormValue) => void;
  onFiltersChange?: (filters: ApptsFilterFormDto) => void;
  onClientChange?: (clientId: string) => void;
  initialValues?: CommFormValue;
  submitButtonLabel: string;
}

const MAX_NAME_LENGTH = 50;
const MIN_NAME_LENGTH = 4;

export interface CommFormValue {
  id?: string;
  filters: ApptsFilterFormDto;
  flowUrl: string;
  name: string;
  clientId: string;
  file?: RcFile & { status?: string };
  hasDnaPredictionFilter: boolean;
  hasAgeFilter: boolean;
  hasReasonFilter: boolean;
  filterType: FILTER_TYPES;
  priority: number;
  isEnabled: boolean;
}

const validateDnaFilter = (minDna: number, maxDna: number) => {
  if (typeof minDna !== "number" || typeof maxDna !== "number") {
    return Promise.resolve();
  }

  if (minDna <= maxDna) {
    return Promise.resolve();
  }
  return Promise.reject(
    "Min dna probability should be less or equal than Max dna probability"
  );
};

const validateAge = (minAge: number, maxAge: number) => {
  if (typeof minAge !== "number" || typeof maxAge !== "number") {
    return Promise.resolve();
  }

  if (minAge <= maxAge) {
    return Promise.resolve();
  }
  return Promise.reject("Min age should be less or equal than Max age");
};

export enum FILTER_TYPES {
  APPOINTMENT_IDS = "APPOINTMENT_IDS",
  DYNAMIC = "DYNAMIC",
  EXTERNAL = "EXTERNAL",
}

const FilterTypeLabels = {
  [FILTER_TYPES.APPOINTMENT_IDS]: "Appointment ids",
  [FILTER_TYPES.DYNAMIC]: "Dynamic",
  [FILTER_TYPES.EXTERNAL]: "External",
};

export function getInitialFilterTypeValue(
  initialValues?: CommFormValue | CommDto
): FILTER_TYPES {
  return initialValues?.filters?.type || FILTER_TYPES.DYNAMIC;
}

export const CommForm: FC<CommFormProps> = ({
  initialValues,
  onSubmit,
  submitButtonLabel,
  onFiltersChange,
  onClientChange,
}: CommFormProps) => {
  const [form] = useForm<CommFormValue>();
  const clientId = Form.useWatch("clientId", form);
  const onFinish = (values: CommFormValue) => {
    onSubmit(values);
  };
  const [hasDnaPredictionFilter, setHasDnaPredictionFilter] = useState<boolean>(
    !!initialValues?.filters?.dnaFilter
  );
  const [hasReasonFilter, setHasReasonFilter] = useState<boolean>(
    !!initialValues?.filters?.reasonFilter
  );
  const [hasAgeFilter, setHasAgeFilter] = useState<boolean>(
    !!initialValues?.filters?.ageFilter
  );
  const [filterType, setFilterType] = useState<FILTER_TYPES>(
    getInitialFilterTypeValue(initialValues)
  );

  // Used for consistently display a file icon when the user tries to edit the comm. A dummy files is created and appeneded to the list of
  // available files. Per design, the user is only able to completely remove the file and overwrite it with a new one at the moment.
  const [fileList, setFileList] = useState<UploadFile<any>[]>(
    initialValues?.filters?.apptIds
      ? [
          {
            ...converStringToFile(
              initialValues.filters.apptIds.join(","),
              "appts.csv"
            ),
            uid: "1234",
            name: "appts.csv",
          },
        ]
      : []
  );

  /**
   * When a filter checkbox is toggled or the filter type changes, an update is provided to the parent with the
   * filter value added/removed from filters, for the estimation of messages to be sent to be correct.
   * This method leaves the internal form value intact.
   */
  function updateFiltersValueOnFilterToggle(
    activeFilters: {
      reasonFilter: boolean;
      dnaFilter: boolean;
      ageFilter: boolean;
      clinicsFilter: boolean;
      apptsFilter: boolean;
    },
    filterType: FILTER_TYPES
  ) {
    const filtersValue = {
      ...form.getFieldValue("filters"),
      type: filterType,
    } as ApptsFilterFormDto;

    if (!activeFilters.dnaFilter) delete filtersValue.dnaFilter;
    if (!activeFilters.reasonFilter) delete filtersValue.reasonFilter;
    if (!activeFilters.ageFilter) delete filtersValue.ageFilter;
    if (!activeFilters.clinicsFilter) delete filtersValue.clinics;
    if (!activeFilters.apptsFilter) delete filtersValue.apptIds;
    if (onFiltersChange && filtersValue) {
      onFiltersChange(filtersValue);
    }
  }

  function handleFilterTypeChange(filterType: FILTER_TYPES) {
    setFilterType(filterType);
    updateFiltersValueOnFilterToggle(
      {
        reasonFilter: filterType === FILTER_TYPES.DYNAMIC,
        dnaFilter: filterType === FILTER_TYPES.DYNAMIC,
        ageFilter: filterType === FILTER_TYPES.DYNAMIC,
        clinicsFilter: filterType === FILTER_TYPES.DYNAMIC,
        apptsFilter: filterType === FILTER_TYPES.APPOINTMENT_IDS,
      },
      filterType
    );
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 5 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        ...initialValues,
        filterType: getInitialFilterTypeValue(initialValues),
        // For UI consistency, when a user edits a comm, he will see a small file icon that signals that the file is uploaded.
        // This bit is also neeeded to keep the state consistent across the component.
        // A user is able to edit the .csv file by simply overwritting the existing one
        ...(initialValues?.filters?.apptIds && {
          file: converStringToFile(
            initialValues.filters.apptIds.join(","),
            "appts.csv"
          ),
        }),
      }}
      onValuesChange={async (
        currentValue: Partial<CommFormValue>,
        allValues: CommFormValue
      ) => {
        if (currentValue.clientId && onClientChange) {
          onClientChange(currentValue.clientId);
        }
        if (currentValue.filterType === FILTER_TYPES.DYNAMIC) {
          if (onFiltersChange) {
            onFiltersChange({
              ...allValues.filters,
              apptIds: ["123"],
              type: allValues.filterType,
            });
          }
          return;
        }
        if (currentValue.file && currentValue.file.status !== "removed") {
          const fileContent = await readFileContent(currentValue.file);
          const apptIds = fileContent.split(",");
          form.setFieldValue(["filters", "apptIds"], apptIds);
          if (onFiltersChange) {
            onFiltersChange({
              ...allValues.filters,
              apptIds,
              type: allValues.filterType,
            });
          }
        } else {
          if (onFiltersChange && currentValue.filters) {
            onFiltersChange({
              ...allValues.filters,
              type: allValues.filterType,
            });
          }
        }
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
          },
          {
            min: 4,
            message: `Name min length is ${MIN_NAME_LENGTH} characters`,
          },
          {
            max: 50,
            message: `Name max length is ${MAX_NAME_LENGTH} characters`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Client" name="clientId" rules={[{ required: true }]}>
        <SelectClient placeholder="Select a client" />
      </Form.Item>
      <Form.Item
        name="filterType"
        label="Filter type"
        rules={[{ required: true }]}
      >
        <Select
          onChange={handleFilterTypeChange}
          style={{ width: "200px" }}
          value={filterType}
          options={Object.values(FILTER_TYPES).map((value) => ({
            value,
            label: FilterTypeLabels[value],
          }))}
        ></Select>
      </Form.Item>
      {filterType === FILTER_TYPES.DYNAMIC && (
        <Fragment>
          {(clientId || initialValues?.clientId) && (
            <Form.Item
              label="Clinic(s)"
              name={["filters", "clinics"]}
              rules={[{ required: true }]}
            >
              <SelectClinics
                clientId={(clientId ?? initialValues?.clientId) as string}
                mode="multiple"
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}
        </Fragment>
      )}

      <Form.Item
        label="Flow url"
        name="flowUrl"
        rules={[
          {
            required: true,
          },
          {
            type: "url",
            message: "Must be a valid url",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Days span"
        name={["filters", "offsetDaysFromCurrent"]}
        rules={[{ required: true, message: "Please enter a value" }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item
        label="Priority"
        name={"priority"}
        rules={[{ required: true, message: "Please enter a value" }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      {filterType === FILTER_TYPES.APPOINTMENT_IDS && (
        <Form.Item
          label="CSV file"
          name="file"
          valuePropName="file"
          getValueFromEvent={(event) => {
            return event.file;
          }}
          rules={[{ required: true, message: "Please upload a csv file" }]}
        >
          <Upload
            accept=".csv"
            maxCount={1}
            name="file"
            multiple={false}
            beforeUpload={(file) => {
              return false; // pervents default behaviour of Upload component to try and make an HTTP request with the file
            }}
            fileList={fileList} // neded to preserve state between filter type toggle
            onChange={({ fileList }) => {
              if (fileList.length === 0) {
                form.setFieldValue("file", null);
              }
              setFileList([...fileList]);
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      )}
      {filterType === FILTER_TYPES.DYNAMIC && (
        <Fragment>
          <Form.Item
            name="hasDnaPredictionFilter"
            label="Filter by DNA"
            valuePropName="checked"
          >
            <Switch
              defaultChecked={hasDnaPredictionFilter}
              onChange={(value) => {
                updateFiltersValueOnFilterToggle(
                  {
                    reasonFilter: hasReasonFilter,
                    dnaFilter: value,
                    ageFilter: hasAgeFilter,
                    clinicsFilter: filterType === FILTER_TYPES.DYNAMIC,
                    apptsFilter:
                      (filterType as FILTER_TYPES) ===
                      FILTER_TYPES.APPOINTMENT_IDS,
                  },
                  filterType
                );
                setHasDnaPredictionFilter(value);
              }}
            ></Switch>
          </Form.Item>
          {hasDnaPredictionFilter && (
            <>
              <Form.Item
                label="Min"
                name={["filters", "dnaFilter", "minDna"]}
                rules={[
                  {
                    required: hasDnaPredictionFilter,
                  },
                  {
                    validator: (_, minDna: number) => {
                      const maxDna = form.getFieldValue([
                        "filters",
                        "dnaFilter",
                        "maxDna",
                      ]) as number;
                      return validateDnaFilter(minDna, maxDna);
                    },
                  },
                ]}
              >
                <InputNumber min={0} max={100} />
              </Form.Item>
              <Form.Item
                label="Max"
                name={["filters", "dnaFilter", "maxDna"]}
                rules={[
                  {
                    required: hasDnaPredictionFilter,
                  },
                  {
                    validator: (_, maxDna: number) => {
                      const minDna = form.getFieldValue([
                        "filters",
                        "dnaFilter",
                        "minDna",
                      ]) as number;
                      return validateDnaFilter(minDna, maxDna);
                    },
                  },
                ]}
              >
                <InputNumber min={0} max={100} />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="hasReasonFilter"
            label="Filter by reason"
            valuePropName="checked"
          >
            <Switch
              defaultChecked={hasReasonFilter}
              onChange={(value) => {
                updateFiltersValueOnFilterToggle(
                  {
                    reasonFilter: value,
                    dnaFilter: hasDnaPredictionFilter,
                    ageFilter: hasAgeFilter,
                    clinicsFilter:
                      (filterType as FILTER_TYPES) === FILTER_TYPES.DYNAMIC,
                    apptsFilter:
                      (filterType as FILTER_TYPES) ===
                      FILTER_TYPES.APPOINTMENT_IDS,
                  },
                  filterType
                );
                setHasReasonFilter(value);
              }}
            ></Switch>
          </Form.Item>
          {hasReasonFilter && (
            <>
              <Form.Item
                label="Reason"
                name={["filters", "reasonFilter", "name"]}
                rules={[
                  {
                    required: hasReasonFilter,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Weight"
                name={["filters", "reasonFilter", "minWeight"]}
                rules={[
                  {
                    required: hasReasonFilter,
                  },
                  {
                    validator: (_, value: number) => {
                      if (0 <= value && value <= 100) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Must be an integer between 0 and 100"
                        );
                      }
                    },
                    message: "Must be an integer between 0 and 100",
                  },
                ]}
              >
                <InputNumber precision={0} />
              </Form.Item>
            </>
          )}
          <div>
            <Form.Item
              name="hasAgeFilter"
              label="Filter by age"
              valuePropName="checked"
            >
              <Switch
                defaultChecked={hasAgeFilter}
                onChange={(value) => {
                  updateFiltersValueOnFilterToggle(
                    {
                      reasonFilter: hasReasonFilter,
                      dnaFilter: hasDnaPredictionFilter,
                      ageFilter: value,
                      clinicsFilter:
                        (filterType as FILTER_TYPES) === FILTER_TYPES.DYNAMIC,
                      apptsFilter:
                        (filterType as FILTER_TYPES) ===
                        FILTER_TYPES.APPOINTMENT_IDS,
                    },
                    filterType
                  );
                  setHasAgeFilter(value);
                }}
              ></Switch>
            </Form.Item>
          </div>
          {hasAgeFilter && (
            <>
              <Form.Item
                label="Min"
                name={["filters", "ageFilter", "minAge"]}
                rules={[
                  {
                    required: hasAgeFilter,
                  },
                  {
                    validator: (_, minAge: number) => {
                      const maxAge = form.getFieldValue([
                        "filters",
                        "ageFilter",
                        "maxAge",
                      ]) as number;
                      return validateAge(minAge, maxAge);
                    },
                  },
                ]}
              >
                <InputNumber precision={0} min={0} />
              </Form.Item>
              <Form.Item
                label="Max"
                name={["filters", "ageFilter", "maxAge"]}
                rules={[
                  {
                    required: hasAgeFilter,
                  },
                  {
                    validator: (_, maxAge: number) => {
                      const minAge = form.getFieldValue([
                        "filters",
                        "ageFilter",
                        "minAge",
                      ]) as number;
                      return validateAge(minAge, maxAge);
                    },
                  },
                ]}
              >
                <InputNumber precision={0} min={0} />
              </Form.Item>
            </>
          )}
        </Fragment>
      )}
      <Form.Item
        label="Is enabled"
        name="isEnabled"
        rules={[
          {
            required: true,
          },
        ]}
        valuePropName="checked"
      >
        <Switch></Switch>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          size="middle"
          style={{ minWidth: 100 }}
        >
          {submitButtonLabel}
        </Button>
      </Form.Item>
    </Form>
  );
};
