import { Button, Table, Tooltip, Typography } from "antd";
import { FC } from "react";
import { dayjs } from "../../../common/helpers/dayjs";
import {
  CheckOutlined,
  ClockCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { PatientDto } from "../../../common/dtos/PatientDto";
import { BirthDateWithGender } from "../../../common/components/BirthDateWithGender";
import { ActionDto } from "../../../common/dtos/ActionDto";
import { OutcomeButton } from "../../list/components/OutcomeButton";
import { getOutcomeText } from "../../../common/helpers/actions";
import { PaginationDto } from "../../../common/dtos/PaginationDto";
import { SortOrder } from "antd/lib/table/interface";
import {
  APPT_PRIORITIES,
  APPT_PRIORITIES_LABELS,
  APPT_TYPES_LABELS,
  APPT_TYPES,
} from "./../../../common/constants/APPTS";
import { ApptDto } from "../../../common/dtos/ApptDto";

const { Text } = Typography;

export interface ActionsTableRow {
  datetime: string;
  patient: PatientDto;
  clinic: { name: string; code: string };
  action: ActionDto;
  key: string;
}

export interface ActionsTableFilters {
  priority?: Number[];
  type?: Number[];
}

interface ActionsTableProps {
  data: ActionsTableRow[];
  pagination: PaginationDto;
  isShowCompleted?: boolean | undefined;
  onPaginationChange: (pagination: PaginationDto) => void;
  onFiltersChange: (filters: ActionsTableFilters) => void;
  onCompleteClick: (clinicId: string, appt: ApptDto, action: ActionDto) => void;
}
const SORT_DIRECTIONS: SortOrder[] = ["ascend", "descend", "ascend"];
const DEFAULT_SORT_ORDER: SortOrder = "ascend";
const COLUMN_ALIGN: any = "center";

export const ActionsTable: FC<ActionsTableProps> = ({
  data,
  pagination,
  isShowCompleted,
  onPaginationChange,
  onCompleteClick,
  onFiltersChange: onFilterChange,
}: ActionsTableProps) => {
  const columns = [
    {
      title: "Appointment",
      dataIndex: "datetime",
      key: "datetime",
      align: COLUMN_ALIGN,
      defaultSortOrder: DEFAULT_SORT_ORDER,
      sortDirections: SORT_DIRECTIONS,
      render: (datetime: string) => {
        let badgeColor = "#262626";
        return (
          <>
            <Text style={{ fontWeight: 500 }}>
              <b>{dayjs.utc(datetime).tz().format("DD.MM ")}</b>
              <ClockCircleOutlined
                style={{ color: badgeColor, marginLeft: 3, marginRight: 3 }}
              />{" "}
              {dayjs.utc(datetime).tz().format(" HH:mm")}
            </Text>
          </>
        );
      },
    },
    {
      title: "Patient",
      width: 160,
      dataIndex: "patient",
      key: "patient",
      align: COLUMN_ALIGN,
      render: (patient: PatientDto) => {
        if (patient.patientIdRaw && patient.firstName && patient.lastName) {
          return (
            <Text>
              <span style={{ fontWeight: 500 }}>{patient.patientIdRaw}</span> (
              {patient.lastName.toUpperCase()}
              {", "}
              {patient.firstName})
              {patient.gender !== undefined && (
                <Text style={{ marginLeft: 5 }}>
                  {" "}
                  <BirthDateWithGender
                    birthDate={patient.birthDate}
                    gender={patient.gender}
                  />
                </Text>
              )}
            </Text>
          );
        } else {
          return (
            <BirthDateWithGender
              birthDate={patient.birthDate}
              gender={patient.gender}
            />
          );
        }
      },
    },
    {
      title: "Priority",
      width: 130,
      dataIndex: "priority",
      key: "priority",
      align: COLUMN_ALIGN,
      filters: [
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.TWOWEEKWAIT],
          value: APPT_PRIORITIES.TWOWEEKWAIT,
        },
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.URGENT],
          value: APPT_PRIORITIES.URGENT,
        },
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.ROUTINE],
          value: APPT_PRIORITIES.ROUTINE,
        },
      ],
      render: (priority: number | null) => {
        return <>{priority !== null && APPT_PRIORITIES_LABELS[priority]}</>;
      },
    },
    {
      title: "Type",
      width: 130,
      dataIndex: "type",
      key: "type",
      align: COLUMN_ALIGN,
      render: (type: number | null) => (
        <>{type !== null && APPT_TYPES_LABELS[type]}</>
      ),
      filters: [
        {
          text: APPT_TYPES_LABELS[APPT_TYPES.NEW],
          value: APPT_TYPES.NEW,
        },
        {
          text: APPT_TYPES_LABELS[APPT_TYPES.FOLLOW_UP],
          value: APPT_TYPES.FOLLOW_UP,
        },
      ],
    },
    {
      title: "Clinic",
      dataIndex: "clinic",
      key: "clinic",
      align: COLUMN_ALIGN,
      render: (a: any) => {
        return (
          <Tooltip title={a.name}>
            <Text style={{ fontWeight: 500 }}>{a.code}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Specialty",
      dataIndex: "specialty",
      key: "specialty",
      align: COLUMN_ALIGN,
      render: (a: any) => {
        return <Text>{a.name}</Text>;
      },
    },
    {
      title: "Outcome",
      width: 110,
      dataIndex: "action",
      key: "callOutcome",
      align: COLUMN_ALIGN,
      render: (action: ActionDto) => (
        <Tooltip title={getOutcomeText(action.outcome)}>
          {/* Need empty element to have the tooltip working */}
          <>
            <OutcomeButton
              disabled={false}
              outcomeSentiment={action.outcomeSentiment}
              actionType={action.type}
              actionOutcome={action.outcome}
            />
          </>
        </Tooltip>
      ),
    },
    {
      title: isShowCompleted ? "Edit" : "Complete",
      width: 110,
      key: "completed",
      align: COLUMN_ALIGN,
      render: (row: any) => (
        <Button
          onClick={() => onCompleteClick(row.clinic.id, row.appt, row.action)}
          shape="circle"
          icon={isShowCompleted ? <EditOutlined /> : <CheckOutlined />}
        />
      ),
    },
  ];

  return (
    <Table
      size="middle"
      dataSource={data}
      columns={columns}
      pagination={{
        showSizeChanger: true,
        hideOnSinglePage: true,
        total: pagination.total,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }}
      onChange={(pagination: any, filters: any) => {
        onFilterChange(filters);
        onPaginationChange({
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        });
      }}
    />
  );
};
