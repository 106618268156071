import type { FC } from "react";
import {
  Typography,
  Table,
  Card,
  Badge,
  Tooltip,
  Button,
  Form,
  Switch,
  Spin,
} from "antd";
import { dayjs } from "../../common/helpers/dayjs";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import * as apptsAPI from "../../api/apptsApi";
import {
  ClockCircleOutlined,
  WomanOutlined,
  ManOutlined,
  QuestionOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { GENDERS } from "../../common/constants/GENDERS";
import {
  APPT_PRIORITIES,
  APPT_PRIORITIES_LABELS,
  APPT_TYPES,
  APPT_TYPES_LABELS,
} from "../../common/constants/APPTS";
import { UpdateCancelledApptDto } from "./dtos/UpdateCancelledApptDto";
import { notify } from "../../common/helpers/notify";
import { useClinicsMultiselectContext } from "../../common/components/HospitalLayout/HospitalLayout";

const { Title, Text } = Typography;
declare type SortOrder = "descend" | "ascend" | null;
const SORT_DIRECTIONS: SortOrder[] = ["ascend", "descend", "ascend"];
const DEFAULT_SORT_ORDER: SortOrder = "ascend";
const COLUMN_ALIGN: any = "center";

export const ListCancelledApptsPage: FC = () => {
  const { setIsUpdatingUserSettings }: any = useClinicsMultiselectContext();
  const queryClient = useQueryClient();
  const [appts, setAppts] = useState<any[]>();
  const [isOnlyFilled, setIsOnlyFilled] = useState<boolean>(false);
  const [isLoadingExtended, setIsLoadingExtended] = useState<boolean>(true);
  const {
    isLoading: isLoadingGetCancelledAppts,
    isError,
    refetch,
  } = useQuery(
    ["findCancelledAppts", isOnlyFilled],
    () => apptsAPI.findCancelledAppts(isOnlyFilled),
    {
      onSuccess: (res: any) => {
        let apptsRaw = res;
        if (apptsRaw !== undefined && Object.keys(apptsRaw).length > 0) {
          setAppts(
            apptsRaw.map((appt: any) => {
              return {
                key: appt.id,
                datetime: appt.datetime,
                clinic: {
                  id: appt.clinic.id,
                  name: appt.clinic.name,
                  code: appt.clinic.code,
                  hospitalName: appt.clinic.hospital.name,
                },
                type: appt.type,
                priority: appt.priority,
                appointment: {
                  id: appt.id,
                  datetime: appt.datetime,
                  cancellationDate: appt.cancellationDate,
                  isShortNotice: appt.isShortNotice,
                  slotDuration: appt.slotDuration,
                  cancellationFilled: appt.cancellationFilled,
                },
                patient: {
                  birthDate: appt.birthDate,
                  gender: appt.gender,
                },
              };
            })
          );
        } else {
          setAppts([]);
        }
        setIsLoadingExtended(false);
      },
      onError: () => setIsLoadingExtended(false),
      onSettled: () => setIsUpdatingUserSettings(false),
    }
  );
  const {
    mutate: updateCancelledAppt,
    isLoading: isUpdateCancelledApptLoading,
  } = useMutation(
    (dto: UpdateCancelledApptDto) => apptsAPI.updateCancelledAppt(dto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["findCancelledAppts", isOnlyFilled]);
        refetch();
        notify.success("Slot updated.");
      },
      onError: () => {
        setIsLoadingExtended(false);
        notify.error("Unable to update the slot.");
      },
    }
  );

  const columns = [
    { width: 48 },
    {
      title: "Appointment",
      dataIndex: "appointment",
      sorter: (a: any, b: any) => {
        return (
          new Date(a.appointment.datetime).getTime() -
          new Date(b.appointment.datetime).getTime()
        );
      },
      defaultSortOrder: DEFAULT_SORT_ORDER,
      onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
      sortDirections: SORT_DIRECTIONS,
      render: (a: any) => {
        let badgeColor = "#262626";
        return (
          <>
            <Text style={{ fontWeight: 500 }}>
              <b>{dayjs.utc(a.datetime).tz().format("DD.MM ")}</b>
              <ClockCircleOutlined
                style={{
                  color: badgeColor,
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
              />{" "}
              {dayjs.utc(a.datetime).tz().format("HH:mm")}
            </Text>
          </>
        );
      },
      align: COLUMN_ALIGN,
    },
    {
      title: "Slot duration",
      dataIndex: "appointment",
      defaultSortOrder: DEFAULT_SORT_ORDER,
      onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
      sortDirections: SORT_DIRECTIONS,
      render: (a: any) => {
        let badgeColor = "#262626";
        return (
          <>
            <ClockCircleOutlined style={{ color: badgeColor }} />{" "}
            <Text style={{ fontWeight: 500 }}>{a.slotDuration}</Text>
          </>
        );
      },
      align: COLUMN_ALIGN,
    },
    {
      title: "Patient",
      width: 160,
      dataIndex: "patient",
      align: COLUMN_ALIGN,
      render: (a: any) => {
        let genderIcon = <QuestionOutlined style={{ color: "#ccc" }} />;
        if (a.gender === GENDERS.MALE) {
          genderIcon = <ManOutlined style={{ color: "#468ea7" }} />;
        } else if (a.gender === GENDERS.FEMALE) {
          genderIcon = <WomanOutlined style={{ color: "#f36c84" }} />;
        }
        return (
          <>
            {a.birthDate && <Text> {a.birthDate}</Text>}
            {a.gender !== undefined && (
              <Text style={{ marginLeft: 5 }}>{genderIcon}</Text>
            )}
          </>
        );
      },
    },
    {
      title: "Priority",
      width: 130,
      dataIndex: "priority",
      align: COLUMN_ALIGN,
      filters: [
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.TWOWEEKWAIT],
          value: APPT_PRIORITIES.TWOWEEKWAIT,
        },
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.URGENT],
          value: APPT_PRIORITIES.URGENT,
        },
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.ROUTINE],
          value: APPT_PRIORITIES.ROUTINE,
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.priority === value;
      },
      render: (priority: any) => {
        return (
          <>
            {typeof priority !== "undefined" &&
              priority !== null &&
              APPT_PRIORITIES_LABELS[priority]}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      filters: [
        {
          text: APPT_TYPES_LABELS[APPT_TYPES.NEW],
          value: APPT_TYPES.NEW,
        },
        {
          text: APPT_TYPES_LABELS[APPT_TYPES.FOLLOW_UP],
          value: APPT_TYPES.FOLLOW_UP,
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.type === value;
      },
      width: 130,
      align: COLUMN_ALIGN,
      render: (type: number | null) => (
        <>{type !== null && APPT_TYPES_LABELS[type]}</>
      ),
    },
    {
      title: "Clinic",
      dataIndex: "clinic",
      align: COLUMN_ALIGN,
      render: (a: any) => {
        return (
          <Tooltip title={a.hospitalName + " - " + a.name}>
            <Text style={{ fontWeight: 500 }}>{a.code}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Cancelled",
      dataIndex: "appointment",
      defaultSortOrder: DEFAULT_SORT_ORDER,
      onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
      sortDirections: SORT_DIRECTIONS,
      render: (a: any) => {
        if (!a.cancellationDate) return <></>;
        return (
          <>
            <Text style={{ fontWeight: 500 }}>
              {dayjs.utc(a.cancellationDate).fromNow()}
            </Text>
          </>
        );
      },
      align: COLUMN_ALIGN,
    },
    {
      title: isOnlyFilled ? "Un-fill" : "Fill",
      width: 120,
      key: "fill",
      align: COLUMN_ALIGN,
      render: (data: any) => {
        return (
          <Button
            onClick={async () => {
              queryClient.cancelQueries(["findCancelledAppts"], {
                exact: false,
              });
              setIsLoadingExtended(true);
              updateCancelledAppt({
                clinicId: data.clinic.id,
                apptId: data.appointment.id,
                isFilled: !data.appointment.cancellationFilled,
              });
            }}
            disabled={isUpdateCancelledApptLoading}
            shape="circle"
            icon={isOnlyFilled ? <CloseOutlined /> : <CheckOutlined />}
          />
        );
      },
    },
    {
      title: "",
      width: 60,
      dataIndex: "appointment",
      render: (a: any) => {
        if (a.isShortNotice === true) {
          return (
            <Badge.Ribbon
              text="Short notice"
              color="#FD590F"
              style={{ marginTop: "-20px", marginRight: "-8px" }}
            />
          );
        }
        return <></>;
      },
      align: COLUMN_ALIGN,
    },
  ];
  if (isError) {
    return (
      <Text>
        Something went wrong, try refreshing the page. If issue persists, please
        contact technical support.
      </Text>
    );
  }
  return (
    <>
      <Title level={3}>
        <span style={{ fontWeight: 400 }}>Slots available for booking</span>{" "}
        <span style={{ fontSize: "13px", color: "rgba(0,0,0,.45)" }}>
          based on cancellations during the next <b>2 weeks</b>
        </span>
      </Title>
      <Spin
        tip="Loading..."
        spinning={
          isLoadingGetCancelledAppts ||
          isUpdateCancelledApptLoading ||
          isLoadingExtended
        }
        size={"large"}
      >
        <Form style={{ padding: "0px 12px 6px" }}>
          <Form.Item
            label="Show filled"
            style={{ marginBottom: 0, marginRight: 0 }}
          >
            <Switch
              onChange={(checked: boolean) => {
                queryClient.cancelQueries(["findCancelledAppts"], {
                  exact: false,
                });
                setIsLoadingExtended(true);
                setAppts([]);
                setIsOnlyFilled(checked);
              }}
            />
          </Form.Item>
        </Form>
        <Card size="small" bodyStyle={{ padding: 0 }}>
          <Table
            size="middle"
            id={"tbl-dashboard-appts"}
            showSorterTooltip={false}
            columns={columns}
            dataSource={appts}
            pagination={{ defaultPageSize: 999999, hideOnSinglePage: true }}
          />
        </Card>
      </Spin>
    </>
  );
};
