import { Button, Form, Input, Spin } from "antd";
import { useMutation } from "react-query";
import * as usersAPI from "../../../api/usersApi";
import { notify } from "../../../common/helpers/notify";

export const PasswordSettingsTab = () => {
  const [form] = Form.useForm();
  const { mutate: changePassword, isLoading: isLoadingChangePassword }: any =
    useMutation(usersAPI.changePassword, {
      onSuccess: () => {
        notify.success("Password updated.");
        form.resetFields();
      },
      onError: (error) => notify.error("Unable to update password."),
    });
  const onFinish = async (values: any) => {
    const { password, repeatPassword } = values;
    if (password !== repeatPassword) {
      notify.error("Passwords do not match.");
      return false;
    }
    changePassword({ password });
  };

  return (
    <Spin spinning={isLoadingChangePassword}>
      <Form
        form={form}
        labelCol={{ span: 7 }}
        autoComplete="off"
        onFinish={onFinish}
        name="settings_user"
        style={{ maxWidth: "500px" }}
        initialValues={{
          password: "",
          repeatPassword: "",
        }}
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please input your Password!" },
            {
              min: 6, //TBD (store in shared config?)
              message: `Password min length is 8 characters`,
            },
            {
              max: 16,
              message: `Password max length is 32 characters`,
            },
          ]}
        >
          <Input name="password" type="password" />
        </Form.Item>
        <Form.Item
          label="Confirm password"
          name="repeatPassword"
          rules={[{ required: true, message: "Please repeat your password!" }]}
        >
          <Input name="repeatPassword" type="password" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
