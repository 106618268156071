export const APPT_PRIORITIES = {
  ROUTINE: 1,
  URGENT: 2,
  TWOWEEKWAIT: 3,
};

export const APPT_TYPES = {
  NEW: 1,
  FOLLOW_UP: 2,
};

export const APPT_PRIORITIES_LABELS = {
  [APPT_PRIORITIES.ROUTINE]: "Routine",
  [APPT_PRIORITIES.URGENT]: "Urgent",
  [APPT_PRIORITIES.TWOWEEKWAIT]: "2WW",
};

export const APPT_TYPES_LABELS = {
  [APPT_TYPES.NEW]: "New",
  [APPT_TYPES.FOLLOW_UP]: "Follow-up",
};
