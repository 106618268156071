import { FormInstance } from "antd";
import { UserWithClinicsDto } from "../../../common/dtos/UserWithClinicsDto";

/**
 * Updates access select input in user create/edit form,
 * by adding clinics from selected user.
 */
const updateSelectedClinics = (
  selectedUser: UserWithClinicsDto,
  form: FormInstance<any>
) => {
  if (!selectedUser) {
    return;
  }
  const selectedClinics: {
    label: string;
    value: string;
  }[] = form.getFieldValue("clinics") ?? [];
  selectedUser.clinics.forEach((userClinic) => {
    if (
      !selectedClinics.some(
        (selectedClinic) => selectedClinic.value === userClinic.id
      )
    ) {
      selectedClinics.push({
        label: `${userClinic.name} (${userClinic.code})`,
        value: userClinic.id,
      });
    }
  });
  if (selectedUser.clinics) {
    form.setFieldValue("clinicsIds", [...selectedClinics]);
  }
};

export { updateSelectedClinics };
