import { useState, useRef, useMemo, useEffect } from "react";
import debounce from "lodash.debounce";
import type { SelectProps } from "antd/es/select";
import * as clinicsAPI from "../../../api/adminApiClinics";
import { Select, Spin } from "antd";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  debounceTimeout?: number;
  clientId: string;
}

export function SelectClinics<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  debounceTimeout = 800,
  clientId,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (value === "") return;
      setOptions([]);
      setFetching(true);
      clinicsAPI.searchClinics(value, clientId).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          return; // for fetch callback order
        }
        const opts = newOptions.map(
          (clinic: { name: string; code: string; id: string }) => ({
            label: `${clinic.name} (${clinic.code})`,
            value: clinic.id,
          })
        );
        setOptions(opts);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [clientId, debounceTimeout]);

  useEffect(() => {
    setOptions([]);
  }, [clientId]);

  return (
    <Select
      optionLabelProp="label"
      labelInValue={true}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : "no results"}
      {...props}
      options={options}
    />
  );
}
