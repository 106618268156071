import { FC, useRef, useState } from "react";
import { Form, Input, Button, InputNumber } from "antd";
import { ClinicValueDto } from "../../clinics/dtos/ClinicValueDto";
import { SelectClinics } from "../../users/components/SelectClinics";
import { SelectClient } from "../../users/components/SelectClient";

interface SmsJobFormProps {
  onSubmit: (createSmsJobDto: SmsJobFormValue) => void;
  onChangeSelectedClinics?: (clinics: ClinicValueDto[]) => void;
  initialValues?: SmsJobFormValue;
  submitButtonLabel: string;
}

export interface SmsJobFormValue {
  name: string;
  sendBefore: number;
  clinics: ClinicValueDto[];
}

export const SmsJobForm: FC<SmsJobFormProps> = ({
  initialValues,
  onSubmit,
  onChangeSelectedClinics,
  submitButtonLabel,
}: SmsJobFormProps) => {
  const [clientId, setClientId] = useState<string | null>(null);
  const form: any = useRef(this);
  const onFinish = (values: SmsJobFormValue) => {
    onSubmit(values);
  };
  return (
    <Form
      ref={form}
      labelCol={{ span: 5 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={initialValues}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Client"
        name="client"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <SelectClient
          onChange={(clientId) => setClientId(clientId)}
          // setClientId={setClientId}
          placeholder="Select client"
          style={{ width: "100%" }}
        />
      </Form.Item>
      {clientId && (
        <Form.Item
          label="Clinic(s)"
          name="clinics"
          rules={[{ required: true }]}
        >
          <SelectClinics
            clientId={clientId}
            mode="multiple"
            style={{ width: "100%" }}
            onChange={(clinics: any) => {
              if (typeof onChangeSelectedClinics !== "undefined") {
                onChangeSelectedClinics(clinics);
              }
            }}
          />
        </Form.Item>
      )}
      <Form.Item
        label="Days before"
        name="sendBefore"
        rules={[{ required: true, message: "Please enter a value" }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ minWidth: 100 }}
        >
          {submitButtonLabel}
        </Button>
      </Form.Item>
    </Form>
  );
};
