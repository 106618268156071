import type { FC } from "react";
import { useState } from "react";
import {
  Typography,
  Table,
  DatePicker,
  Card,
  Space,
  Badge,
  Tooltip,
  Form,
  Timeline,
  Tabs,
  Popconfirm,
  Spin,
  Button,
} from "antd";
import { Pie } from "@ant-design/plots";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as apptsAPI from "../../api/apptsApi";
import { useParams, Link } from "react-router-dom";
import { ActionCalled } from "./components/ActionCalled";
import { ActionDoublebooked } from "./components/ActionDoublebooked";
import {
  ACTION_TYPES,
  CALL_OUTCOMES,
  DOUBLEBOOK_OUTCOMES,
  isConclusiveAction,
} from "../../common/helpers/actions";
import { BirthDateWithGender } from "../../common/components/BirthDateWithGender";
import { getOutcomeText } from "../../common/helpers/actions";
import { OutcomeButton } from "./components/OutcomeButton";
import { ApptDto } from "../../common/dtos/ApptDto";
import {
  APPT_PRIORITIES,
  APPT_PRIORITIES_LABELS,
  APPT_TYPES_LABELS,
  APPT_TYPES,
} from "./../../common/constants/APPTS";
import { notify } from "../../common/helpers/notify";
import { ActionCalledNoAnswer } from "./components/ActionCalledNoAnswer";
import { EditActionModal } from "./components/EditActionModal";
import { useClinicsMultiselectContext } from "../../common/components/HospitalLayout/HospitalLayout";
import { dayjs } from "../../common/helpers/dayjs";
import { MobileOutlined, PhoneOutlined } from "@ant-design/icons";
import { ROUTES } from "../../common/constants/ROUTES";
import { ActionDto } from "../../common/dtos/ActionDto";
import { isDna } from "../../common/helpers/appts";

const { Title, Text } = Typography;
declare type SortOrder = "descend" | "ascend" | null;
const SORT_DIRECTIONS: SortOrder[] = ["ascend", "descend", "ascend"];
const DEFAULT_SORT_ORDER: SortOrder = "descend";
const COLUMN_ALIGN: any = "center";
const dateToday = dayjs();

type EditActionModalDto = {
  clinicId: string;
  appt: ApptDto;
  action: any;
};

const hasBBOutcome = (appt: ApptDto): boolean => {
  for (const a of appt.actions) {
    if (a.outcome === DOUBLEBOOK_OUTCOMES.DOUBLEBOOKED) return true;
  }
  return false;
};

const isBBAllowed = ({
  clinicId,
  caps,
}: {
  clinicId: string;
  caps: Record<string, { cap: number | null; capUsed: number }>;
}): boolean => {
  const numCap: number | any =
    caps[clinicId].cap === null ? 0 : caps[clinicId].cap; // TS doesn't like possible null comparisons
  if (caps[clinicId] === undefined) {
    return false; // shouldn't happen
  } else if (caps[clinicId].cap === null) {
    return true;
  } else if (caps[clinicId].cap === 0) {
    return false;
  } else if (numCap > caps[clinicId].capUsed) {
    return true;
  } else {
    return false; // shouldn't happen
  }
};

const hasConclusiveOutcome = (actions: ActionDto[]): boolean => {
  for (let i = 0; i < actions.length; i++) {
    if (
      isConclusiveAction({
        type: actions[i].type,
        outcome: actions[i].outcome,
      })
    ) {
      return true;
    }
  }
  return false;
};

export const ListApptsPage: FC = () => {
  const { setIsUpdatingUserSettings }: any = useClinicsMultiselectContext();
  let { date: userSelectedDate } = useParams();
  const queryClient = useQueryClient();
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(
    userSelectedDate ? dayjs(userSelectedDate, "D-M-YYYY") : dayjs()
  );
  // Track the expanded rows for each selected date to preserve consistent UI and maintain
  // the appointments that were expanded by the user upon switching back and forth between dates
  const [expandedApptsByDate, setExpandedApptsByDate] = useState<
    Record<string, string[]>
  >({});
  const [extraStats, setExtraStats] = useState<{
    totalAttendance: number;
    totalRecommendations: number;
  }>({
    totalAttendance: 0,
    totalRecommendations: 0,
  });
  const [isLoadingExtended, setIsLoadingExtended] = useState<boolean>(true);
  const [editActionModal, setEditActionModal] =
    useState<null | EditActionModalDto>(null);
  const { mutate: cancelAction, isLoading: isLoadingCancelAction }: any =
    useMutation(apptsAPI.cancelAction, {
      onSuccess: async () => {
        notify.success("Action removed.");
        await refetchAppts();
      },
      onError: () => notify.error("Unable to remove the action."),
    });
  const {
    isLoading,
    isError,
    refetch: refetchAppts,
  } = useQuery(
    ["findBookedDnaAppts", selectedDate],
    async () => apptsAPI.findBookedDnaAppts(selectedDate),
    {
      onSuccess: (apptsRaw: ApptDto[]) => {
        if (apptsRaw !== undefined && Object.keys(apptsRaw).length > 0) {
          // expanded appts should be set only on first load,
          // because on refresh this will collapse manually expanded rows
          if (
            !expandedApptsByDate[selectedDate.format("D-M-YYYY")] ||
            expandedApptsByDate[selectedDate.format("D-M-YYYY")].length === 0
          )
            setExpandedApptsByDate((prevState) => {
              return {
                ...prevState,
                [selectedDate.format("D-M-YYYY")]: apptsRaw
                  .filter((appt) => {
                    return isDna(appt) && !hasConclusiveOutcome(appt.actions);
                  })
                  .map((appt) => appt.id),
              };
            });
          // doublecheck the array is properly sorted to avoid jumping rows
          // Otherwise order by datetime
          apptsRaw.sort((a: ApptDto, b: ApptDto) => {
            if (a.datetime === b.datetime) {
              const aBirthDate: number = a.patient.birthDate
                ? Number.parseInt(
                    dayjs.utc(a.patient.birthDate, "YYYY-MM").format("x")
                  )
                : 0;
              const bBirthDate: number = b.patient.birthDate
                ? Number.parseInt(
                    dayjs.utc(b.patient.birthDate, "YYYY-MM").format("x")
                  )
                : 0;
              return aBirthDate - bBirthDate; // If datetime is same, order by birthdate
            }
            return a.probabilityDNA - b.probabilityDNA;
          });
          setAppts(
            apptsRaw.map((appt: any) => {
              return {
                key: appt.id,
                ...appt,
              };
            })
          );
          calcExtraStats(apptsRaw);
          calcClinicsCaps(apptsRaw);
        } else {
          setAppts([]);
        }
        setIsLoadingExtended(false);
      },
      onError: () => setIsLoadingExtended(false),
      onSettled: () => setIsUpdatingUserSettings(false),
    }
  );
  const [appts, setAppts] = useState<any[]>([]);
  const [clinicsCaps, setClinicsCaps] = useState<
    Record<string, { cap: number | null; capUsed: number }>
  >({});

  const calcExtraStats = (appointments: ApptDto[]) => {
    let apptsOK: number = 0;
    let apptsDNA: number = 0;
    appointments.map((appt) => {
      return !isDna(appt) ? ++apptsOK : ++apptsDNA;
    });
    setExtraStats({
      totalAttendance: apptsOK,
      totalRecommendations: apptsDNA,
    });
  };

  const calcClinicsCaps = (appointments: ApptDto[] | any) => {
    const caps: Record<string, { cap: number | null; capUsed: number }> = {};
    appointments.forEach((a: ApptDto) => {
      if (caps[a.clinic.id] === undefined)
        caps[a.clinic.id] = { cap: a.clinic.cap, capUsed: 0 };
      if (hasBBOutcome(a))
        caps[a.clinic.id] = {
          ...caps[a.clinic.id],
          capUsed: ++caps[a.clinic.id].capUsed,
        };
    });
    setClinicsCaps(caps);
  };

  const renderPieChart = (
    data: {
      reason: string;
      weight: number;
    }[]
  ): any => {
    const chartData = data.map((reason, idx) => {
      const reasonNum = idx + 1;
      return {
        idx: reasonNum,
        type: reason.reason + " (" + reasonNum + ")",
        value: reason.weight,
      };
    });
    const legend: any = false;
    const statistic: any = "";
    const tooltip: any = false;
    const config = {
      autoFit: true,
      height: 200,
      padding: [-40, 0, -40, 0],
      appendPadding: 0,
      data: chartData,
      angleField: "value",
      colorField: "type",
      color: ["#a23605", "#f36523", "#f8931d", "#ffc20d", "#fef200"],
      radius: 0.5,
      innerRadius: 0.6,
      legend,
      statistic,
      tooltip,
      pieStyle: {
        shadowColor: "red",
      },
      label: {
        type: "spider",
        labelHeight: 28,
        content: "{name}",
      },
      interactions: [
        {
          type: "pie-legend-active",
        },
        {
          type: "element-active",
        },
      ],
    };
    return <Pie {...config} />;
  };

  const renderActionsTimeline = (
    appt: ApptDto,
    clinicId: string,
    actions: {
      id: string;
      outcomeSentiment: number;
      type: number;
      outcome: number;
    }[]
  ) => {
    return (
      <Space>
        <Timeline
          className={"timeline-actions-history"}
          style={{ marginLeft: 0, listStyleType: "none" }}
        >
          {actions.map((action: any, key: any) => (
            <div key={key}>
              {renderActionsTimelineItem({
                appt,
                clinicId,
                action,
              })}
            </div>
          ))}
        </Timeline>
      </Space>
    );
  };

  const renderActionsTimelineItem = ({
    appt,
    clinicId,
    action,
  }: {
    appt: ApptDto;
    clinicId: string;
    action: any;
  }): React.ReactElement => {
    let actionText = getOutcomeText(action.outcome);
    const actionTakenBy =
      action.type !== ACTION_TYPES.COMM
        ? `${action.takenBy.firstName} ${action.takenBy.lastName}`
        : "DM Connects";
    const timelineItemBody = (
      <Timeline.Item
        color="green"
        style={{
          paddingBottom: 10,
          cursor: "pointer",
          listStyleType: "none",
          marginLeft: 10,
        }}
        dot={
          <OutcomeButton
            disabled={isLoadingCancelAction}
            outcomeSentiment={action.outcomeSentiment}
            actionType={action.type}
            actionOutcome={action.outcome}
          />
        }
      >
        <p style={{ marginBottom: 0 }}>
          {actionText}
          <br />
          {action.callNote !== null && action.callNote !== "" && (
            <>
              <i>"{action.callNote}"</i>
              <br />
            </>
          )}

          <span
            style={{
              color: "rgb(152 152 152)",
              fontSize: "13px",
            }}
          >
            {actionTakenBy} {dayjs.utc(action.created).fromNow()}
          </span>
        </p>
      </Timeline.Item>
    );

    // Users are not allowed to delete or edit actions of type comm
    if (action.type === ACTION_TYPES.COMM) {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
        >
          {timelineItemBody}
        </div>
      );
    }

    if (
      action.outcome === CALL_OUTCOMES.NO_ANSWER ||
      action.outcome === DOUBLEBOOK_OUTCOMES.DOUBLEBOOKED
    ) {
      return (
        <div>
          <Popconfirm
            title="Delete action?"
            style={{ cursor: "pointer" }}
            onConfirm={() =>
              cancelAction({
                clinicId,
                apptId: appt.id,
                actionId: action.id,
              })
            }
          >
            {timelineItemBody}
          </Popconfirm>
        </div>
      );
    } else {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => setEditActionModal({ clinicId, appt, action })}
        >
          {timelineItemBody}
        </div>
      );
    }
  };

  const columns = [
    {
      title: "Appointment",
      // defaultSortOrder: DEFAULT_SORT_ORDER,
      // sortDirections: SORT_DIRECTIONS,
      dataIndex: "datetime",
      align: COLUMN_ALIGN,
      sorter: (a: any, b: any) =>
        Number.parseInt(dayjs.utc(a.datetime ? a.datetime : 0).format("x")) -
        Number.parseInt(dayjs.utc(b.datetime ? b.datetime : 0).format("x")),
      width: 200,
      render: (a: any) => {
        return (
          <div style={{ lineHeight: "20px" }}>
            <Text style={{ fontWeight: 500, marginLeft: 5 }}>
              {dayjs.utc(a).tz().format("HH:mm")}
            </Text>
          </div>
        );
      },
    },
    {
      title: "Patient",
      // dataIndex: "patient",
      align: COLUMN_ALIGN,
      // render: (patient: PatientDto) => {
      render: (appt: ApptDto) => {
        const patient = appt.patient;
        let hasConclusiveOutcome = false;
        for (let i = 0; i < appt.actions.length; i++) {
          if (
            isConclusiveAction({
              type: appt.actions[i].type,
              outcome: appt.actions[i].outcome,
            }) === true
          ) {
            hasConclusiveOutcome = true;
            break;
          }
        }
        if (
          !hasConclusiveOutcome &&
          patient.patientIdRaw &&
          patient.firstName &&
          patient.lastName
        ) {
          return (
            <Text>
              <span style={{ fontWeight: 500 }}>{patient.patientIdRaw}</span> (
              {patient.lastName.toUpperCase()}
              {", "}
              {patient.firstName})
              {patient.gender !== undefined && (
                <Text style={{ marginLeft: 5 }}>
                  {" "}
                  <BirthDateWithGender
                    birthDate={patient.birthDate}
                    gender={patient.gender}
                  />
                </Text>
              )}
            </Text>
          );
        } else {
          return (
            <BirthDateWithGender
              birthDate={patient.birthDate}
              gender={patient.gender}
            />
          );
        }
      },
    },
    {
      title: "Priority",
      key: "priority",
      filters: [
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.TWOWEEKWAIT],
          value: APPT_PRIORITIES.TWOWEEKWAIT,
        },
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.URGENT],
          value: APPT_PRIORITIES.URGENT,
        },
        {
          text: APPT_PRIORITIES_LABELS[APPT_PRIORITIES.ROUTINE],
          value: APPT_PRIORITIES.ROUTINE,
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.priority === value;
      },
      align: COLUMN_ALIGN,
      render: (appt: any) => {
        let hasConclusiveOutcome = false;
        for (let i = 0; i < appt.actions.length; i++) {
          if (
            isConclusiveAction({
              type: appt.actions[i].type,
              outcome: appt.actions[i].outcome,
            }) === true
          ) {
            hasConclusiveOutcome = true;
            break;
          }
        }
        if (!isDna(appt) || (isDna(appt) && hasConclusiveOutcome))
          return APPT_PRIORITIES_LABELS[appt.priority];
        if (appt.priority === APPT_PRIORITIES.ROUTINE)
          return <Badge count={"Routine"} showZero color="#52c41a" />;
        if (appt.priority === APPT_PRIORITIES.URGENT)
          return <Badge count={"Urgent"} showZero color="#faad14" />;
        if (appt.priority === APPT_PRIORITIES.TWOWEEKWAIT)
          return <Badge count={"2WW"} showZero />;
        return <></>;
      },
    },
    {
      title: "Type",
      filters: [
        {
          text: APPT_TYPES_LABELS[APPT_TYPES.NEW],
          value: APPT_TYPES.NEW,
        },
        {
          text: APPT_TYPES_LABELS[APPT_TYPES.FOLLOW_UP],
          value: APPT_TYPES.FOLLOW_UP,
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.type === value;
      },
      dataIndex: "type",
      align: COLUMN_ALIGN,
      render: (type: any) => <>{type !== null && APPT_TYPES_LABELS[type]}</>,
    },
    {
      title: "Clinic",
      width: 150,
      align: COLUMN_ALIGN,
      render: (appt: any) => {
        return (
          <Tooltip
            title={"(" + appt.clinic.hospital.name + ") " + appt.clinic.name}
          >
            <Text style={{ fontWeight: 500 }}>{appt.clinic.code}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Risk",
      width: 125,
      dataIndex: "probabilityDna",
      align: COLUMN_ALIGN,
      defaultSortOrder: DEFAULT_SORT_ORDER,
      sortDirections: SORT_DIRECTIONS,
      sorter: (a: ApptDto, b: ApptDto) => a.probabilityDNA - b.probabilityDNA,
      render: (probabilityDna: number, appt: ApptDto) => {
        // return <></>;
        if (!isDna(appt)) return <></>;
        let hasConclusiveOutcome = false;
        let conclusiveOutomeText = null;
        for (let i = 0; i < appt.actions.length; i++) {
          if (
            isConclusiveAction({
              type: appt.actions[i].type,
              outcome: appt.actions[i].outcome,
            }) === true
          ) {
            hasConclusiveOutcome = true;
            conclusiveOutomeText = getOutcomeText(appt.actions[i].outcome);
            break;
          }
        }
        if (hasConclusiveOutcome)
          return (
            <Tooltip
              title={"(" + appt.clinic.hospital.name + ") " + appt.clinic.name}
            >
              <Badge.Ribbon
                text={conclusiveOutomeText}
                // text={appt.probabilityDNA + " : " + conclusiveOutomeText}
                color="#10A5DD"
                style={{
                  marginTop: "-20px",
                  marginRight: "-8px",
                  minWidth: 80,
                }}
              />
            </Tooltip>
          );
        return (
          <Tooltip
            title={"(" + appt.clinic.hospital.name + ") " + appt.clinic.name}
          >
            <Badge.Ribbon
              text={"At risk"}
              // text={appt.probabilityDNA + " : " + "At risk"}
              color="#FD590F"
              style={{
                marginTop: "-20px",
                marginRight: "-8px",
                minWidth: 80,
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "Appointment",
      defaultSortOrder: DEFAULT_SORT_ORDER,
      sortDirections: SORT_DIRECTIONS,
      sorter: (a: any, b: any) => {
        return (
          Number.parseInt(dayjs.utc(a.datetime ? a.datetime : 0).format("x")) -
          Number.parseInt(dayjs.utc(b.datetime ? b.datetime : 0).format("x"))
        );
      },
      width: 110,
      render: (a: any) => {
        return (
          <Tooltip title={dayjs.utc(a.datetime).fromNow()}>
            <>
              <div style={{ lineHeight: "20px" }}>
                <Text style={{ fontWeight: 500, marginLeft: 5 }}>
                  {dayjs.utc(a.datetime).tz().format("DD.MM")}
                  {". "}
                  {dayjs.utc(a.datetime).tz().format("HH:mm")}
                </Text>
              </div>
            </>
          </Tooltip>
        );
      },
      align: COLUMN_ALIGN,
    },
    {
      title: "Priority",
      width: 120,
      dataIndex: "priority",
      align: COLUMN_ALIGN,
      render: (priority: any) => {
        return <>{priority !== null && APPT_PRIORITIES_LABELS[priority]}</>;
      },
    },
    {
      title: "Clinic",
      width: 120,
      align: COLUMN_ALIGN,
      render: (appt: any) => {
        if (!isDna(appt))
          return (
            <Tooltip
              title={"(" + appt.clinic.hospital.name + ") " + appt.clinic.name}
            >
              <Text style={{ fontWeight: 500 }}>{appt.clinic.code}</Text>
            </Tooltip>
          );
        let hasConclusiveOutcome = false;
        for (let i = 0; i < appt.actions.length; i++) {
          if (
            isConclusiveAction({
              type: appt.actions[i].type,
              outcome: appt.actions[i].outcome,
            }) === true
          ) {
            hasConclusiveOutcome = true;
            break;
          }
        }
        if (hasConclusiveOutcome)
          return (
            <Tooltip
              title={"(" + appt.clinic.hospital.name + ") " + appt.clinic.name}
            >
              <Text style={{ fontWeight: 500 }}>{appt.clinic.code}</Text>
            </Tooltip>
          );
        return (
          <Tooltip
            title={"(" + appt.clinic.hospital.name + ") " + appt.clinic.name}
          >
            <Text style={{ fontWeight: 500 }}>{appt.clinic.code}</Text>
          </Tooltip>
        );
      },
    },
    {
      width: 70,
      render: (appt: ApptDto) => {
        if (!isDna(appt)) return <></>;
        let hasConclusiveOutcome = false;
        let conclusiveOutomeText = null;
        for (let i = 0; i < appt.actions.length; i++) {
          if (
            isConclusiveAction({
              type: appt.actions[i].type,
              outcome: appt.actions[i].outcome,
            }) === true
          ) {
            hasConclusiveOutcome = true;
            conclusiveOutomeText = getOutcomeText(appt.actions[i].outcome);
            break;
          }
        }
        if (hasConclusiveOutcome)
          return (
            <Tooltip
              title={"(" + appt.clinic.hospital.name + ") " + appt.clinic.name}
            >
              <Badge.Ribbon
                text={conclusiveOutomeText}
                color="#10A5DD"
                style={{
                  marginTop: "-20px",
                  marginRight: "-8px",
                  minWidth: 80,
                }}
              />
            </Tooltip>
          );
        return (
          <Tooltip
            title={"(" + appt.clinic.hospital.name + ") " + appt.clinic.name}
          >
            <Badge.Ribbon
              text={"At risk"}
              color="#FD590F"
              style={{
                marginTop: "-20px",
                marginRight: "-8px",
                minWidth: 80,
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  const onDatepickerChange = (date: any, dateString: any) => {
    setIsLoadingExtended(true);
    queryClient.cancelQueries(["findBookedDnaAppts", selectedDate], {
      exact: false,
    });
    setSelectedDate(date);
  };

  const collapseById = (apptId: string) => {
    const newExpandedAppts = expandedApptsByDate[
      selectedDate.format("D-M-YYYY")
    ].filter((expandedApptId: any) => expandedApptId !== apptId);
    setExpandedApptsByDate((prevState) => {
      return {
        ...prevState,
        [selectedDate.format("D-M-YYYY")]: newExpandedAppts,
      };
    });
  };

  if (isError) {
    return (
      <Text>
        Something went wrong, try refreshing the page. If issue persists, please
        contact technical support.
      </Text>
    );
  }
  return (
    <>
      <Title level={3}>
        <span style={{ fontWeight: 400 }}>Appointments on</span>{" "}
        {dayjs(selectedDate).format("dddd D, MMMM")}
      </Title>
      {editActionModal && (
        <EditActionModal
          action={editActionModal.action}
          clinicId={editActionModal.clinicId}
          appt={editActionModal.appt}
          onClose={() => {
            setEditActionModal(null);
          }}
          onSuccess={async () => {
            await refetchAppts();
          }}
        />
      )}
      <Spin
        tip="Loading..."
        spinning={isLoading || isLoadingExtended}
        size={"large"}
      >
        <div
          style={{
            padding: 12,
            paddingTop: 0,
            paddingBottom: 6,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: 500, display: "flex", flexDirection: "row" }}>
            <Form>
              <Form.Item style={{ marginBottom: 5 }}>
                <DatePicker
                  size="middle"
                  style={{ width: 279 }}
                  allowClear={false}
                  onChange={onDatepickerChange}
                  format={"MMMM, Do YYYY"}
                  defaultValue={selectedDate}
                  disabledDate={(current) => {
                    return current && current.isBefore(dateToday);
                  }}
                />
              </Form.Item>
            </Form>
            <Link to={ROUTES.APPTS_CALENDAR} style={{ marginLeft: 10 }}>
              <Button>Monthly view</Button>
            </Link>
          </div>
          {appts && appts.length > 0 ? (
            <Text style={{ paddingTop: 3, paddingLeft: 0, paddingBottom: 0 }}>
              We expect{" "}
              <span style={{ color: "green", fontWeight: "bold" }}>
                {extraStats.totalAttendance}
              </span>{" "}
              of our patients to arrive as scheduled
              {extraStats.totalRecommendations > 0 && (
                <span>
                  {" "}
                  and suggest{" "}
                  <span style={{ color: "orange", fontWeight: "bold" }}>
                    {extraStats.totalRecommendations + " actions"}
                  </span>{" "}
                  to help others get treated too
                </span>
              )}
              !
            </Text>
          ) : (
            <Text style={{ paddingTop: 3, paddingLeft: 0, paddingBottom: 0 }}>
              No scheduled appointments to help with.
            </Text>
          )}
        </div>
        <Card size="small" bodyStyle={{ padding: 0 }}>
          <Table
            size="middle"
            id={"tbl-dashboard-appts"}
            rowClassName={(record) => {
              let hasConclusiveOutcome = false;
              for (let i = 0; i < record.actions.length; i++) {
                if (
                  isConclusiveAction({
                    type: record.actions[i].type,
                    outcome: record.actions[i].outcome,
                  }) === true
                ) {
                  hasConclusiveOutcome = true;
                  break;
                }
              }
              if (isDna(record)) {
                if (hasConclusiveOutcome) {
                  return "table-row-has-conclusive-outcome";
                } else {
                  return "table-row-no-conclusive-outcome";
                }
              }
              return "";
            }}
            expandedRowKeys={
              expandedApptsByDate[selectedDate.format("D-M-YYYY")]
            }
            expandable={{
              expandedRowRender: (a: ApptDto | any) => {
                let hasConclusiveOutcome = false;
                for (let i = 0; i < a.actions.length; i++) {
                  if (
                    isConclusiveAction({
                      type: a.actions[i].type,
                      outcome: a.actions[i].outcome,
                    }) === true
                  ) {
                    hasConclusiveOutcome = true;
                    break;
                  }
                }
                let bgClassName;
                if (hasConclusiveOutcome) {
                  bgClassName = "expanded-row-has-conclusive-outcome";
                } else {
                  bgClassName = "expanded-row-no-conclusive-outcome";
                }
                const clinicBBCap = clinicsCaps[a.clinic.id].cap;
                return (
                  <div
                    className={bgClassName}
                    style={{
                      display: "flex",
                      margin: 26,
                      marginTop: 0,
                      marginBottom: 0,
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        flex: "2 1 0",
                        marginLeft: 40,
                        paddingLeft: 52,
                        paddingRight: 20,
                        borderRight: "1px solid #D2D2D6",
                      }}
                    >
                      <div style={{ paddingBottom: 25 }}>
                        <h3 style={{ marginBottom: 15, marginTop: 12 }}>
                          Actions available
                        </h3>
                        <Space>
                          {!hasConclusiveOutcome ? (
                            <>
                              <ActionCalled
                                clinicId={a.clinic.id}
                                appt={a}
                                patient={a.patient}
                                refetchAppts={refetchAppts}
                                collapseActioned={collapseById}
                              />
                              <ActionCalledNoAnswer
                                clinicId={a.clinic.id}
                                apptId={a.id}
                                refetchAppts={refetchAppts}
                              />
                              {isBBAllowed({
                                clinicId: a.clinic.id,
                                caps: clinicsCaps,
                              }) && (
                                <ActionDoublebooked
                                  clinicId={a.clinic.id}
                                  apptId={a.id}
                                  refetchAppts={refetchAppts}
                                  collapseActioned={collapseById}
                                />
                              )}
                            </>
                          ) : (
                            <p style={{ marginBottom: 0 }}>
                              - no further actions required
                            </p>
                          )}
                        </Space>
                        {!hasConclusiveOutcome &&
                          !isBBAllowed({
                            clinicId: a.clinic.id,
                            caps: clinicsCaps,
                          }) && (
                            <p>
                              - backup booking not allowed{" "}
                              {clinicBBCap != null && clinicBBCap > 0 && (
                                <>
                                  (max: <b>{clinicBBCap}</b>)
                                </>
                              )}
                            </p>
                          )}
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid #D2D2D6",
                          paddingTop: "15px",
                        }}
                      >
                        <h3 style={{ marginBottom: 15, marginTop: 5 }}>
                          Actions history
                        </h3>
                        {a.actions.length > 0 ? (
                          renderActionsTimeline(a, a.clinic.id, a.actions)
                        ) : (
                          <p>- no actions performed</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: "3 1 0",
                        marginLeft: 20,
                        marginRight: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      {!hasConclusiveOutcome &&
                        (a.patient.phone || a.patient.phoneLandline) && (
                          <>
                            <Title level={5} style={{ marginTop: 12 }}>
                              Patient's contacts
                            </Title>
                            {a.patient.phone && (
                              <>
                                <span style={{ display: "inline" }}>
                                  <MobileOutlined style={{ marginRight: 5 }} />
                                  {a.patient.phone}
                                </span>
                                <br />
                              </>
                            )}
                            {a.patient.phoneLandline && (
                              <span style={{ display: "inline" }}>
                                <PhoneOutlined style={{ marginRight: 5 }} />
                                {a.patient.phoneLandline}
                              </span>
                            )}
                          </>
                        )}
                      <Tabs
                        items={[
                          {
                            key: "1",
                            label: "Reasons insights",
                            children: isDna(a) ? (
                              renderPieChart(a.reasons)
                            ) : (
                              <>Patient is expected to arrive as scheduled.</>
                            ),
                          },
                          {
                            key: "2",
                            label:
                              "Future appointments (" +
                              a.patient.futureAppts.length +
                              ")",
                            children: (
                              <Table
                                size="middle"
                                id="tbl-future-appts"
                                style={{ margin: 0 }}
                                columns={columns2}
                                dataSource={a.patient.futureAppts.map(
                                  (futureAppt: any) => ({
                                    ...futureAppt,
                                    key: futureAppt.id,
                                  })
                                )}
                                pagination={false}
                              />
                            ),
                          },
                        ]}
                      />
                      {((typeof a.referralToTreatment !== "undefined" &&
                        a.referralToTreatment != null) ||
                        (typeof a.consultationMechanism !== "undefined" &&
                          a.consultationMechanism != null)) && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderTop: "1px solid #D2D2D6",
                            marginTop: "25px",
                          }}
                        >
                          {typeof a.referralToTreatment !== "undefined" &&
                            a.referralToTreatment != null && (
                              <div style={{ flex: "2 1 0", marginRight: 30 }}>
                                <h3
                                  style={{
                                    fontWeight: 500,
                                    marginTop: 25,
                                    fontSize: "1em",
                                  }}
                                >
                                  Referral to treatment
                                </h3>
                                <p>
                                  {dayjs.utc(a.referralToTreatment).fromNow()}
                                </p>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              },
              expandRowByClick: true,
              showExpandColumn: true,
              onExpand: (expanded, record) => {
                let newExpandedAppts: string[];
                if (expanded) {
                  newExpandedAppts = [
                    ...expandedApptsByDate[selectedDate.format("D-M-YYYY")],
                    record.key,
                  ];
                } else {
                  newExpandedAppts = expandedApptsByDate[
                    selectedDate.format("D-M-YYYY")
                  ].filter(
                    (expandedApptKey: any) => expandedApptKey !== record.key
                  );
                }
                setExpandedApptsByDate((prevState) => {
                  return {
                    ...prevState,
                    [selectedDate.format("D-M-YYYY")]: newExpandedAppts,
                  };
                });
              },
              expandedRowClassName: () => "appt-expanded",
              rowExpandable: (appt: any) => isDna(appt) || appt?.actions.length,
            }}
            showSorterTooltip={false}
            columns={columns}
            dataSource={appts}
            pagination={{ defaultPageSize: 999999, hideOnSinglePage: true }}
          />
        </Card>
      </Spin>
    </>
  );
};
