import { FC } from "react";
import { Modal, Form, Switch } from "antd";
import { ActionDto } from "../../../common/dtos/ActionDto";
import { getOutcomeText } from "../../../common/helpers/actions";
import TextArea from "antd/lib/input/TextArea";
import { useMutation, useQueryClient } from "react-query";
import * as apptsApi from "../../../api/apptsApi";
import { notify } from "../../../common/helpers/notify";
import { ApptDto } from "../../../common/dtos/ApptDto";
import { BirthDateWithGender } from "../../../common/components/BirthDateWithGender";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import { dayjs } from "../../../common/helpers/dayjs";

interface CompleteActionModalProps {
  appt: ApptDto;
  action: ActionDto;
  activeClinicId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export const CompleteActionModal: FC<CompleteActionModalProps> = ({
  appt,
  activeClinicId,
  onClose,
  action,
  onSuccess,
}: CompleteActionModalProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { mutate: updateAction, isLoading } = useMutation(
    (updatedAction: any) => apptsApi.updateAction(updatedAction),
    {
      onSuccess,
      onError: () => notify.error("Unable to update action."),
    }
  );
  const initialValues = {
    callNote: action.callNote,
    isCompleted: true,
    actionNote: action.actionNote,
  };
  const isWithPii = appt.patient.patientIdRaw != null ? true : false;

  return (
    <Modal
      title={`Requested: ${getOutcomeText(action.outcome)}`}
      width={isWithPii ? 860 : 520}
      style={isWithPii ? { minWidth: 860 } : { minWidth: 520 }}
      open={true}
      confirmLoading={isLoading}
      onOk={async () => {
        queryClient.cancelQueries(["findBookedDnaAppts"], { exact: false });
        form.submit();
      }}
      okText="Confirm"
      onCancel={onClose}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {isWithPii && (
          <div className="modal-patient-side">
            {appt.patient.firstName && appt.patient.lastName && (
              <h3>
                <UserOutlined
                  style={{
                    color: "#fff",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                />{" "}
                {appt.patient.lastName.toUpperCase()}, {appt.patient.firstName}
              </h3>
            )}
            <ul className="patient-pii">
              {appt.patient.patientIdRaw && (
                <li>
                  <span className="color-gray">Patient ID</span>{" "}
                  <span style={{ fontWeight: "500" }}>
                    {appt.patient.patientIdRaw}
                  </span>
                </li>
              )}
              <li>
                <span className="color-gray">Birth date</span>
                <span>
                  <BirthDateWithGender
                    birthDate={appt.patient.birthDate}
                    gender={appt.patient.gender}
                  />
                </span>
              </li>
              {appt.patient.phone && (
                <li>
                  <span className="color-gray">Phone</span>{" "}
                  <span>{appt.patient.phone}</span>
                </li>
              )}
              {appt.patient.phoneLandline && (
                <li>
                  <span className="color-gray">Phone landline</span>{" "}
                  <span>{appt.patient.phoneLandline}</span>
                </li>
              )}
            </ul>
            <h3>
              <ClockCircleOutlined
                style={{
                  color: "#fff",
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
              />{" "}
              {dayjs.utc(appt.datetime).tz().format("HH:mm (DD.MM)")}
            </h3>
            <ul className="patient-pii">
              <li>
                <span className="color-gray">Specialty</span>{" "}
                <span>{appt.specialty.name}</span>
              </li>
              <li>
                <span
                  className="color-gray"
                  style={{ paddingRight: 10, alignSelf: "flex-start" }}
                >
                  Clinic
                </span>
                <span style={{ textAlign: "right" }}>
                  {appt.clinic.name} ({appt.clinic.code})
                </span>
              </li>
              {appt.site && (
                <li>
                  <span className="color-gray">Site</span>
                  <span style={{ textAlign: "right" }}>{appt.site}</span>
                </li>
              )}
              {appt.consultationMechanism && (
                <li>
                  <span className="color-gray">Consultation mechanism</span>
                  <span style={{ textAlign: "right" }}>
                    {appt.consultationMechanism}
                  </span>
                </li>
              )}
            </ul>
          </div>
        )}
        <div style={{ flex: "1.8" }}>
          <Form
            form={form}
            labelCol={{ span: 5 }}
            onFinish={(values) =>
              updateAction({
                clinicId: activeClinicId,
                id: action.id,
                actionNote: values.actionNote,
                isCompleted: values.isCompleted,
              })
            }
            autoComplete="off"
            size="large"
            name="update_action"
            initialValues={initialValues}
          >
            <Form.Item label="Call notes:" name="callNote">
              <TextArea
                rows={4}
                disabled={true}
                value={action.callNote ?? ""}
              />
            </Form.Item>
            <Form.Item label="Other notes" name="actionNote">
              <TextArea
                rows={4}
                maxLength={500}
                placeholder={"max 500 characters"}
              />
            </Form.Item>
            <Form.Item
              label="Is completed"
              name="isCompleted"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
