import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCommMessages } from "../../api/commApi";
import { CommMessageDto } from "./dtos/CommMessageDto";
import { CommMessagesTable } from "./components/CommMessagesTable";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { Card, TablePaginationConfig, Typography } from "antd";
import { BackButton } from "../../common/components/BackButton";

const PAGE_SIZE = 15;

const { Title } = Typography;

export const ListCommMessagesPage: FC = () => {
  const { commId } = useParams() as { commId: string };

  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: PAGE_SIZE,
    sortBy: "created",
    order: "ASC",
    total: 0,
  });
  const handlePaginationChange = (newPagination: TablePaginationConfig) => {
    setPagination({
      ...pagination,
      current: newPagination.current ?? 1,
      pageSize: newPagination.pageSize ?? PAGE_SIZE,
    });
  };

  const { data, isLoading } = useQuery(
    ["get-comm-messagges", commId, ...Object.values(pagination)],
    () => getCommMessages(commId, pagination.pageSize, pagination.current),
    {
      onSuccess: (data: any) => {
        setPagination({
          ...pagination,
          total: data.total,
        });
      },
    }
  );
  const commMessages: CommMessageDto[] = data?.results ?? [];
  return (
    <>
      <Title level={3}>Communication messages</Title>
      <Card
        title={<BackButton />}
        size="small"
        bodyStyle={{ padding: 0 }}
        headStyle={{ paddingTop: 7, paddingBottom: 7 }}
      >
        <CommMessagesTable
          isLoading={isLoading}
          data={commMessages}
          onPaginationChange={handlePaginationChange}
          pagination={pagination}
        />
      </Card>
    </>
  );
};
