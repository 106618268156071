import React, { FC } from "react";
import { Layout } from "antd";
import bg from "../../../common/images/bg-login.jpg";
import { Footer } from "../Footer";

const { Content } = Layout;
type PublicLayoutProps = {
  children: React.ReactNode;
};

export const PublicLayout: FC<PublicLayoutProps> = (
  props: PublicLayoutProps
) => {
  return (
    <div
      style={{
        backgroundImage: `url(${bg}`,
        display: "flex",
        flex: "auto",
        flexDirection: "column",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="bg-login-overlay"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          flex: "auto",
          flexDirection: "column",
        }}
      >
        <Content
          style={{
            display: "block",
            width: "100%",
            margin: "0 auto",
            padding: "20px 50px",
            maxWidth: "1300px",
          }}
        >
          {props.children}
        </Content>
        <Footer isOnDarkBg={true} />
      </div>
    </div>
  );
};
