import type { FC } from "react";
import { useState } from "react";
import {
  Typography,
  Table,
  Card,
  TablePaginationConfig,
  Alert,
  Button,
} from "antd";
import { useQuery } from "react-query";
import * as adminAPI from "../../api/adminApi";
import { dayjs } from "../../common/helpers/dayjs";
import { Link } from "react-router-dom";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { ROUTES } from "../../common/constants/ROUTES";
import { truncate } from "../../common/helpers/truncate";

const defaultSortOrder: any = "descend";
const { Title } = Typography;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (text: string, record: any, index: number) => {
      return (
        <Link to={`/admin/clinics/view/${record.id}`}>
          {truncate(text, 32)}
        </Link>
      );
    },
  },
  {
    defaultSortOrder,
    title: "Code",
    dataIndex: "code",
  },
  {
    title: "Hospital",
    dataIndex: "hospital",
    render: function (text: any, record: any, index: any) {
      return truncate(record.hospital.name, 24);
    },
  },
  {
    title: "Added on",
    dataIndex: "created",
    render: function (text: any, record: any, index: any) {
      return dayjs.utc(text).tz().format("DD.MM.YYYY");
    },
  },
];

const PAGINATION_SIZE = 20;

export const ListClinicsPage: FC = () => {
  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: PAGINATION_SIZE,
    sortBy: "created",
    order: "desc",
    total: 0,
  });
  const { isLoading, isError, data, error } = useQuery(
    [
      pagination.current,
      pagination.pageSize,
      pagination.sortBy,
      pagination.order,
      "getClinics",
    ],
    adminAPI.findClinics,
    {
      onSuccess: (response: { results: any[]; total: number }) => {
        setPagination({
          ...pagination,
          total: response.total,
        });
      },
    }
  );
  const onTableChange = async (
    paginationCfg: TablePaginationConfig
  ): Promise<any> => {
    setPagination({
      current: paginationCfg.current ?? 1,
      pageSize: paginationCfg.pageSize ?? PAGINATION_SIZE,
      sortBy: pagination.sortBy,
      order: pagination.order,
      total: pagination.total,
    });
  };
  return (
    <>
      <Title level={3}>Manage clinics</Title>
      <Card
        size="small"
        bodyStyle={{ padding: 0 }}
        headStyle={{ paddingTop: 7, paddingBottom: 7 }}
        title={
          <>
            <Link to={ROUTES.ADMIN_CLINICS_THRESHOLDS}>
              <Button type="primary">Update thresholds</Button>
            </Link>
            <Link to={ROUTES.ADMIN_CLINICS_SEARCH}>
              <Button type="primary" style={{ marginLeft: 10 }}>
                Search clinics
              </Button>
            </Link>
            <Link
              to={ROUTES.ADMIN_CLINICS_APPTS_CANCELLATION}
              style={{ marginLeft: 10 }}
            >
              <Button type="primary">Appts cancellation</Button>
            </Link>
          </>
        }
      >
        {isError && (
          <Alert
            type="error"
            showIcon={true}
            message={(error as Error).message}
            style={{ marginBottom: 10 }}
          />
        )}
        <Table
          size="middle"
          loading={isLoading}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>Location: {record.location}</p>
            ),
          }}
          rowKey={(row) => row.id}
          columns={columns}
          dataSource={data?.results ?? []}
          pagination={{
            defaultPageSize: 15,
            hideOnSinglePage: true,
            position: ["bottomLeft"],
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
          }}
          onChange={(pagination) => onTableChange(pagination)}
        />
      </Card>
    </>
  );
};
