import { Button, Modal, Tooltip, Form, Input } from "antd";
import { useMutation, useQueryClient } from "react-query";
import * as apptsAPI from "../../../api/apptsApi";
import { MdGroup } from "react-icons/md";
import {
  ACTION_TYPES,
  DOUBLEBOOK_OUTCOMES,
} from "../../../common/helpers/actions";
import { FC, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { notify } from "../../../common/helpers/notify";

const { TextArea } = Input;

interface ActionDoubleBookedType {
  clinicId: string;
  apptId: string;
  refetchAppts: any;
  collapseActioned: (apptId: string) => void;
}

export const ActionDoublebooked: FC<ActionDoubleBookedType> = ({
  clinicId,
  apptId,
  refetchAppts,
  collapseActioned,
}: ActionDoubleBookedType) => {
  const queryClient = useQueryClient();
  const { mutate: addAction, isLoading } = useMutation(apptsAPI.addAction, {
    onSuccess: async () => {
      notify.success("Action saved.");
      setIsOutcomeModalOpen(false);
      await refetchAppts();
      form.resetFields();
      setTimeout(() => collapseActioned(apptId), 1000);
    },
    onError: () => {
      notify.error("Unable to add action. Please try again.");
    },
  });
  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState<boolean>(false);
  const [form] = useForm();
  return (
    <>
      <Modal
        title='New action "Backup booking"'
        open={isOutcomeModalOpen}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { minWidth: 90 } }}
        onCancel={() => setIsOutcomeModalOpen(false)}
        onOk={() => {
          queryClient.cancelQueries(["findBookedDnaAppts"], { exact: false });
          form.submit();
        }}
        okText="Confirm"
      >
        <Form
          labelCol={{ span: 6 }}
          form={form}
          className={"disable-select"}
          onFinish={(data) => {
            addAction({
              clinicId: clinicId,
              type: ACTION_TYPES.DOUBLEBOOK,
              apptId: apptId,
              outcome: DOUBLEBOOK_OUTCOMES.DOUBLEBOOKED,
              callNote: data.callNote ?? null,
            });
          }}
        >
          <Form.Item
            name="callNote"
            label="Notes"
            style={{
              marginTop: 15,
            }}
          >
            <TextArea
              rows={5}
              placeholder="max 500 characters"
              maxLength={500}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Tooltip title={"Backup-booked"}>
        <Button
          disabled={isLoading}
          htmlType="submit"
          type="primary"
          className={"btn-recommendation"}
          shape="circle"
          style={{ backgroundColor: "#ccc", borderColor: "#ccc" }}
          onClick={() => {
            setIsOutcomeModalOpen(true);
          }}
          icon={<MdGroup />}
        />
      </Tooltip>
    </>
  );
};
