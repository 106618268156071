import { Card, Typography, Alert, Col, Row, Spin } from "antd";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Navigate, useLocation } from "react-router-dom";
import * as smsJobsApi from "../../api/smsApi";
import { ROUTES } from "../../common/constants/ROUTES";
import { BackButton } from "../../common/components/BackButton";
import { CreateSmsJobDto } from "./dtos/SmsJobDto";
import { SmsJobForm, SmsJobFormValue } from "./components/SmsJobForm";
import { MessagePreview } from "./components/MessagePreview";
import { ClinicValueDto } from "../clinics/dtos/ClinicValueDto";
import { notify } from "../../common/helpers/notify";

const { Title } = Typography;

export const CreateSmsJobPage: FC = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const [selectedClinics, setSelectedClinics] = useState<ClinicValueDto[]>([]);
  const {
    mutate: createSmsJob,
    isError: isCreateError,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    error: createError,
  } = useMutation((dto: CreateSmsJobDto) => smsJobsApi.createSmsJob(dto), {
    onSuccess: () => {
      notify.success("New routine was created.");
      queryClient.invalidateQueries(["get-sms-jobs"]);
    },
    onError: () => notify.error("Unable to create a routine."),
  });
  const { data: smsCount, isLoading: isLoadingEstimate } = useQuery(
    ["estimate-sms", selectedClinics],
    async () =>
      smsJobsApi.estimateSms(selectedClinics.map((clinic) => clinic.value)),
    {
      enabled: selectedClinics.length > 0,
    }
  );
  if (isCreateSuccess) {
    return <Navigate to={ROUTES.ADMIN_SMS_JOBS} replace state={{ location }} />;
  }
  return (
    <>
      <Title level={3}>Create a new routine</Title>
      <Row gutter={24}>
        <Col xs={14}>
          <Spin spinning={isCreateLoading} size="large">
            <Card title={<BackButton />} size="small" bordered={false}>
              {isCreateError && (
                <Alert
                  message="Error"
                  description={(createError as Error).message}
                  type="error"
                  showIcon
                  style={{ marginBottom: 15 }}
                />
              )}{" "}
              <Spin spinning={isLoadingEstimate}>
                <Alert
                  message={`Will send approximately ${
                    smsCount ?? 0
                  } message(s) during the next 30 days`}
                  style={{ marginBottom: 15 }}
                />
              </Spin>
              <SmsJobForm
                initialValues={{
                  name: "",
                  clinics: [],
                  sendBefore: 1,
                }}
                onSubmit={(value: SmsJobFormValue) => {
                  createSmsJob({
                    ...value,
                    clinics: value.clinics.map(
                      (clinic: ClinicValueDto) => clinic.value
                    ),
                  });
                }}
                onChangeSelectedClinics={(clinics: ClinicValueDto[]) => {
                  setSelectedClinics(clinics);
                }}
                submitButtonLabel="Create"
              />
            </Card>
            <MessagePreview />
          </Spin>
        </Col>
      </Row>
    </>
  );
};
