import type { FC } from "react";
import { Button, Form, Input, Card, Typography, Alert } from "antd";
import logo from "../../common/images/logo-large.png";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAuth } from "../../common/helpers/auth";
import { LoginDto } from "../../common/dtos/LoginDto";
const { Text } = Typography;

export const LoginPage: FC = () => {
  const { login, authError } = useAuth();
  const onFinish = async (values: LoginDto) => {
    await login(values);
  };
  return (
    <>
      <div className="login-form-top">
        <img src={logo} className="login-logo" alt="logo" />
        <br />
        <Text style={{ color: "rgb(131 192 238)" }} className="login-subline">
          AI Enhanced Decision Making.
        </Text>
      </div>
      <div className="login-form-container">
        <Card>
          {authError && (
            <Alert
              description={authError}
              type="error"
              showIcon={true}
              style={{ marginBottom: 15 }}
            />
          )}
          <Form
            size="large"
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                autoComplete="off"
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                autoComplete="off"
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="btn-login">
                Log in
              </Button>
            </Form.Item>
          </Form>
          <Link className="login-form-forgot" to="/reset-password">
            Forgot password
          </Link>
        </Card>
      </div>
    </>
  );
};
