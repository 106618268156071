import { Card, Typography, Alert, Col, Row, Spin } from "antd";
import { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Navigate, useLocation } from "react-router-dom";
import * as commsApi from "../../../api/commApi";
import { ROUTES } from "../../../common/constants/ROUTES";
import { BackButton } from "../../../common/components/BackButton";

import { notify } from "../../../common/helpers/notify";
import { CommCredentialsForm } from "./components/CommCredentialsForm";
import { CreateCommCredentialsDto } from "./dtos/CommCredentialsDto";

const { Title } = Typography;

export const CreateCommCredentialsPage: FC = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const {
    mutate: createCommCredentials,
    isError: isCreateError,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    error: createError,
  } = useMutation(
    (value: CreateCommCredentialsDto) => commsApi.createCommCredentials(value),
    {
      onSuccess: () => {
        notify.success("Credential set successfully created.");
        queryClient.invalidateQueries(["get-comm-credentials"]);
      },
      onError: () => notify.error("Unable to create the credential set."),
    }
  );
  if (isCreateSuccess) {
    return (
      <Navigate
        to={ROUTES.ADMIN_COMM_CREDENTIALS}
        replace
        state={{ location }}
      />
    );
  }
  return (
    <>
      <Title level={3}>Create a new set of credentials</Title>
      <Row gutter={24}>
        <Col xs={14}>
          <Spin spinning={isCreateLoading} size="large">
            <Card
              title={<BackButton />}
              size="small"
              bordered={false}
              headStyle={{ paddingTop: 7, paddingBottom: 7 }}
            >
              {isCreateError && (
                <Alert
                  message="Error"
                  description={(createError as Error).message}
                  type="error"
                  showIcon
                  style={{ marginBottom: 15 }}
                />
              )}
              <CommCredentialsForm
                onSubmit={(value: CreateCommCredentialsDto) => {
                  createCommCredentials(value);
                }}
              />
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};
