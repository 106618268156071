import { api } from "./api";
import { UpdateThresholdsDto } from "./dtos/adminApiClinicsDtos";
import { ClinicDto } from "../common/dtos/ClinicDto";

export const updateThresholds = async (
  updateThresholdsDto: UpdateThresholdsDto
): Promise<{ affectedClinicsCount: number; missingClinicsCount: number }> => {
  const response = (await api.post(
    "/admin/clinics/thresholds",
    updateThresholdsDto,
    {
      withCredentials: true,
    }
  )) as { affectedClinicsCount: number; missingClinicsCount: number };
  return response;
};

export const searchClinics = async (
  searchedText: string,
  clientId: string
): Promise<ClinicDto[]> => {
  const response = (await api.get("/admin/clinics/search", {
    withCredentials: true,
    params: {
      searchedText,
      clientId,
    },
  })) as ClinicDto[];
  return response;
};