import { Form, Spin, Switch } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import * as usersAPI from "../../../api/usersApi";
import { notify } from "../../../common/helpers/notify";
import { useAuth } from "../../../common/helpers/auth";
import { UserDto } from "../../../common/dtos/UserDto";

const TYPE_SETTING_DISABLE_NOTIFICATIONS = 1;
const TYPE_SETTING_BOOL = 1;

export const NotificationSettingsTab = () => {
  const [isDisableNotifications, setDisableNotifications] = useState(null);
  const { user }: any = useAuth();
  const { isLoading: isLoadingCurrentUser } = useQuery(
    ["getCurrentUser", "NotificationSettingsTabCache"],
    usersAPI.getCurrentUser,
    {
      onSuccess: (response: UserDto) => {
        setDisableNotifications(
          getDisableNotificationsSettingAsBoolean(response?.settings ?? [])
        );
      },
    }
  );
  const getDisableNotificationsSettingAsBoolean = (settings: any[]) => {
    for (let i = 0; i < settings.length; i++) {
      if (settings[i].type === TYPE_SETTING_DISABLE_NOTIFICATIONS) {
        return settings[i].valueInt;
      }
    }
    return false;
  };
  const { mutate: updateSettings, isLoading: isLoadingUpdateMySettings }: any =
    useMutation(usersAPI.updateMySettings, {
      onSuccess: () => notify.success("Notification settings updated."),
      onError: () => notify.success("Error updating notification settings."),
    });
  return (
    <Spin spinning={isLoadingCurrentUser || isLoadingUpdateMySettings}>
      {isDisableNotifications !== null && user !== null && (
        <Form
          initialValues={{
            isDisableNotifications,
          }}
        >
          <Form.Item
            label="Disable daily emails about attendance"
            name="isDisableNotifications"
            valuePropName="checked"
          >
            <Switch
              onClick={(value: boolean) => {
                updateSettings({
                  userId: user.id,
                  name: TYPE_SETTING_DISABLE_NOTIFICATIONS,
                  type: TYPE_SETTING_BOOL,
                  valueInt: value ? 1 : 0,
                });
              }}
            />
          </Form.Item>
        </Form>
      )}
    </Spin>
  );
};
