import type { FC } from "react";
import { Typography, Table, Card, Spin, Badge, Alert } from "antd";
import { useQuery } from "react-query";
import * as adminAPI from "../../api/adminApi";
import { dayjs } from "../../common/helpers/dayjs";
import { notify } from "../../common/helpers/notify";
import { SortOrder } from "antd/lib/table/interface";
const defaultSortOrder: any = "descend";
const sortDirections: SortOrder[] = ["ascend", "descend", "ascend"];
const { Title } = Typography;

const columns = [
  {
    title: "Batch ID",
    dataIndex: "id",
    render: (id: string) => "..." + id.slice(-5),
  },
  {
    title: "Generated",
    dataIndex: "generated",
    sorter: (a: any, b: any) =>
      Number.parseInt(dayjs.utc(a.generated ? a.generated : 0).format("x")) -
      Number.parseInt(dayjs.utc(b.generated ? b.generated : 0).format("x")),
    render: (a: any) => dayjs.utc(a).tz().format("DD.MM HH:mm"),
  },
  {
    title: "Size",
    dataIndex: "size",
    sorter: (a: any, b: any) =>
      Number.parseInt(a.size) - Number.parseInt(b.size),
  },
  {
    title: "Time to sync",
    render: (a: any) => {
      const generatedDate = dayjs.utc(a.generated);
      const syncedDate = dayjs.utc(a.synced);
      const diff = syncedDate.diff(generatedDate, "minute");
      return diff + " min";
    },
  },
  {
    title: "Time to live",
    render: (a: any) => {
      if (!a.deployed) return "-";
      const generatedDate = dayjs.utc(a.generated);
      const deployedDate = dayjs.utc(a.deployed);
      const diff = deployedDate.diff(generatedDate, "minute");
      return diff + " min";
    },
  },
  {
    title: "Sync speed",
    render: (a: any) => {
      if (!a.deployed) return "-";
      const syncedDate = dayjs.utc(a.synced);
      const generatedDate = dayjs.utc(a.generated);
      const diff = syncedDate.diff(generatedDate, "second");
      if (diff === 0) return "< 1 sec";
      const speed = a.size / diff;
      return parseFloat(speed.toFixed(1)) + " msg/sec";
    },
  },
  {
    title: "Depl. speed",
    render: (a: any) => {
      if (!a.deployed) return "-";
      const deployedDate = dayjs.utc(a.deployed);
      const detectedDate = dayjs.utc(a.created);
      const diff = deployedDate.diff(detectedDate, "second");
      if (diff === 0) return "< 1 sec";
      const speed = a.size / diff;
      return parseFloat(speed.toFixed(1)) + " msg/sec";
    },
  },
  {
    title: "Depl. duration",
    render: (a: any) => {
      if (!a.deployed) return "-";
      const deployedDate = dayjs.utc(a.deployed);
      const detectedDate = dayjs.utc(a.created);
      const diff = deployedDate.diff(detectedDate, "minute");
      if (!diff) return "< 1 min";
      return diff + " min";
    },
  },
  {
    defaultSortOrder,
    title: "Synced",
    dataIndex: "synced",
    sorter: (a: any, b: any) =>
      Number.parseInt(dayjs.utc(a.synced ? a.synced : 0).format("x")) -
      Number.parseInt(dayjs.utc(b.synced ? b.synced : 0).format("x")),
    render: (a: any) => dayjs.utc(a).tz().format("DD.MM HH:mm"),
  },
  {
    title: "Deployed",
    sorter: (a: any, b: any) =>
      Number.parseInt(dayjs.utc(a.deployed ? a.deployed : 0).format("x")) -
      Number.parseInt(dayjs.utc(b.deployed ? b.deployed : 0).format("x")),
    render: (a: any) => {
      if (a.deployed)
        return (
          <Badge
            count={
              a.deployed
                ? dayjs.utc(a.deployed).tz().format("DD.MM HH:mm")
                : ""
            }
            showZero
            color="#52c41a"
          />
        );
      if (!a.validationError && !a.deploymentError)
        return <Badge count={"deploying"} showZero color="#faad14" />;

      const errors = [];
      if (a.validationError)
        errors.push(
          <li key={errors.length + 1}>
            <Badge count={"validation failed"} showZero />
          </li>
        );
      if (a.deploymentError)
        errors.push(
          <li key={errors.length + 1}>
            <Badge count={"deployment failed"} showZero />
          </li>
        );
      return (
        <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
          {errors}
        </ul>
      );
    },
  },
];

export const ListSyncsPage: FC = () => {
  const { isLoading, data: batches } = useQuery(
    ["getSyncs"],
    adminAPI.getSyncs,
    {
      onError: (error: Error) =>
        notify.error(
          error.message ?? "Unable to get data. Please contact admin."
        ),
    }
  );
  return (
    <>
      <Title level={3}>Data syncs & deployments</Title>
      <Spin spinning={isLoading}>
        <Card size="small" bodyStyle={{ padding: 0 }}>
          <Table
            size="middle"
            expandable={{
              expandedRowRender: (batch) => {
                const errors = [];
                if (batch.validationError)
                  errors.push(
                    <li key={errors.length + 1}>
                      <Alert
                        type="error"
                        showIcon={true}
                        message={
                          batch.msgValidationErrors &&
                          batch.msgValidationErrors.length > 0 ? (
                            <>
                              {batch.validationError}
                              <p
                                style={{
                                  marginTop: 10,
                                  marginBottom: 0,
                                  marginLeft: 20,
                                }}
                              >
                                Details:
                              </p>
                              <ul>
                                {batch.msgValidationErrors.map(
                                  (errorMsg: string, idx: number) => (
                                    <li key={idx}>{errorMsg}</li>
                                  )
                                )}
                              </ul>
                            </>
                          ) : (
                            batch.validationError
                          )
                        }
                        style={{ marginBottom: 10 }}
                      />
                    </li>
                  );
                if (batch.deploymentError)
                  errors.push(
                    <li key={errors.length + 1}>
                      <Alert
                        type="error"
                        showIcon={true}
                        message={batch.deploymentError}
                        style={{ marginBottom: 10 }}
                      />
                    </li>
                  );
                return (
                  <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                    {errors}
                  </ul>
                );
              },
              rowExpandable: (batch) =>
                batch.validationError || batch.deploymentError,
            }}
            rowKey={(row) => row.id}
            sortDirections={sortDirections}
            columns={columns}
            dataSource={batches ?? []}
            pagination={{
              defaultPageSize: 15,
              hideOnSinglePage: true,
              position: ["bottomLeft"],
            }}
          />
        </Card>
      </Spin>
    </>
  );
};
