import type { FC } from "react";
import React, { useState, useEffect } from "react";
import { Menu, Layout, Typography, Avatar } from "antd";
import {
  UserOutlined,
  SafetyOutlined,
  BellOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { UserSettingsTab } from "./components/UserSettingsTab";
import { PasswordSettingsTab } from "./components/PasswordSettingsTab";
import { PrivacySettingsTab } from "./components/PrivacySettingsTab";
import { NotificationSettingsTab } from "./components/NotificationSettingsTab";
import { useAuth } from "../../common/helpers/auth";
import { dayjs } from "../../common/helpers/dayjs";
import { useClinicsMultiselectContext } from "../../common/components/HospitalLayout/HospitalLayout";

const { Title } = Typography;
const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Profile", "user", <UserOutlined />),
  getItem("Security", "password", <UnlockOutlined />),
  getItem("Notifications", "notification", <BellOutlined />),
  getItem("Privacy", "privacy", <SafetyOutlined />),
];

type SettingsStateKeys = "user" | "password" | "privacy" | "notification";
type SettingsState = {
  selectKey: SettingsStateKeys;
};

export const SettingsPage: FC = () => {
  const { isUpdatingUserSettings, setIsUpdatingUserSettings }: any =
    useClinicsMultiselectContext();
  const auth: any = useAuth();
  const [initConfig, setInitConfig] = useState<SettingsState>({
    selectKey: "user",
  });

  // autoremover of global spinner for clinicsmultiselect
  useEffect(() => {
    setIsUpdatingUserSettings(false);
  }, [isUpdatingUserSettings, setIsUpdatingUserSettings]);

  const renderChildren = () => {
    const { selectKey } = initConfig;
    switch (selectKey) {
      default:
      case "user":
        return <UserSettingsTab />;
      case "password":
        return <PasswordSettingsTab />;
      case "notification":
        return <NotificationSettingsTab />;
      case "privacy":
        return <PrivacySettingsTab />;
    }
  };

  return (
    <>
      <Title level={3}>Settings</Title>
      <Layout className="site-layout-background">
        <Sider
          className="site-layout-background"
          width={200}
          style={{ backgroundColor: "#fff", borderRight: "1px solid #f0f2f5" }}
        >
          <div
            style={{
              textAlign: "center",
              padding: 20,
            }}
          >
            <Avatar
              size={96}
              style={{
                color: "#fff",
                backgroundColor: "rgb(111 69 206)",
                fontWeight: 600,
                marginRight: 7,
                fontSize: 46,
              }}
            >
              {auth.user.firstName[0] + auth.user.lastName[0]}
            </Avatar>
            <Title level={5} style={{ marginTop: 10 }}>
              {auth.user.firstName} {auth.user.lastName}
            </Title>
            <p style={{ fontSize: 12, color: "#a2a2a2" }}>
              Member since {dayjs.utc(auth.user.created).tz().format("DD.MM.YYYY")}
            </p>
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={["user"]}
            items={items}
            style={{ borderRight: 0 }}
            onClick={({ key }) => {
              setInitConfig({
                selectKey: key as SettingsStateKeys,
              });
            }}
          />
        </Sider>
        <Content
          style={{
            padding: "32px 24px",
            minHeight: 380,
            backgroundColor: "#fff",
          }}
        >
          {renderChildren()}
        </Content>
      </Layout>
    </>
  );
};
