import { useState } from "react";
import debounce from "lodash.debounce";
import type { SelectProps } from "antd/es/select";
import * as adminAPI from "../../../api/adminApi";
import { Select, Spin } from "antd";
import { UserDto } from "../../../common/dtos/UserDto";
import { notify } from "../../../common/helpers/notify";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  debounceTimeout?: number;
  clientId: string;
}

interface SelectOption {
  key: string;
  label: string;
  value: any;
}

export function SelectUser<ValueType extends SelectOption = any>({
  debounceTimeout = 800,
  clientId,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const fetchUsersDebounce = debounce(async (searchTerm: string) => {
    if (!searchTerm) return;
    setOptions([]);
    setFetching(true);
    try {
      const users: Partial<UserDto>[] = await adminAPI.searchUsers(
        searchTerm,
        clientId
      );
      const opts: SelectOption[] = users.map(
        (user: Partial<UserDto> | any) => ({
          key: user.id,
          label: `${user.firstName} ${user.lastName} (${user.email})`,
          value: JSON.stringify(user), // passing a JSON as value does not work well with the Antd <Select> component.
        })
      );
      setOptions(opts);
    } catch (error: any) {
      notify.error(error.message);
    } finally {
      setFetching(false);
    }
  }, debounceTimeout);

  return (
    <Select
      allowClear
      showSearch
      optionLabelProp="label"
      labelInValue={true}
      filterOption={false}
      onSearch={fetchUsersDebounce}
      notFoundContent={fetching ? <Spin size="small" /> : "no results"}
      {...props}
      options={options}
    />
  );
}
