import { FC } from "react";
import { Form, Input, Button } from "antd";
import { useForm } from "antd/es/form/Form";
import { SelectClient } from "../../../users/components/SelectClient";
import {
  CommCredentialsDto,
  CreateCommCredentialsDto,
} from "../dtos/CommCredentialsDto";

interface CommCredentialsFormProps {
  onSubmit: (value: CreateCommCredentialsDto) => void;
  initialValues?: CommCredentialsDto;
}

export interface CommCredentialsFormValue {
  name: string;
  serviceSecret: string;
  serviceId: string;
  clientId: string;
}

const MAX_NAME_LENGTH = 50;
const MIN_NAME_LENGTH = 4;

export const CommCredentialsForm: FC<CommCredentialsFormProps> = ({
  initialValues,
  onSubmit,
}: CommCredentialsFormProps) => {
  const [form] = useForm<CommCredentialsFormValue>();
  const onFinish = (values: CreateCommCredentialsDto) => {
    onSubmit(values);
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 5 }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        ...initialValues,
        client: {
          label: initialValues?.client.code,
          value: initialValues?.client.id,
        },
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
          },
          {
            min: 4,
            message: `Name min length is ${MIN_NAME_LENGTH} characters`,
          },
          {
            max: 50,
            message: `Name max length is ${MAX_NAME_LENGTH} characters`,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Service id"
        name="serviceId"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Service secret"
        name="serviceSecret"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Client" name="clientId" rules={[{ required: true }]}>
        <SelectClient placeholder="Select a client" />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          size="middle"
          style={{ minWidth: 100, marginTop: "7px", marginBottom: "7px" }}
        >
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};
