import type { FC } from "react";
import { useState } from "react";
import {
  Card,
  Typography,
  Col,
  Row,
  Descriptions,
  Table,
  Spin,
  Tag,
  Popover,
  Button,
  Popconfirm,
} from "antd";
import { useParams } from "react-router-dom";
import { useMutation, useQueries } from "react-query";
import * as adminAPI from "../../api/adminApi";
import {
  WomanOutlined,
  ManOutlined,
  QuestionOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { GENDERS } from "../../common/constants/GENDERS";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { ClinicDto } from "../../common/dtos/ClinicDto";
import { ApptDto } from "../../common/dtos/ApptDto";
import { isDna } from "../../common/helpers/appts";
import dayjs from "dayjs";
import { MdCancel, MdCheckBox } from "react-icons/md";
import * as adminApptsApi from "../../api/admin/apptsApi";
import { notify } from "../../common/helpers/notify";
import { BackButton } from "../../common/components/BackButton";
const { Title, Text } = Typography;

const defaultSortOrder: any = "ascend";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    render: (id: string) => <Text>{id}</Text>,
  },
  {
    width: 120,
    title: "Datetime (UTC)",
    render: (appt: ApptDto) =>
      dayjs.utc(appt.datetime).format("HH:mm, DD.MM.YY"),
  },
  {
    defaultSortOrder,
    width: 120,
    title: "Datetime (UK)",
    render: (appt: ApptDto) =>
      dayjs.utc(appt.datetime).tz("Europe/London").format("HH:mm, DD.MM.YY"),
  },
  {
    title: "PatientId",
    dataIndex: "patientId",
  },
  {
    title: "Birth date",
    width: 100,
    render: (appt: any) => {
      const getGenderIcon = () => {
        switch (appt.gender) {
          case GENDERS.MALE:
            return <ManOutlined style={{ color: "#468ea7" }} />;
          case GENDERS.FEMALE:
            return <WomanOutlined style={{ color: "#f36c84" }} />;
          default:
            return <QuestionOutlined style={{ color: "#ccc" }} />;
        }
      };
      const component = (
        <>
          {getGenderIcon()} {appt.birthDate}
        </>
      );
      return component;
    },
  },
  {
    title: "DNA %",
    width: 60,
    render: (appt: ApptDto) => {
      return appt.probabilityDNA + "%";
    },
  },
  {
    title: "Attendance",
    width: 100,
    render: (appt: ApptDto) =>
      isDna(appt) ? (
        <MdCancel style={{ color: "red", fontSize: 18 }} />
      ) : (
        <MdCheckBox style={{ color: "green", fontSize: 18 }} />
      ),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 150,
    render: (status: number) => {
      switch (status) {
        case 2:
          return <Tag color="green">booked (2)</Tag>;
        case 3:
          return <Tag color="default">cancelled, patient (3)</Tag>;
        case 4:
          return <Tag color="default">cancelled, hospital (4)</Tag>;
      }
    },
  },
  {
    title: "Site",
    dataIndex: "site",
    width: 50,
    render: (site: string) => {
      return site ? (
        <Popover
          placement="bottom"
          title="Appointment site"
          overlayInnerStyle={{ maxWidth: 300 }}
          content={site}
        >
          <Button size="small">
            <InfoCircleOutlined />
          </Button>
        </Popover>
      ) : (
        ""
      );
    },
  },
  {
    width: 20,
  },
];

const PAGINATION_SIZE = 20;

export const ViewClinicPage: FC = () => {
  let { id: clinicId } = useParams();
  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: PAGINATION_SIZE,
    sortBy: "datetime",
    order: "desc",
    total: 0,
  });
  const [getClinicQuery, getApptsQuery] = useQueries([
    {
      queryKey: [clinicId],
      queryFn: adminAPI.getClinic,
    },
    {
      queryKey: [
        clinicId,
        pagination.current,
        pagination.pageSize,
        pagination.sortBy,
        pagination.order,
      ],
      queryFn: adminAPI.findApptsForClinic,
      onSuccess: (response: any) => {
        setPagination({
          current: pagination.current,
          pageSize: pagination.pageSize,
          sortBy: pagination.sortBy,
          order: pagination.order,
          total: response.total,
        });
      },
    },
  ]);
  const {
    mutate: cancelApptsByClinic,
    isLoading: isLoadingCancelApptsByClinic,
  }: any = useMutation(adminApptsApi.cancelByClinic, {
    onSuccess: (response: number) => {
      getApptsQuery.refetch();
      notify.success(`Cancelled appts count: ${response}`);
    },
    onError: () => notify.error("Unable to cancel appts by clinic."),
  });
  const onTableChange = async (pagination: any): Promise<any> => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      sortBy: pagination.sortBy,
      order: pagination.order,
    });
  };
  const clinic = (getClinicQuery?.data as ClinicDto) ?? null;
  const appts = (getApptsQuery?.data?.results as ApptDto[]) ?? [];
  return (
    <>
      <Spin spinning={getClinicQuery.isLoading || isLoadingCancelApptsByClinic}>
        <Row gutter={24}>
          <Col xl={20}>
            <Title level={3}>{clinic?.name ?? ""}</Title>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: 20 }}>
          <Col xs={12}>
            <Card
              size="small"
              title="About"
              bordered={false}
              bodyStyle={{ padding: 0 }}
            >
              <Descriptions
                bordered
                column={1}
                size={"small"}
                labelStyle={{ minWidth: 140 }}
              >
                <Descriptions.Item label="Client code">
                  {clinic?.client?.code ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="Clinic code">
                  {clinic?.code ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="Clinic location">
                  {clinic?.location ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="Hospital">
                  {clinic?.hospital.name ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="Hospital code">
                  {clinic?.hospital.code ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="Appointments">
                  {pagination.total}
                </Descriptions.Item>
                <Descriptions.Item label="Specialties">
                  {clinic?.specialties.map((s) => s.name).join(", ") ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="Threshold (DNA)">
                  {clinic?.threshold ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="Cap (BB)">
                  {clinic?.cap ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="Created on">
                  {clinic?.created ?? ""}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col xs={4} style={{ display: "flex", flexDirection: "column" }}>
            <BackButton />
            <Popconfirm
              overlayStyle={{ width: 300 }}
              title={
                "This will set all appts in clinic as 'Cancelled by hospital'. Are you sure?"
              }
              onConfirm={() => cancelApptsByClinic(clinicId)}
              disabled={isLoadingCancelApptsByClinic}
            >
              <Button type="primary" danger={true} style={{ marginTop: 15 }}>
                Cancel all appts
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Spin>
      <Spin spinning={getApptsQuery.isLoading}>
        <Row gutter={24}>
          <Col xs={24}>
            <Card
              size="small"
              title="All appointments"
              bordered={false}
              bodyStyle={{ padding: 0 }}
            >
              <Table
                size="middle"
                expandable={{
                  expandedRowRender: (record) => (
                    <p style={{ margin: 0 }}>
                      Reasons:{" "}
                      {record.reasons.map(
                        (reason: any) => reason.id + ") " + reason.reason + " "
                      )}
                    </p>
                  ),
                  rowExpandable: (record) => isDna(record),
                }}
                rowKey={(row) => row.id}
                columns={columns}
                dataSource={appts}
                pagination={{
                  hideOnSinglePage: true,
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                }}
                onChange={(pagination) => onTableChange(pagination)}
              />
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};
