import { FC, useState } from "react";
import { Typography, Card, Alert, Col, Row, Spin } from "antd";
import { BackButton } from "../../common/components/BackButton";
import { SmsJobForm, SmsJobFormValue } from "./components/SmsJobForm";
import { Navigate, useLocation, useParams } from "react-router-dom";
import * as smsJobsApi from "../../api/smsApi";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { UpdateSmsJobDto } from "./dtos/SmsJobDto";
import { ROUTES } from "../../common/constants/ROUTES";
import { notify } from "../../common/helpers/notify";
import { ClinicValueDto } from "../clinics/dtos/ClinicValueDto";

const { Title } = Typography;

type EditSmsJobPageParams = {
  smsJobId: string;
};

export const EditSmsJobPage: FC = () => {
  const { smsJobId } = useParams<
    keyof EditSmsJobPageParams
  >() as EditSmsJobPageParams;
  const location = useLocation();
  const queryClient = useQueryClient();
  const [selectedClinics, setSelectedClinics] = useState<ClinicValueDto[]>([]);
  const {
    isLoading: isLoadingGetSmsJob,
    isError: isGetSmsJobError,
    data: currentSmsJob,
    error: getSmsJobError,
  }: any = useQuery(
    ["get-sms-job-by-id", smsJobId],
    () => smsJobsApi.getSmsJobById(smsJobId),
    {
      onSuccess: (data: any) => {
        setSelectedClinics(
          data.clinics.map((clinic: any) => {
            return {
              value: clinic.id,
              label: clinic.name,
            };
          })
        );
      },
    }
  );
  const {
    mutate: updateRecord,
    isSuccess: isUpdateSuccess,
    isLoading: isLoadingUpdate,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(
    ({
      smsJobId,
      updateSmsJobDto,
    }: {
      smsJobId: string;
      updateSmsJobDto: UpdateSmsJobDto;
    }) => smsJobsApi.updateSmsJob(smsJobId, updateSmsJobDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-sms-job-by-id", smsJobId]);
        notify.success("Routine was updated.");
      },
      onError: () => notify.error("Unable to update a routine."),
    }
  );
  const { data: smsCount, isLoading: isLoadingEstimate } = useQuery(
    ["estimate-sms", selectedClinics],
    async () =>
      smsJobsApi.estimateSms(selectedClinics.map((clinic) => clinic.value)),
    {
      enabled: selectedClinics.length > 0,
    }
  );
  if (isUpdateSuccess) {
    return <Navigate to={ROUTES.ADMIN_SMS_JOBS} replace state={{ location }} />;
  }
  const isErrorMessageVisible = () => isGetSmsJobError || isUpdateError;
  const getErrorMessage = () => {
    if (isGetSmsJobError) return (getSmsJobError as Error).message;
    if (isUpdateError) return (updateError as Error).message;
  };
  return (
    <>
      <Title level={3}>Edit routine</Title>
      <Row gutter={24}>
        <Col xs={14}>
          <Spin spinning={isLoadingGetSmsJob || isLoadingUpdate} size="large">
            <Card title={<BackButton />} size="small" bordered={false}>
              {isErrorMessageVisible() && (
                <Alert
                  message="Error"
                  description={getErrorMessage()}
                  type="error"
                  showIcon
                  style={{ marginBottom: 15 }}
                />
              )}
              <Spin spinning={isLoadingEstimate}>
                <Alert
                  message={`Will send approximately ${
                    smsCount ?? 0
                  } message(s) during the next 30 days`}
                  style={{ marginBottom: 15 }}
                />
              </Spin>
              {currentSmsJob && (
                <SmsJobForm
                  initialValues={{
                    name: currentSmsJob.name,
                    sendBefore: currentSmsJob.sendBefore,
                    clinics: currentSmsJob.clinics.map((clinic: any) => {
                      return {
                        value: clinic.id,
                        label: clinic.name,
                      };
                    }),
                  }}
                  onSubmit={(formValue: SmsJobFormValue) => {
                    updateRecord({
                      smsJobId,
                      updateSmsJobDto: {
                        ...formValue,
                        clinics: formValue.clinics.map((clinic) => ({
                          id: clinic.value,
                        })),
                      },
                    });
                  }}
                  onChangeSelectedClinics={(clinics: ClinicValueDto[]) =>
                    setSelectedClinics(clinics)
                  }
                  submitButtonLabel="Update"
                />
              )}
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};
