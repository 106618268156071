import { PlusOutlined } from "@ant-design/icons";
import {
  TablePaginationConfig,
  Alert,
  Typography,
  Card,
  Button,
  Popconfirm,
} from "antd";
import { FC, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import * as commsAPI from "../../api/commApi";
import { ROUTES } from "../../common/constants/ROUTES";
import { PaginationDto } from "../../common/dtos/PaginationDto";
import { CommDto } from "./dtos/CommDto";
import { notify } from "../../common/helpers/notify";
import { CommsTable } from "./components/CommsTable";
const { Title } = Typography;
const PAGE_SIZE = 20;

export const ListCommsPage: FC = () => {
  const [pagination, setPagination] = useState<PaginationDto>({
    current: 1,
    pageSize: PAGE_SIZE,
    sortBy: "created",
    order: "ASC",
    total: 0,
  });
  const { data, isError, isLoading } = useQuery(
    ["get-comms", ...Object.values(pagination)],
    async () => {
      return commsAPI.getComms(pagination.pageSize, pagination.current);
    },
    {
      onSuccess: (data) => {
        let current = pagination.current;
        if (data?.results.length === 0 && pagination.current > 1) {
          --current;
        }
        setPagination({
          ...pagination,
          current: current,
          total: data.total,
        });
      },
      onError: () => notify.error("Unable to get Connects."),
    }
  );
  const { mutate: runAllComms, isLoading: isLoadingRunAllComms } = useMutation(
    commsAPI.runAllComms,
    {
      onError: (error) => {
        notify.error("Unable to run all comms.");
        console.error(error);
      },
      onSuccess: () => notify.success("Routines have been started."),
    }
  );
  const comms: CommDto[] = (data?.results as CommDto[]) || [];
  const handlePaginationChange = (newPagination: TablePaginationConfig) => {
    setPagination({
      ...pagination,
      current: newPagination.current ?? 1,
      pageSize: newPagination.pageSize ?? PAGE_SIZE,
    });
  };
  if (isError) {
    return (
      <Alert
        message="Error"
        description="Unable to get Connects."
        type="error"
        showIcon
        style={{ marginBottom: 15 }}
      />
    );
  }
  return (
    <>
      <Title level={3}>Connects</Title>
      <Card
        size="small"
        bodyStyle={{ padding: 0 }}
        headStyle={{ paddingTop: 7, paddingBottom: 7 }}
        title={
          <>
            <Link to={ROUTES.ADMIN_COMMS_CREATE}>
              <Button type="primary">
                <PlusOutlined />
                Create
              </Button>
            </Link>
            <Link to={ROUTES.ADMIN_COMM_CREDENTIALS}>
              <Button type="primary" style={{ marginLeft: 7 }}>
                Credentials
              </Button>
            </Link>
            <Popconfirm
              overlayStyle={{ width: 300 }}
              title="Are you sure? This will trigger creation of messages for all enabled routines."
              onConfirm={() => runAllComms()}
              disabled={isLoadingRunAllComms}
            >
              <Button
                type="primary"
                style={{ marginLeft: 7 }}
                disabled={isLoadingRunAllComms}
              >
                Run all routines
              </Button>
            </Popconfirm>
          </>
        }
      >
        <CommsTable
          data={comms}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePaginationChange}
        />
      </Card>
    </>
  );
};
