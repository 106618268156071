import { MdCheckBox, MdCancel } from "react-icons/md";
import { Button, Table, Typography } from "antd";
import { FC } from "react";
import { dayjs } from "../../../common/helpers/dayjs";
import { BBRoutineLogDto } from "../../../api/dtos/adminApiBBRoutinesDtos";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import { PaginationDto } from "../../../common/dtos/PaginationDto";
import { notify } from "../../../common/helpers/notify";
import "react-json-view-lite/dist/index.css";

const { Text } = Typography;

const defaultSortOrder: any = "descend";

interface BBRoutineLogsTableProps {
  pagination: PaginationDto;
  data: BBRoutineLogDto[];
  isLoading: boolean;
  onPaginationChange: (pagination: PaginationDto) => void;
}

const columns = [
  {
    width: 20,
  },
  {
    defaultSortOrder,
    title: "created at",
    dataIndex: "created",
    render: (value: string) => {
      return <Text>{dayjs.utc(value).tz().format("DD.MM HH:mm")}</Text>;
    },
  },
  {
    title: "appt id",
    width: 300,
    dataIndex: "apptId",
    render: (value: string) => {
      return <Text>{value}</Text>;
    },
  },
  {
    title: "clinic id",
    width: 300,
    dataIndex: "clinicId",
    render: (value: string) => {
      return <Text>{value}</Text>;
    },
  },
  {
    title: "action saved",
    width: 120,
    dataIndex: "isActionCreated",
    render: (isActionCreated: boolean) =>
      isActionCreated ? (
        <MdCheckBox style={{ color: "green", fontSize: 18 }} />
      ) : (
        <MdCancel style={{ color: "grey", fontSize: 18 }} />
      ),
  },
  {
    title: "log",
    width: 150,
    dataIndex: "log",
    render: (value: string) => (
      <Button
        type="default"
        onClick={() => {
          navigator.clipboard.writeText(value).then(() => {
            notify.success("Copied to clipboard.");
          });
        }}
      >
        Click to copy
      </Button>
    ),
  },
  {
    width: 20,
  },
];

export const BBRoutineLogsTable: FC<BBRoutineLogsTableProps> = ({
  pagination,
  data,
  onPaginationChange,
  isLoading,
}) => {
  return (
    <Table
      size="middle"
      loading={isLoading}
      pagination={{
        showSizeChanger: true,
        hideOnSinglePage: true,
        total: pagination.total,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }}
      onChange={(pagination: any, filters: any) => {
        onPaginationChange({
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        });
      }}
      expandable={{
        expandedRowRender: (record) => (
          <JsonView
            data={JSON.parse(record.log)}
            shouldExpandNode={allExpanded}
            style={defaultStyles}
          />
        ),
      }}
      rowKey={(row) => row.id}
      columns={columns}
      dataSource={data}
    />
  );
};
