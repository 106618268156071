import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import logo from "../../../common/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { Layout, Spin } from "antd";
import { UserDropdown } from "../UserDropdown";
import { ClinicsMultiSelect } from "./components/ClinicsMultiSelect";
import { ClinicMenu } from "./components/ClinicMenu";
import { ROUTES } from "../../constants/ROUTES";
import { Footer } from "../Footer";
const { Header, Content } = Layout;

type HospitalLayoutProps = {
  children: ReactNode;
};

const ClinicsMultiselectContext = createContext<any>({ isUpdating: false });

export function useClinicsMultiselectContext() {
  return useContext(ClinicsMultiselectContext);
}

export const HospitalLayout: FC<HospitalLayoutProps> = (
  props: HospitalLayoutProps
) => {
  const location = useLocation();
  const [isUpdatingUserSettings, setIsUpdatingUserSettings] = useState(false);
  // if location changes, cancel the page content spinner for clinics multiselect
  useEffect(() => {
    setIsUpdatingUserSettings(false);
  }, [location.pathname]);
  return (
    <>
      <Header style={{ padding: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            margin: "0 auto",
            maxWidth: "1300px",
            padding: "0 50px",
          }}
        >
          <Link to="/">
            <div className="logo">
              <img src={logo} className="logo-img" alt="logo" />
            </div>
          </Link>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <ClinicMenu />
            <ClinicsMultiSelect
              setIsUpdatingUserSettings={(value: any) =>
                setIsUpdatingUserSettings(value)
              }
              isHidden={
                location.pathname === ROUTES.ACTIONS ||
                location.pathname === ROUTES.INSIGHTS
              }
            />
            <UserDropdown />
          </div>
        </div>
      </Header>
      <Content
        style={{
          display: "block",
          width: "100%",
          margin: "0 auto",
          padding: "20px 50px",
          maxWidth: "1300px",
        }}
      >
        <ClinicsMultiselectContext.Provider
          value={{
            isUpdatingUserSettings,
            setIsUpdatingUserSettings,
          }}
          // value={({ isUpdating }: any) => setIsUpdatingUserSettings(isUpdating)}
        >
          <Spin
            spinning={isUpdatingUserSettings}
            size={"large"}
            tip="Loading..."
          >
            {props.children}
          </Spin>
        </ClinicsMultiselectContext.Provider>
      </Content>
      <Footer />
    </>
  );
};
