import { ApptsFilterFormDto } from "../dtos/CommDto";
import { ApptsFilterDto } from "../dtos/CommDto";

export function mapFormValuesToApptsFilterDto(
  filterValues: ApptsFilterFormDto
): ApptsFilterDto {
  const clinicIds = filterValues.clinics?.length
    ? filterValues.clinics?.map((c) => c.value)
    : null;
  return {
    ...filterValues,
    clinics: clinicIds,
  };
}
