import type { FC } from "react";
import {
  Card,
  Typography,
  Col,
  Row,
  Spin,
  Form,
  Upload,
  Button,
  Popover,
} from "antd";
import { useMutation } from "react-query";
import { BackButton } from "../../common/components/BackButton";
import { RcFile } from "antd/lib/upload";
import { useForm } from "antd/es/form/Form";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { readFileContent } from "../comms/utils/file";
import { notify } from "../../common/helpers/notify";
import * as adminApptsApi from "../../api/admin/apptsApi";
import { SelectClient } from "../users/components/SelectClient";

const { Title, Text } = Typography;

export interface BatchCancelApptsFormValues {
  file?: RcFile;
  clientId: string;
}

const schema = '[{ "clinic_code": "TST"}, { "clinic_code": "TSTB"}]';

export const CancelApptsByClinicsPage: FC = () => {
  const [form] = useForm<BatchCancelApptsFormValues>();

  const { mutate: cancelByClinics, isLoading: isLoadingCancelByClinics }: any =
    useMutation(adminApptsApi.cancelByClinics, {
      onSuccess: (affectedApptsCount) =>
        notify.success(`Appts cancelled: ${affectedApptsCount}`),
      onError: (error: Error) =>
        notify.error(
          error.message ?? "Unable to batch cancel appts by clinics."
        ),
    });
  const onFinish = async (values: BatchCancelApptsFormValues) => {
    const file = values?.file;
    if (!file) {
      notify.error("Please select the file first.");
      return false;
    }
    try {
      const content = await readFileContent(file);
      const clinicCodes = JSON.parse(content);
      cancelByClinics({ clientId: values.clientId, codes: clinicCodes });
    } catch (e) {
      notify.error(
        "Unable to parse the JSON file. Please verify the format and values."
      );
    }
  };
  return (
    <>
      <Title level={3}>{`Batch appts cancellation by clinics`}</Title>
      <Row gutter={24}>
        <Col xs={14}>
          <Spin spinning={isLoadingCancelByClinics} size="large">
            <Card
              title={
                <div style={{ display: "flex" }}>
                  <BackButton />
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      window.open("https://csvjson.com/csv2json", "_blank");
                    }}
                  >
                    csv2json
                  </Button>
                  <Popover
                    placement="bottom"
                    title="Info"
                    overlayInnerStyle={{ maxWidth: 300 }}
                    content={
                      <>
                        <Text>Current schema: {schema}</Text>
                        <ul>
                          <li>
                            use csvjson.com/csv2json for format conversion
                          </li>
                          <li>missing clinics are ignored</li>
                          <li>max clinics count in JSON 10k</li>
                          <li>max JSON size 1MB</li>
                          <li>remember that appts.sync will update statuses</li>
                        </ul>
                      </>
                    }
                  >
                    <Button style={{ marginLeft: 10 }}>
                      <InfoCircleOutlined />
                    </Button>
                  </Popover>
                </div>
              }
              size="small"
              bordered={false}
              headStyle={{ paddingTop: 7, paddingBottom: 7 }}
            >
              <Form
                form={form}
                labelCol={{ span: 5 }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Select client"
                  name="clientId"
                  rules={[
                    {
                      required: true,
                      message: "Please select the client!",
                    },
                  ]}
                >
                  <SelectClient
                    placeholder="Select client"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  label="JSON file"
                  name="file"
                  required={true}
                  valuePropName="file"
                  getValueFromEvent={(event) => {
                    return event.file;
                  }}
                  rules={[
                    { required: true, message: "Please upload a JSON file." },
                  ]}
                >
                  <Upload
                    accept=".json"
                    maxCount={1}
                    name="file"
                    multiple={false}
                    beforeUpload={() => {
                      return false; // no default http request
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Select file</Button>
                  </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    style={{ minWidth: 100 }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};
