import type { FC } from "react";
import { useState } from "react";
import {
  Typography,
  Table,
  Statistic,
  Card,
  Col,
  Row,
  Avatar,
  Spin,
  Badge,
  Alert,
} from "antd";
import {
  UserSwitchOutlined,
  UserOutlined,
  BankOutlined,
  CloudSyncOutlined,
  UserAddOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { useQuery } from "react-query";
import { useAuth } from "../../common/helpers/auth";
import * as adminAPI from "../../api/adminApi";
import { Link } from "react-router-dom";
import { notify } from "../../common/helpers/notify";
import { dayjs } from "../../common/helpers/dayjs";
import { truncate } from "../../common/helpers/truncate";

const { Title } = Typography;

const loginsCols = [
  { width: 28 },
  {
    title: "Name",
    render: (user: any) => {
      return (
        <Link to={`/admin/users/update/${user.id}`}>
          <Avatar
            size={22}
            style={{
              color: "#fff",
              backgroundColor: "rgb(111 69 206)",
              fontWeight: 600,
              marginRight: 7,
              fontSize: 11,
            }}
          >
            {user.firstName[0] + user.lastName[0]}
          </Avatar>
          {user.firstName + " " + user.lastName}
        </Link>
      );
    },
  },
  {
    title: "Logged in",
    dataIndex: "lastLogin",
    render: (text: string, record: any, index: number) => {
      return dayjs.utc(text).fromNow();
    },
  },
  {
    title: "Email",
    dataIndex: "email",
  },
];

const clinicsCols = [
  { width: 28 },
  {
    title: "Name",
    dataIndex: "name",
    render: (text: string, record: any, index: number) => {
      return (
        <Link to={`/admin/clinics/${record.id}`}>{truncate(text, 40)}</Link>
      );
    },
  },
  {
    title: "Added",
    dataIndex: "created",
    render: (text: string) => {
      return dayjs.utc(text).fromNow();
    },
  },
];

const apptsSyncCols = [
  { width: 28 },
  {
    width: "55%",
    title: "Batch ID",
    dataIndex: "id",
    render: (id: string) => "..." + id.slice(-5),
  },
  {
    title: "Size",
    dataIndex: "size",
    render: (text: string, record: any, index: number) => {
      return text;
    },
  },
  {
    title: "Deployed",
    render: (a: any) => {
      if (a.deployed)
        return (
          <Badge
            style={{ minWidth: 100 }}
            count={a.deployed ? dayjs.utc(a.deployed).fromNow() : ""}
            showZero
            color="#52c41a"
          />
        );
      if (!a.validationError && !a.deploymentError)
        return (
          <Badge
            style={{ minWidth: 100 }}
            count={"deploying"}
            showZero
            color="#faad14"
          />
        );
      const errors = [];
      if (a.validationError)
        errors.push(
          <li key={errors.length + 1}>
            <Badge
              style={{ minWidth: 100 }}
              count={"validation failed"}
              showZero
            />
          </li>
        );
      if (a.deploymentError)
        errors.push(
          <li key={errors.length + 1}>
            <Badge count={"deployment failed"} showZero />
          </li>
        );
      return (
        <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
          {errors}
        </ul>
      );
    },
  },
];

const invitedCols = [
  { width: 28 },
  {
    title: "Name",
    render: (user: any) => {
      return (
        <Link to={`/admin/users/update/${user.id}`}>
          <Avatar
            size={22}
            style={{
              color: "#fff",
              backgroundColor: "rgb(111 69 206)",
              fontWeight: 600,
              marginRight: 7,
              fontSize: 11,
            }}
          >
            {user.firstName[0] + user.lastName[0]}
          </Avatar>
          {user.firstName + " " + user.lastName}
        </Link>
      );
    },
  },
  {
    title: "Invited",
    dataIndex: "created",
    render: (text: string) => {
      return dayjs.utc(text).fromNow();
    },
  },
  {
    title: "Email",
    dataIndex: "email",
  },
];

type UserDto = {
  id: string;
  fullname: string;
  date: string;
  email: string;
  avatar: string;
};

type ClinicDto = {
  id: string;
  name: string;
  date: string;
};

type ApptsBatchDto = {
  id: string;
  size: number;
  generated: string;
  validationError: string | null;
  deploymentError: string | null;
  deployed: string;
  created: string;
};

type StatsProps = {
  isRunningApptsSync: boolean;
  isRunningApptsDeploy: boolean;
  isRunningActionsSync: boolean;
  isRunningBBRoutines: boolean;
  activatedUsersCount: number;
  usersCount: number;
  loginsCount: number;
  clinicsCount: number;
  apptsCount: number;
  latestLogins: UserDto[];
  latestInvited: UserDto[];
  latestClinics: ClinicDto[];
  latestApptsSyncs: ApptsBatchDto[];
  apptsLastDeployedDate: string | null;
};

export const DashboardPage: FC = () => {
  const auth: any = useAuth();
  const [stats, setStats] = useState<StatsProps>({
    isRunningApptsSync: false,
    isRunningApptsDeploy: false,
    isRunningActionsSync: false,
    isRunningBBRoutines: false,
    activatedUsersCount: 0,
    usersCount: 0,
    loginsCount: 0,
    clinicsCount: 0,
    apptsCount: 0,
    latestLogins: [],
    latestInvited: [],
    latestClinics: [],
    latestApptsSyncs: [],
    apptsLastDeployedDate: null,
  });
  const { isLoading, isError } = useQuery(["getStats"], adminAPI.getStats, {
    onSuccess: (res: StatsProps) => setStats(res),
    onError: (error: Error) =>
      notify.error(
        error.message ?? "Unable to get data. Please contact admin."
      ),
    refetchOnWindowFocus: false,
  });

  if (isError)
    return <Title>An error has occurred. Try reloading this page.</Title>;

  let isOutdatedDeployment = false;
  if (stats.apptsLastDeployedDate !== null) {
    const lastDeployDate = dayjs.utc(stats.apptsLastDeployedDate);
    const currentDate = dayjs.utc();
    if (currentDate.diff(lastDeployDate, "days") >= 5)
      isOutdatedDeployment = true;
  }
  return (
    <>
      <Title level={3}>Welcome, {auth.user.firstName}!</Title>
      {stats.isRunningApptsSync && (
        <Alert
          type="info"
          showIcon={true}
          message={"Appointments sync is running in background."}
          style={{ marginBottom: 10 }}
        />
      )}
      {stats.isRunningApptsDeploy && (
        <Alert
          type="info"
          showIcon={true}
          message={"Appointments deployment is running in background."}
          style={{ marginBottom: 10 }}
        />
      )}
      {stats.isRunningActionsSync && (
        <Alert
          type="info"
          showIcon={true}
          message={"Actions sync is running in background."}
          style={{ marginBottom: 10 }}
        />
      )}
      {stats.isRunningBBRoutines && (
        <Alert
          type="info"
          showIcon={true}
          message={"Backup booking routines are running in background."}
          style={{ marginBottom: 10 }}
        />
      )}
      <Spin spinning={isLoading}>
        <Row gutter={24}>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 24 }}>
            <Card size="small">
              <Statistic
                title="Clinics / Appointments"
                value={stats.clinicsCount + " / " + stats.apptsCount}
                valueStyle={{ color: "#3f8600" }}
                prefix={<BankOutlined />}
              />
            </Card>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 24 }}>
            <Card size="small">
              <Statistic
                title="Unique logins (30d)"
                value={stats.loginsCount}
                valueStyle={{ color: "#3f8600" }}
                prefix={<LoginOutlined />}
              />
            </Card>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 24 }}>
            <Card size="small">
              <Statistic
                title="Users (enabled/total)"
                value={stats.activatedUsersCount + "/" + stats.usersCount}
                valueStyle={{ color: "#3f8600" }}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: 24 }}>
            {stats.apptsLastDeployedDate !== null ? (
              <Card size="small">
                <Statistic
                  title="Appointments deployed"
                  value={dayjs.utc(stats.apptsLastDeployedDate).fromNow()}
                  valueStyle={
                    !isOutdatedDeployment
                      ? { color: "green" }
                      : { color: "red" }
                  }
                  prefix={<CloudSyncOutlined />}
                />
              </Card>
            ) : (
              <Card size="small">
                <Statistic
                  title="Appointments deployed"
                  value={"never"}
                  valueStyle={{ color: "orange" }}
                  prefix={<CloudSyncOutlined />}
                />
              </Card>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xl={12} lg={12} md={12} style={{ marginBottom: 24 }}>
            <Card
              size="small"
              bodyStyle={{ padding: 0 }}
              title={
                <>
                  <UserSwitchOutlined /> Latest logins
                </>
              }
              bordered={false}
              style={{ marginBottom: 25 }}
            >
              <Table
                rowKey={(user: any) => user.id}
                size="middle"
                columns={loginsCols}
                dataSource={stats?.latestLogins}
                pagination={{ hideOnSinglePage: true }}
              />
            </Card>
            <Card
              size="small"
              bodyStyle={{ padding: 0 }}
              title={
                <>
                  <BankOutlined /> Latest clinics
                </>
              }
              bordered={false}
            >
              <Table
                size="middle"
                rowKey={(clinic: any) => clinic.id}
                columns={clinicsCols}
                dataSource={stats?.latestClinics}
                pagination={{ hideOnSinglePage: true }}
              />
            </Card>
          </Col>
          <Col xl={12} lg={12} md={12} style={{ marginBottom: 24 }}>
            <Card
              size="small"
              bodyStyle={{ padding: 0 }}
              title={
                <>
                  <UserAddOutlined /> Latest invited
                </>
              }
              bordered={false}
              style={{ marginBottom: 25 }}
            >
              <Table
                size="middle"
                rowKey={(user: any) => user.id}
                columns={invitedCols}
                dataSource={stats?.latestInvited}
                pagination={{ hideOnSinglePage: true }}
              />
            </Card>
            <Card
              size="small"
              bodyStyle={{ padding: 0 }}
              title={
                <>
                  <BankOutlined /> Latest appointments syncs
                </>
              }
              bordered={false}
            >
              <Table
                size="middle"
                rowKey={(batch: any) => batch.id}
                columns={apptsSyncCols}
                dataSource={stats?.latestApptsSyncs}
                pagination={{ hideOnSinglePage: true }}
              />
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};
