import { Card, Typography, Alert, Col, Row, Spin } from "antd";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Navigate, useLocation } from "react-router-dom";
import * as commsApi from "../../api/commApi";
import { ROUTES } from "../../common/constants/ROUTES";
import { BackButton } from "../../common/components/BackButton";
import { notify } from "../../common/helpers/notify";
import { CommForm, CommFormValue, FILTER_TYPES } from "./components/CommForm";
import { CreateUpdateCommDto } from "./dtos/CommDto";
import { ApptsFilterFormDto } from "./dtos/CommDto";
import { readFileContent } from "./utils/file";
import { shouldGetEstimate } from "./utils/shouldGetEstimate";
import { mapFormValuesToApptsFilterDto } from "./utils/mapFormValuesToApptsFilterDto";

const { Title } = Typography;

export const CreateCommPage: FC = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const [filterValues, setFilterValues] = useState<ApptsFilterFormDto>(
    {} as ApptsFilterFormDto
  );
  const {
    mutate: createComm,
    isError: isCreateError,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    error: createError,
  } = useMutation((value: CreateUpdateCommDto) => commsApi.creatComm(value), {
    onSuccess: () => {
      notify.success("New communication was created.");
      queryClient.invalidateQueries(["get-comms"]);
    },
    onError: () => notify.error("Unable to create a communication."),
  });
  const { data: commsCount, isLoading: isLoadingEstimate } = useQuery(
    ["estimate-comms", filterValues],
    async () =>
      commsApi.estimateCommsToSend(mapFormValuesToApptsFilterDto(filterValues)),
    {
      enabled: shouldGetEstimate(filterValues),
    }
  );
  if (isCreateSuccess) {
    return <Navigate to={ROUTES.ADMIN_COMMS} replace state={{ location }} />;
  }
  return (
    <>
      <Title level={3}>Create a new communication</Title>
      <Row gutter={24}>
        <Col xs={14}>
          <Spin spinning={isCreateLoading} size="large">
            <Card
              title={<BackButton />}
              headStyle={{ paddingTop: 7, paddingBottom: 7 }}
              size="small"
              bordered={false}
            >
              {isCreateError && (
                <Alert
                  message="Error"
                  description={(createError as Error).message}
                  type="error"
                  showIcon
                  style={{ marginBottom: 15 }}
                />
              )}{" "}
              <Spin spinning={isLoadingEstimate}>
                <Alert
                  message={`Will contact approx. ${
                    commsCount ?? 0
                  } patients during the next 30 days`}
                  style={{ marginBottom: 15 }}
                />
              </Spin>
              <CommForm
                initialValues={{
                  id: "",
                  name: "",
                  filters: {} as ApptsFilterFormDto,
                  flowUrl: "",
                  clientId: "",
                  hasDnaPredictionFilter: false,
                  hasAgeFilter: false,
                  hasReasonFilter: false,
                  filterType: FILTER_TYPES.DYNAMIC,
                  priority: 1,
                  isEnabled: false,
                }}
                onSubmit={async (formValue: CommFormValue) => {
                  let fileContent;
                  if (formValue.file) {
                    fileContent = await readFileContent(formValue.file);
                  }
                  createComm({
                    ...formValue,
                    clientId: formValue.clientId,
                    filters: {
                      ...formValue.filters,
                      clinics:
                        formValue.filters.clinics?.map(
                          (clinic) => clinic.value
                        ) || null,
                      apptIds: fileContent?.split(","),
                      type: formValue.filterType,
                    },
                  });
                }}
                onFiltersChange={(value) => {
                  queryClient.cancelQueries("estimate-comms", {
                    exact: false,
                  });
                  setFilterValues(value);
                }}
                submitButtonLabel="Create"
              />
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};
