import { FC } from "react";
import { Table, TablePaginationConfig } from "antd";
import { SmsLog } from "../dtos/SmsLogDto";
import { PaginationDto } from "../../../common/dtos/PaginationDto";
import { dayjs } from "../../../common/helpers/dayjs";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Phone",
    dataIndex: "phone",
    key: "name",
  },
  {
    title: "Text message",
    dataIndex: "text",
    key: "text",
  },
  {
    title: "Error message",
    dataIndex: "errorMessage",
    key: "errorMessage",
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
    render: (createdDate: string) =>
      dayjs.utc(createdDate).tz().format("YYYY.MM.DD HH:mm"),
  },
  {
    title: "Status",
    dataIndex: "isError",
    key: "isError",
    render: (isError: boolean) =>
      isError ? (
        <CloseOutlined style={{ color: "red" }} />
      ) : (
        <CheckOutlined style={{ color: "green" }} />
      ),
  },
];

interface SmsLogsTableProps {
  data: SmsLog[];
  pagination: PaginationDto;
  isLoading: boolean;
  onPaginationChange: (pagination: TablePaginationConfig) => void;
}

export const SmsLogsTable: FC<SmsLogsTableProps> = ({
  data,
  pagination,
  isLoading,
  onPaginationChange,
}) => {
  return (
    <Table
      rowKey={"id"}
      size="middle"
      loading={isLoading}
      columns={columns}
      dataSource={data}
      onChange={(pagination: TablePaginationConfig) => {
        onPaginationChange(pagination);
      }}
      pagination={{
        hideOnSinglePage: true,
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      }}
    />
  );
};
